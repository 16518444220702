var render = function render(){
  var _vm$defaultDevice$bra, _vm$defaultDevice$dev, _vm$defaultDevice, _vm$defaultDevice2, _vm$defaultDevice3, _vm$defaultDevice$bra2, _vm$defaultDevice4, _vm$defaultDevice$dev2, _vm$defaultDevice5, _vm$defaultDevice6, _vm$defaultDevice7;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.full ? _c('div', [_vm._v(" " + _vm._s(_vm.defaultDevice) + " "), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [!_vm.defaultDevice && _vm.isEmpty(_vm.defaultDevice) ? _c('v-icon', _vm._g({
          domProps: {
            "textContent": _vm._s(['Android', 'iOS'].includes(_vm.os && _vm.os.name) ? 'mdi-cellphone' : 'mdi-monitor')
          }
        }, on)) : _c('v-icon', _vm._g({
          domProps: {
            "textContent": _vm._s(['Android', 'iOS'].includes(_vm.defaultDevice.os) ? 'mdi-cellphone' : 'mdi-monitor')
          }
        }, on))];
      }
    }], null, false, 3076150908)
  }, [_c('span', [_vm.defaultDevice && !_vm.isEmpty(_vm.defaultDevice) ? _c('div', [_c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(" " + _vm._s((_vm$defaultDevice$bra = _vm.defaultDevice.brand) !== null && _vm$defaultDevice$bra !== void 0 ? _vm$defaultDevice$bra : _vm.defaultDevice.manufacturer) + " ")]), _c('span', [_vm._v(_vm._s((_vm$defaultDevice$dev = (_vm$defaultDevice = _vm.defaultDevice) === null || _vm$defaultDevice === void 0 ? void 0 : _vm$defaultDevice.device) !== null && _vm$defaultDevice$dev !== void 0 ? _vm$defaultDevice$dev : (_vm$defaultDevice2 = _vm.defaultDevice) === null || _vm$defaultDevice2 === void 0 ? void 0 : _vm$defaultDevice2.model))]), _c('div', [_vm._v(" " + _vm._s(_vm.defaultDevice.os + " ") + " " + _vm._s(((_vm$defaultDevice3 = _vm.defaultDevice) === null || _vm$defaultDevice3 === void 0 ? void 0 : _vm$defaultDevice3.os_version) || _vm.defaultDevice.version) + " ")])]) : _vm._e()])])], 1) : _c('div', {
    staticClass: "py-3 pl-2"
  }, [_vm.defaultDevice && !_vm.isEmpty(_vm.defaultDevice) ? _c('div', [_c('v-row', {
    staticClass: "text-left"
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 pl-0 pr-2 my-auto",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s(['Android', 'iOS'].includes(_vm.defaultDevice.os) ? 'mdi-cellphone' : 'mdi-monitor')
    }
  })], 1), _c('v-col', {
    staticClass: "pt-0 pb-0 pl-0 pr-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.defaultDevice && !_vm.isEmpty(_vm.defaultDevice) ? _c('div', [_c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(" " + _vm._s((_vm$defaultDevice$bra2 = _vm.defaultDevice.brand) !== null && _vm$defaultDevice$bra2 !== void 0 ? _vm$defaultDevice$bra2 : (_vm$defaultDevice4 = _vm.defaultDevice) === null || _vm$defaultDevice4 === void 0 ? void 0 : _vm$defaultDevice4.manufacturer) + " ")]), _c('span', [_vm._v(_vm._s((_vm$defaultDevice$dev2 = (_vm$defaultDevice5 = _vm.defaultDevice) === null || _vm$defaultDevice5 === void 0 ? void 0 : _vm$defaultDevice5.device) !== null && _vm$defaultDevice$dev2 !== void 0 ? _vm$defaultDevice$dev2 : (_vm$defaultDevice6 = _vm.defaultDevice) === null || _vm$defaultDevice6 === void 0 ? void 0 : _vm$defaultDevice6.model))]), _c('div', [_vm._v(" " + _vm._s(_vm.defaultDevice.os + " ") + " " + _vm._s(((_vm$defaultDevice7 = _vm.defaultDevice) === null || _vm$defaultDevice7 === void 0 ? void 0 : _vm$defaultDevice7.os_version) || _vm.defaultDevice.version) + " ")])]) : _vm._e()])], 1)], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }