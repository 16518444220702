var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.rolesOnly ? _c('div', [_c('v-skeleton-loader', {
    staticClass: "mb-3",
    attrs: {
      "type": "heading",
      "width": "170"
    }
  }), _c('v-skeleton-loader', {
    attrs: {
      "type": "image",
      "height": "56"
    }
  }), _c('div', {
    staticClass: "my-15"
  }), _c('v-skeleton-loader', {
    staticClass: "mb-3",
    attrs: {
      "type": "heading",
      "width": "250"
    }
  }), _c('v-skeleton-loader', {
    attrs: {
      "type": "image",
      "height": "56"
    }
  }), _c('div', {
    staticClass: "my-15"
  }), _c('v-skeleton-loader', {
    staticClass: "mb-3",
    attrs: {
      "type": "heading",
      "width": "220"
    }
  }), _c('v-skeleton-loader', {
    attrs: {
      "type": "image",
      "height": "120"
    }
  }), _c('div', {
    staticClass: "my-15"
  })], 1) : _vm._e(), _c('div', [_vm._l(4, function (i) {
    return _c('v-skeleton-loader', {
      key: i + '_key',
      staticClass: "mb-2",
      attrs: {
        "type": "text",
        "width": i == 1 || i == 3 ? '100' : '200'
      }
    });
  }), _c('div', {
    staticClass: "my-15"
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "text"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "text"
    }
  })], 1)], 1), _vm._l(4, function (i) {
    return _c('v-row', {
      key: i + '__key'
    }, [_c('v-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "text",
        "width": "90"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "3"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "text",
        "width": "20"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "3"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "text",
        "width": "20"
      }
    })], 1)], 1);
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }