<template>
  <div>
    <v-card height="100%" outlined>
      <v-card-title>
        <div>
          <div class="bold-text text-body-1">
            Listed here are the team members granted access to this app. To
            adjust roles and permissions, please visit the
            <a
              class="text-decoration-underline"
              @click="$router.push({ name: 'team-members' })"
              >team members</a
            >
            page.
          </div>
        </div>
        <v-spacer></v-spacer>
        <AddMemberPanel
          v-if="canManageApp"
          :app-detail="info"
          drop-down
          @refresh="loadData"
        />
      </v-card-title>
    </v-card>
    <div v-if="getInfoErrorMessage">
      <no-data :first-text="getInfoErrorMessage" permission-issues></no-data>
    </div>
    <div v-else>
      <div>
        <!-- loader when data are loading -->
        <v-skeleton-loader
          v-if="loading"
          class="transparent"
          type="table-thead, table-tbody"
        />
        <!-- in case no releases -->
        <!--          <no-data-->
        <!--            v-else-if="noInfo"-->
        <!--            :first-text="-->
        <!--              errorMessage-->
        <!--                ? errorMessage-->
        <!--                : 'You do not have permission to view members.'-->
        <!--            "-->
        <!--            permission-issues-->
        <!--          />-->

        <noData v-else-if="noData" />
        <v-row v-else>
          <v-col cols="12" md="12">
            <div class="" flat>
              <!-- loader spinner before the data comes -->
              <Loader v-if="loading" />

              <!-- shows only if data fetch succefuly -->
              <div v-if="!loading">
                <DatatableWrapper
                  :loading="loading"
                  :pagination="pagination"
                  :on-change="handleOnChange"
                >
                  <v-data-table
                    :headers="headers"
                    :items="members"
                    hide-default-footer
                    mobile-breakpoint="0"
                  >
                    <template #item.name="{ item }">
                      <v-row>
                        <v-col cols="auto">
                          <ProfileImage
                            :alt="item.name"
                            :image="item.image"
                            size="40"
                          />
                        </v-col>
                        <v-col>
                          <div>
                            {{ get(item, "name", "") }}
                          </div>
                          <div class="text-muted">
                            {{ get(item, "role.name", "") }}
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                    <!-- <template #item.current_release="{ item }">
                    <v-row
                      v-if="
                        !isEmpty(item.current_release.android) ||
                        !isEmpty(item.current_release.ios)
                      "
                    >
                      <v-col v-if="!isEmpty(item.current_release.android)">
                        <div>
                          <v-icon
                            v-text="'mdi-android'"
                            small
                            color="primary"
                          ></v-icon>
                        </div>
                        <div class="text-caption">
                          {{
                            `${item.current_release.android.version} ( ${item.current_release.android.version_code} )`
                          }}
                        </div>
                      </v-col>
                      <v-col v-if="!isEmpty(item.current_release.ios)">
                        <div>
                          <v-icon
                            v-text="'mdi-apple'"
                            small
                            color="primary"
                          ></v-icon>
                        </div>
                        <div class="text-caption">
                          {{
                            `${item.current_release.ios.version} ( ${item.current_release.ios.version_code} )`
                          }}
                        </div>
                      </v-col>
                    </v-row>
                    <div v-else>-</div>
                  </template> -->
                    <template #item.status="{ item }">
                      <span
                        :class="
                          item.status == 1 ? 'success--text' : 'error--text'
                        "
                      >
                        {{ item.status == 1 ? "Active" : "Inactive" }}
                      </span>
                    </template>
                    <template #item.join="{ item }">
                      <vue-hoverable-date :date="item.created_at" />
                    </template>

                    <template #item.action="{ item }">
                      <action-dropdown
                        v-if="user.id != item.user_id && canManageApp"
                        id="membersActionDropdown"
                      >
                        <template #list>
                          <v-list-item
                            v-if="
                              currentUser.id !== item.user_id && canManageApp
                            "
                            id="removeMemberFromApp"
                            @click="removeMember(item)"
                          >
                            <v-icon small>cancel</v-icon>
                            Remove
                          </v-list-item>
                        </template>
                      </action-dropdown>
                      <v-icon v-else class="mx-1" v-text="'mdi-minus'"></v-icon>
                    </template>
                  </v-data-table>
                </DatatableWrapper>

                <Modal v-model="showRemoveModal" no-py>
                  <template #card>
                    <v-card>
                      <v-card-title class="font-weight-bold">
                        Remove member
                      </v-card-title>
                      <v-divider />

                      <v-card-text class="my-4 message">
                        <p>
                          Are you sure you want to remove
                          <b>{{ userName }}</b> from this app?
                        </p>

                        <v-alert
                          border="left"
                          class="mt-3"
                          color="info"
                          icon="info"
                          outlined
                        >
                          <b>{{ userName }}</b> will lose the access to any
                          information regarding this app.

                          <div v-if="readMore">
                            <br />
                            Such as:<br />
                            <br />

                            <ul>
                              <li>Releases</li>
                              <li>Notifications</li>
                              <li>Timeline</li>
                            </ul>

                            <br />
                            However, their data such as releases will remain the
                            same in the app and they can still be added again to
                            the app at any point of time.
                          </div>
                          <b
                            v-if="!readMore"
                            class="pointer"
                            @click="readMore = !readMore"
                          >
                            Read more...
                          </b>
                        </v-alert>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions class="py-3">
                        <v-spacer />
                        <v-btn
                          class="text-transform-none"
                          color="primary"
                          text
                          @click="onCancelRemoveMember"
                        >
                          No
                        </v-btn>
                        <v-btn
                          :loading="busy"
                          :outlined="$vuetify.theme.dark"
                          class="text-transform-none"
                          color="primary"
                          @click="deleteMember"
                          >Yes
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </Modal>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.Invited {
  color: #fa6400;
}

.Suspended {
  color: #e02020;
}
</style>

<script>
import {
  get,
  getRelativeOrAbsoluteTime,
  isEmpty,
} from "@/core/services/helper.service";
import { mapGetters, mapMutations, mapState } from "vuex";
import AddMemberPanel from "@/view/components/Team/Members/AddMemberPanel.vue";
import { DELETE_MEMBER, MEMBERS } from "@/store/apps/appmember.module.js";
import ActionDropdown from "@/view/components/Common/ActionDropdown";
import DatatableWrapper from "@/view/components/App/DatatableWrapper";

const MEMBER_STATUS = {
  0: "Suspended",
  1: "Active",
};

const INVITE_STATUS = {
  0: "Rejected",
  1: "Invited",
};

export default {
  name: "app-members",
  components: {
    ActionDropdown,
    AddMemberPanel,
    DatatableWrapper,
  },
  data() {
    return {
      get,
      isEmpty,
      readMore: false,
      isLoaded: false,
      MEMBER_STATUS,
      INVITE_STATUS,
      page: 1,
      errorMessage: null,
      selectedId: "",
      loading: true,
      selectedMember: null,
      showRemoveModal: false,
      busy: false,
      error: "d",
      userName: {},
      pagination: {
        page: this.toNumber(this.$route.query.page),
        itemsPerPage: 10,
        totalItems: 0,
      },
      getInviteTime: getRelativeOrAbsoluteTime,
      headers: [
        {
          text: "Name",
          align: "start",
          width: 250,
          sortable: false,
          value: "name",
        },
        {
          text: "Status",
          align: "start",
          width: 100,
          sortable: false,
          value: "status",
        },
        {
          text: "Added to this app",
          align: "start",
          sortable: false,
          width: "120",
          value: "join",
        },
        {
          text: "Action",
          align: "end",
          sortable: false,
          width: "100",
          value: "action",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      members: "getMemberList",
      info: "getAppInfo",
      getInfoErrorMessage: "getInfoErrorMessage",
      getTotalAppMemberCount: "getTotalAppMemberCount",
      getTotalAppMemberList: "getTotalAppMemberList",
    }),
    ...mapState({
      user: (state) => state.auth.user,
    }),
    noData() {
      return isEmpty(this.members) ? true : false;
    },
    noInfo() {
      return isEmpty(this.info) ? true : false;
    },
  },
  watch: {
    "pagination.page"(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.getTotalAppMemberList.length > oldVal * 10) {
          this.setPreviousAppMemberData(
            this.getTotalAppMemberList.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.members[this.members.length - 1].id;
          this.loadData(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousAppMemberData(
          this.getTotalAppMemberList.slice(offset, limit)
        );
      }
    },
    "$route.params.app_id"() {
      this.$store.dispatch(MEMBERS, this.$route.params.app_id).catch((err) => {
        this.logError(err);
        this.notifyErrorMessage({ message: err.message });
      });
    },
  },
  created() {
    this.loading = false;
    this.loadData();
  },
  methods: {
    ...mapMutations({
      removeAppMember: "removeAppMember",
      removeMemberStatsCount: "removeMemberStatsCount",
      setPreviousAppMemberData: "setPreviousAppMemberData",
      removeAppMemberStats: "removeAppMemberStats",
    }),
    handleOnChange(val) {
      this.pagination.page = val;
    },
    removeMember(item) {
      this.selectedId = item.id;
      this.userName = item.name;
      this.showRemoveModal = true;
    },
    async deleteMember() {
      this.busy = true;
      try {
        const response = await this.$store.dispatch(DELETE_MEMBER, {
          app_id: this.$route.params.app_id,
          member_id: this.selectedId,
        });
        this.removeAppMember(this.selectedId);
        this.removeAppMemberStats();
        this.setPreviousAppMemberData(
          this.getTotalAppMemberList.slice((this.page - 1) * 10, this.page * 10)
        );
        // this.page = 1;
        this.notifyUserMessage({
          message:
            response.message ||
            `${
              this.selectedMemeber ? this.selectedMember.name : "Member"
            } deleted successfully`,
        });
        this.showRemoveModal = false;
      } catch (err) {
        this.showRemoveModal = false;
        this.notifyErrorMessage(
          err.message || "Something went wrong, please try again"
        );
      }
      this.busy = false;
    },

    onCancelRemoveMember() {
      this.showRemoveModal = false;
      this.selectedMember = null;
    },
    loadData(lastId) {
      // start the loading spinner
      this.errorMessage = null;
      if (!this.getInfoErrorMessage) {
        if (this.loading) return;
        this.loading = true;
        this.$store
          .dispatch(MEMBERS, { app_id: this.$route.params.app_id, lastId })
          .then(() => {
            this.loading = false;
            if (!lastId) {
              this.pagination.page = 1;
              this.pagination.totalItems = this.getTotalAppMemberCount;
            }
          })
          .catch((err) => {
            this.errorMessage = err.message || null;
            this.loading = false;
          });
      }
    },
  },
};
</script>
