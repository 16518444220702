<template>
    <v-card class="mt-5 mb-5">
        <v-row dense>
            <v-col class="px-0" v-for="i in 5" :key="i" cols="12">
                <v-skeleton-loader class="px-0" type="list-item-avatar-three-line"></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-overlay z-index="1" light absolute :value="true">
            <div class="text-center">
                <div class="mb-3" size="40">
                    <v-icon v-text="'mdi-lock'"></v-icon>
                </div>
                <v-btn color="primary" class="text-transform-none" :outlined="$vuetify.theme.dark" @click="openSubscriptionModal = true">
                    Upgrade to unlock all records
                </v-btn>
            </div>
        </v-overlay>
        <Modal :width="$vuetify.breakpoint.mdAndUp ? 1100 : ''" :fullscreen="$vuetify.breakpoint.smAndDown" @close="openSubscriptionModal = false"
            v-model="openSubscriptionModal">
            <template #message>
                <plan-pricing from-modal from-overlay :upgrade-plan-text="message" @success="$emit('close'), (openSubscriptionModal = false)"
                    @close="openSubscriptionModal = false"></plan-pricing>
            </template>
        </Modal>
    </v-card>
</template>

<script>
import PlanPricing from "@/view/components/Team/PlanPricing";
export default {
    components: { PlanPricing },
    props: {
        totalLength: {
            type: Number,
            default: 0,
        },
        message: {
            type: String,
            default: "Upgrade your plans to access all records and more...",
        },
    },
    data() {
        return {
            openSubscriptionModal: false,
        };
    },
};
</script>
