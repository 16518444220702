<template>
  <v-row>
    <v-col v-if="canManageInvite" class="d-flex">
      <v-spacer />
      <InviteMembersPanel @success="loadInvitesList" />
    </v-col>
    <v-col cols="12" v-if="!canViewInvite">
      <no-data
        first-text="Ops!"
        second-text="You don't have permission to view invites"
      >
      </no-data>
    </v-col>
    <v-col v-else cols="12">
      <div class="members mt-4">
        <v-skeleton-loader
          v-if="loading"
          class="transparent"
          type="table-thead, table-tbody"
        />

        <!-- <noData v-else-if="noData" firstText="sorry you have no Invites..." /> -->
        <div v-else-if="noData" class="no-data-found">
          <inline-svg
            :src="`/media/placeholders/no-app-members${
              $vuetify.theme.dark ? '-dark' : ''
            }.svg`"
            class="mt-6 svg-icon empty_icon"
          />
          <p class="mt-4 bold-text bodyFont darkGrey--text text-center">
            No Invites Yet!
          </p>
          <p class="mt-4 mb-5 bodyFont darkGrey--text text-center">
            Boost collaboration by inviting team members. They can help
            <strong>manage</strong> your app or <strong>test</strong> it
            internally via our App. Start inviting now!
          </p>
        </div>
        <DatatableWrapper
          v-else
          :pagination="pagination"
          :loading="loading"
          @onChange="handleOnChange"
        >
          <v-data-table
            :headers="headers"
            :items="getTeamInvitesList"
            hide-default-footer
            mobile-breakpoint="0"
          >
            <template #item.idAndEmail="{ item }">
              <b>
                {{ item.email ? item.email : item.id }}
              </b>
              <div>
                {{ "By" + " " + item.invited_by.name }}
              </div>
            </template>

            <template #item.role="{ item }">
              {{ item.role.name }}
            </template>

            <template #item.totalApps="{ item }">
              <v-btn
                class="primary--text"
                icon
                @click="openAppsModal(item.apps)"
              >
                <b>
                  {{ item.apps.length }}
                </b>
              </v-btn>
            </template>
            <template v-if="urlList" #item.total_joins="{ item }">
              {{ item && item.meta ? item.meta.total_joins || "-" : "-" }}
            </template>
            <template #item.status="{ item }">
              {{ item.status == 1 ? "Active" : "In active" }}
            </template>
            <template #item.lastUpdate="{ item }">
              <vue-hoverable-date
                :date="parseInt(item.created_at)"
              ></vue-hoverable-date>
            </template>

            <template #item.action="{ item }">
              <action-dropdown v-if="canManageInvite">
                <template #list>
                  <v-list-item @click="setEdit(item)">
                    <v-icon small>edit</v-icon>
                    edit
                  </v-list-item>
                  <v-list-item @click="copyText(item.id)">
                    <v-icon small v-text="'mdi-content-copy'" />
                    Copy URL
                  </v-list-item>
                  <v-list-item @click="openDelete(item)">
                    <v-icon small>cancel</v-icon>
                    Withdraw invite
                  </v-list-item>
                </template>
              </action-dropdown>
            </template>
            <template #footer.page-text>
              <div>
                <v-btn
                  class="text-transform-none"
                  color="darkGrey"
                  text
                  @click="$emit('refresh')"
                >
                  <v-icon
                    :color="$vuetify.theme.dark ? '' : 'grey'"
                    left
                    v-text="'mdi-reload'"
                  >
                  </v-icon>
                  Reload
                </v-btn>
                Showing
                <b
                  >{{ totalItems ? offset : 0 }} to
                  {{ offset + (data.length - 1) }}</b
                >
                of <b>{{ totalItems }}</b>
              </div>
            </template>
          </v-data-table>
        </DatatableWrapper>

        <!-- here we have the edit navigation drawer and the leave dialog -->
        <SidePanel v-model="drawer" :loading="busy" @close="drawer = false">
          <!--  -->
          <v-card v-if="drawer" flat>
            <v-card-title class="display-1 justify-center mb-8 mt-n3">
              Edit
            </v-card-title>
            <div class="mt-4">
              <v-row class="mt-4">
                <v-col v-if="!$route.params.app_id" cols="12">
                  <AppAutoComplete
                    v-model="selectedapps"
                    :initial-data="initailData"
                    class="mb-4"
                    multiple
                  />
                </v-col>
                <v-col class="semi-bold bodyFont mb-3" v-else cols="12">
                  {{ appInfo.name }}
                </v-col>
              </v-row>
            </div>
            <Roles
              v-if="drawer"
              :permission="selectedPermission"
              :update-roles="updateroles"
              @updateRoles="saveRoles"
              @updateTheRole="saveTheRole"
            />
            <div class="text-center">
              <v-btn
                :loading="busy"
                :outlined="$vuetify.theme.dark"
                class="text-transform-none py-7 px-15 mx-auto mt-13"
                color="primary"
                x-large
                @click="updateInvite"
              >
                <span class="font14"> Save </span>
              </v-btn>
            </div>
          </v-card>
        </SidePanel>

        <!-- <Modal
          title="Delete invite"
          message="Are you sure you want to delete this invite? There's no undo for this action."
          @onConfirm="onConfirm"
          @onCancel="onCancel"
          :loading="loading"
          v-model="modal"
        /> -->

        <Modal v-model="modal" @close="modal = false">
          <template #card>
            <v-card>
              <v-card-title class="font-weight-bold"
                >Delete invite</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text class="my-4 message">
                <p>Are you sure you want to delete this invite?</p>
              </v-card-text>
              <v-divider />
              <v-card-actions class="py-3">
                <div>No undo for this action</div>
                <v-spacer />
                <v-btn
                  class="text-transform-none"
                  color="primary"
                  text
                  @click="onCancel"
                  >No
                </v-btn>
                <v-btn
                  :loading="loading"
                  :outlined="$vuetify.theme.dark"
                  class="text-transform-none"
                  color="primary"
                  @click="onConfirm"
                  >Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </Modal>

        <v-snackbar v-model="notify.show" :color="notify.color">
          {{ notify.message }}
        </v-snackbar>
        <Modal v-model="showAppModal" persistent scrollable width="560">
          <template v-if="showAppModal" #message>
            <v-card-title>
              <div class="mr-1 mb-4">
                Apps (<b>{{ modalsApps.length }}</b
                >)
              </div>
              <v-spacer />
            </v-card-title>
            <v-card-text class="pb-3">
              <div
                v-if="!searchedModalsApp.length"
                class="text-center bold-text title"
              >
                {{
                  !search
                    ? "No apps added in this invite"
                    : "No app match " + search + " keys"
                }}
              </div>
              <div v-else>
                <v-card
                  v-for="(app, index) in searchedModalsApp"
                  :key="index"
                  class="mb-2 pa-2"
                  outlined
                >
                  <v-row align="center">
                    <v-col>
                      <div class="d-flex align-center">
                        <div class="flex-shrink-0 mr-4">
                          <v-avatar size="50">
                            <img
                              :src="getAppImage(app)"
                              @error="replaceDefaultUserImage"
                            />
                          </v-avatar>
                        </div>
                        <div class="">{{ app.name }}</div>
                      </div>
                    </v-col>
                    <v-col
                      :class="{ 'text-center': $vuetify.breakpoint.mdAndDown }"
                      cols="6"
                      md="3"
                    >
                      <PlatformIcon
                        v-if="app.platforms.includes('android')"
                        :color="
                          app.platforms.includes('android') ? 'grey' : 'primary'
                        "
                        android
                      />
                      <span class="mx-2"></span>
                      <PlatformIcon
                        v-if="app.platforms.includes('ios')"
                        :color="
                          app.platforms.includes('ios') ? 'grey' : 'primary'
                        "
                        apple
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </v-card-text>
          </template>
        </Modal>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import AppAutoComplete from "@/view/components/Common/AppSearch.vue";
import Roles from "@/view/components/Team/Members/Roles.vue";
import InviteMembersPanel from "@/view/components/Invites/InviteMembersPanel.vue";
import { DELETE_INVITES, UPDATE_INVITES } from "@/store/team/invite.module";
import { currentTimestamp, get, isEmpty } from "@/core/services/helper.service";
import { mapGetters, mapMutations, mapState } from "vuex";
import ActionDropdown from "@/view/components/Common/ActionDropdown";
import DatatableWrapper from "@/view/components/App/DatatableWrapper";
import { GET_INVITE_LIST } from "@/store/team/invite.module";

export default {
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    page: {
      type: [Number, String],
      default: 1,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    urlList: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: Number,
      default: 1,
    },
    totalCount: {
      type: [Number, String],
      default: "",
    },
  },
  components: {
    ActionDropdown,
    AppAutoComplete,
    Roles,
    InviteMembersPanel,
    DatatableWrapper,
  },
  data() {
    return {
      dialog: null,
      drawer: null,
      modal: false,
      busy: false,
      loading: false,
      selectedRoles: "",
      search: "",
      copyAlert: false,
      show2: false,
      selectedapps: [],
      initailData: [],
      slectedId: "",
      notify: { show: false },
      inviteMessage: "",
      selectedPermission: null,
      modalsApps: [],
      pageValue: 1,
      updateroles: "",
      selectedDetails: {},
      showAppModal: false,
      headers: [
        {
          text: "ID / Email",
          align: "start",
          sortable: false,
          width: "150",
          value: "idAndEmail",
        },
        {
          text: "Role",
          align: "start",
          width: 50,
          sortable: false,
          value: "role",
        },
        {
          text: "Apps",
          align: "center",
          sortable: false,
          width: "25",
          value: "totalApps",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          width: "25",
          value: "status",
        },
        {
          text: "Last Updated",
          align: "start",
          sortable: false,
          width: "120",
          value: "lastUpdate",
        },
        {
          text: "Action",
          align: "end",
          sortable: false,
          width: "10",
          value: "action",
        },
      ],
      pagination: {
        page: 1,
        offset: 0,
        limit: 10,
        itemsPerPage: 10,
        totalItems: 0,
      },
    };
  },
  computed: {
    totalItems() {
      return this.getTotalTeamInvitesCount;
    },
    noData() {
      return !this.getTotalTeamInvitesCount;
    },
    ...mapState({
      roles: (state) => get(state, "team.roles", ""),
    }),
    ...mapGetters({
      getInviteUrl: "getInviteUrl",
      appInfo: "getAppInfo",
      getTotalTeamInvitesCount: "getTotalTeamInvitesCount",
      getTeamInvitesList: "getTeamInvitesList",
      getTotalTeamInvites: "getTotalTeamInvitesList",
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
    searchedModalsApp() {
      return this.modalsApps.filter(
        (app) =>
          app &&
          app.name &&
          app.name.toLowerCase().includes(this.search.toLowerCase() || "")
      );
    },
  },
  watch: {
    "pagination.page"(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.getTotalTeamInvites.length > oldVal * 10) {
          this.setPreviousTeamInviteData(
            this.getTotalTeamInvites.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.getTeamInvitesList.length
            ? this.getTeamInvitesList[this.getTeamInvitesList.length - 1].id
            : "";
          this.loadInvitesList(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousTeamInviteData(
          this.getTotalTeamInvites.slice(offset, limit)
        );
      }
    },
    getTotalTeamInvitesCount(val) {
      this.pagination.totalItems = val;
    },
    copyAlert() {
      this.notifyUserMessage({ message: "Invite URL copied successfully" });
    },
    drawer() {
      if (!this.drawer) {
        (this.selectedPermission = null), (this.selectedRoles = null);
      }
    },
  },
  created() {
    if (this.urlList) {
      this.headers.splice(3, 0, {
        text: "Joined",
        align: "start",
        width: "10%",
        sortable: false,
        value: "total_joins",
      });
    }
    this.loadInvitesList();
  },
  methods: {
    ...mapMutations({
      setPreviousTeamInviteData: "setPreviousTeamInviteData",
    }),
    async loadInvitesList(lastId) {
      if (!this.getInactiveUserMessage && this.canViewInvite) {
        if (this.loading) return;
        this.loading = true;
        await this.$store
          .dispatch(GET_INVITE_LIST, {
            lastID: lastId,
            appID: this.$route.params.app_id || "",
          })
          .then(() => {
            this.pagination.totalItems = this.getTotalTeamInvitesCount;
            this.loading = false;
          })
          .catch((err) => {
            if (err.status !== 403) {
              this.notifyErrorMessage({ message: err.message });
            }
            this.loading = false;
          });
      }
    },
    handleOnChange(value) {
      this.pagination.page = value;
    },
    copyText(id) {
      let input = document.createElement("input");
      let copyText = document.body.appendChild(input);
      copyText.value = `${this.getInviteUrl}${id}`;
      copyText.select();
      document.execCommand("copy");
      this.notifyUserMessage({ message: "Invite URL copied successfully" });
      if (input) {
        document.body.removeChild(input);
      }
    },
    openAppsModal(details) {
      this.modalsApps = [...details];
      this.showAppModal = true;
    },
    setEdit(details) {
      this.selectedDetails = { ...details };
      if (!this.$route.params.app_id) {
        this.initailData = [...details.apps];
      }
      this.inviteMessage = details.message;
      this.updateroles = { ...details.role };
      this.selectedPermission = { ...details.permissions };
      this.slectedId = details.id;
      this.$nextTick(() => {
        this.drawer = true;
      });
    },
    ...mapMutations({
      appendTeamInvites: "appendTeamInvites",
      removeFromTeamInvites: "removeFromTeamInvites",
    }),
    updateInvite() {
      if (this.$route.params.app_id) {
        this.selectedapps = [this.appInfo];
      }
      if (this.busy) return;
      this.busy = true;
      let data = {
        apps:
          this.selectedapps && this.selectedapps.length
            ? this.selectedapps.map((app) => app.id)
            : [],
        role_id: this.mainRole,
        permissions: this.selectedRoles,
      };

      data.role_id == null || false || "" ? delete dasta["role_id"] : "";
      isEmpty(data.permissions) ? (data.permissions = this.selectedRoles) : "";
      this.$store
        .dispatch(UPDATE_INVITES, {
          id: this.slectedId,
          data: JSON.stringify(data),
        })
        .then(() => {
          this.busy = false;
          const dataToAppend = {
            id: this.slectedId,
            invited_by: { ...this.selectedDetails.invited_by },
            apps: [...this.selectedapps],
            status: 1,
            meta: {},
            email: this.selectedDetails.email || "",
            permissions: { ...data.permissions },
            role: {
              id: data.role_id,
              name: this.roles.find((el) => el.id === data.role_id).name,
            },
            created_at: currentTimestamp(),
          };
          this.appendTeamInvites(dataToAppend);
          this.drawer = false;
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
    openDelete(details) {
      this.slectedId = details.id;
      this.selectedDetails = { ...details };
      this.modal = true;
    },
    onCancel() {
      this.modal = false;
    },
    onConfirm() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(DELETE_INVITES, this.slectedId)
        .then((response) => {
          this.modal = false;
          this.loading = false;
          this.notifyUserMessage({ message: response.message });
          this.removeFromTeamInvites(this.slectedId);
          this.$nextTick(() => {
            if (!this.data.length && this.totalCount && this.page == 1) {
              this.$emit("refresh");
            }
          });
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
          this.modal = false;
        });
    },
    saveTheRole(v) {
      this.mainRole = v;
    },
    saveRoles(v) {
      this.selectedRoles = v;
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/assets/sass/pages/manage-Team";
// @import "@/assets/sass/custom/table-theme";
</style>
<style lang="scss">
.col-with-dropdown {
  padding: 2px;
  position: relative;

  .the-drop-down {
    bottom: calc(50% - 19px);
    position: absolute;
    right: 10px;
  }
}
</style>
