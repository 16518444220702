var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_c('div', {
    staticClass: "mt-4 bodyFont bold-text",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Change Page Status ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('close')
    }
  })], 1)], 1), _c('v-card-text', [_c('v-radio-group', {
    staticClass: "mt-3",
    model: {
      value: _vm.formValues.show_as,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "show_as", $$v);
      },
      expression: "formValues.show_as"
    }
  }, [_vm._l(_vm.previewItems, function (item) {
    return [_c('v-row', {
      key: item.value,
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "pr-0",
      attrs: {
        "cols": "1"
      }
    }, [_c('v-radio', {
      attrs: {
        "value": item.value
      }
    })], 1), _c('v-col', {
      staticClass: "pl-0",
      attrs: {
        "cols": "11"
      }
    }, [_c('b', [_vm._v(_vm._s(item.title) + ":")]), _vm._v(" "), _c('span', [_vm._v(_vm._s(item.description))])]), [4].includes(_vm.formValues.show_as) && item.value === 4 ? _c('v-col', {
      staticClass: "px-4",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-text-field', _vm._b({
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required|min:3|max:70',
        expression: "'required|min:3|max:70'"
      }],
      staticClass: "mt-n4",
      attrs: {
        "filled": "",
        "label": "Custom Message",
        "hint": "Optional - By default, we display a message but you can choose a custom one to be displayed at top of the page for the external testers.",
        "counter": "70",
        "persistent-hint": ""
      },
      model: {
        value: _vm.formValues.show_as_message,
        callback: function callback($$v) {
          _vm.$set(_vm.formValues, "show_as_message", $$v);
        },
        expression: "formValues.show_as_message"
      }
    }, 'v-text-field', _vm.veeValidate('Message', ''), false))], 1) : _vm._e()], 1), _c('v-row', {
      key: item.value
    }, [_c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-divider')], 1)], 1)];
  })], 2)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark,
      "loading": _vm.busy,
      "depressed": ""
    },
    on: {
      "click": _vm.submitDetail
    }
  }, [_vm._v(" Save ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }