<template>
    <div v-if="!full">
      {{ defaultDevice }}
        <v-tooltip bottom>
            <template #activator="{ on }">
                <v-icon v-if="!defaultDevice && isEmpty(defaultDevice)" v-on="on" v-text="
                    ['Android', 'iOS'].includes(os && os.name)
                        ? 'mdi-cellphone'
                        : 'mdi-monitor'
                ">
                </v-icon>
                <v-icon v-else v-on="on" v-text="
                    ['Android', 'iOS'].includes(defaultDevice.os)
                        ? 'mdi-cellphone'
                        : 'mdi-monitor'
                ">
                </v-icon>
            </template>
            <span>
                <div v-if="defaultDevice && !isEmpty(defaultDevice)">
                    <span class="text-capitalize">
                        {{ defaultDevice.brand ?? defaultDevice.manufacturer }}
                    </span>
                    <span>{{ defaultDevice?.device ?? defaultDevice?.model }}</span>
                    <div>
                        {{ defaultDevice.os + " " }}
                      {{  defaultDevice?.os_version || defaultDevice.version}}
                    </div>
                </div>
            </span>
        </v-tooltip>
    </div>
    <div v-else class="py-3 pl-2">
        <div v-if="defaultDevice && !isEmpty(defaultDevice)">
            <v-row class="text-left">
                <v-col class="pt-0 pb-0 pl-0 pr-2 my-auto" cols="auto">
                    <v-icon v-text="
                        ['Android', 'iOS'].includes(defaultDevice.os)
                            ? 'mdi-cellphone'
                            : 'mdi-monitor'
                    ">
                    </v-icon>
                </v-col>
                <v-col class="pt-0 pb-0 pl-0 pr-0" cols="auto">
                    <div v-if="defaultDevice && !isEmpty(defaultDevice)">
                        <span class="text-capitalize">
                            {{ defaultDevice.brand ?? defaultDevice?.manufacturer }}
                        </span>
                        <span>{{ defaultDevice?.device ?? defaultDevice?.model }}</span>
                        <div>
                            {{ defaultDevice.os + " "   }}
                            {{  defaultDevice?.os_version || defaultDevice.version}}
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { get, isEmpty } from "@/core/services/helper.service.js";
import { appDeviceList } from "@/consts/appDeviceConst.js";

export default {
    props: {
        full: {
            type: Boolean,
            default: false,
        },
        defaultDevice: {
            type: Object,
            default() {
                return null;
            },
        },
    },
    data() {
        return {
            get,
            isEmpty,
            appDeviceList,
        };
    },
};
</script>
