<template>
  <div>
    <div v-if="!rolesOnly">
      <v-skeleton-loader type="heading" class="mb-3" width="170" />
      <v-skeleton-loader type="image" height="56" />
      <div class="my-15"></div>
      <v-skeleton-loader type="heading" class="mb-3" width="250" />
      <v-skeleton-loader type="image" height="56" />
      <div class="my-15"></div>
      <v-skeleton-loader type="heading" class="mb-3" width="220" />
      <v-skeleton-loader type="image" height="120" />
      <div class="my-15"></div>
    </div>

    <div>
      <v-skeleton-loader
        v-for="i in 4"
        :key="i + '_key'"
        type="text"
        class="mb-2"
        :width="i == 1 || i == 3 ? '100' : '200'"
      />
      <div class="my-15"></div>
      <v-row>
        <v-col cols="6"></v-col>
        <v-col cols="3">
          <v-skeleton-loader type="text" />
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader type="text" />
        </v-col>
      </v-row>
      <v-row v-for="i in 4" :key="i + '__key'">
        <v-col cols="6">
          <v-skeleton-loader type="text" width="90" />
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader type="text" width="20" />
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader type="text" width="20" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rolesOnly: Boolean,
  },
};
</script>

<style lang="scss" scoped></style>
