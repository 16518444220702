var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_c('div', [_vm._v("Update Auto Archive settings for " + _vm._s(_vm.settingName))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-close')
    }
  })], 1)], 1), _c('v-card-text', [_c('v-row', [_vm.settingName === 'team' ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "outlined": "",
      "color": "info"
    }
  }, [_vm._v(" This setting will be applied to all apps in your team for new release ")])], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Never",
      "value": 0,
      "hide-details": "",
      "disabled": !_vm.getTeamPlanUsage.auto_archive_release
    },
    model: {
      value: _vm.noValue,
      callback: function callback($$v) {
        _vm.noValue = $$v;
      },
      expression: "noValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.selectedDay,
      "hide-details": "",
      "disabled": _vm.noValue || !_vm.getTeamPlanUsage.auto_archive_release,
      "filled": "",
      "type": "number"
    },
    model: {
      value: _vm.selectedDay,
      callback: function callback($$v) {
        _vm.selectedDay = $$v;
      },
      expression: "selectedDay"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "hide-details": "",
      "disabled": _vm.noValue || !_vm.getTeamPlanUsage.auto_archive_release,
      "filled": "",
      "items": ['Days', 'Months']
    },
    model: {
      value: _vm.selectedTime,
      callback: function callback($$v) {
        _vm.selectedTime = $$v;
      },
      expression: "selectedTime"
    }
  })], 1), !_vm.getTeamPlanUsage.auto_archive_release ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({}, on), [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('start-trail');
            }
          }
        }, [_c('v-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "color": "primary"
          },
          domProps: {
            "textContent": _vm._s('mdi-lock-outline')
          }
        })], 1)], 1)];
      }
    }], null, false, 1411934760)
  }, [_c('span', [_vm._v("Auto archive settings is available for Starter & Pro team plans")])])], 1) : _vm._e(), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Note: Maximum 90 days or 3 months ")])], 1)], 1)], 1)], 1), !_vm.noValue && _vm.selectedDay != 0 ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "subListFont"
  }, [_c('v-alert', {
    staticClass: "mt-3",
    attrs: {
      "border": "left",
      "color": "info",
      "icon": "info",
      "outlined": ""
    }
  }, [_vm._v(" By archiving releases, we will be deleting the (.aab / .apk / .ipa) "), _c('br'), _c('br'), _vm._v(" Those releases will not be visible or available for installation in our app and public install links."), _c('br'), _c('br'), _vm._v(" Keeping all the data attached to these releases such as notes, installs, feedback,... for your future reference. ")]), _c('v-alert', {
    staticClass: "mt-3",
    attrs: {
      "border": "left",
      "color": "success",
      "icon": "info",
      "outlined": ""
    }
  }, [_vm._v(" Archived releases will not be counted towards your team's storage quota 👍 ")])], 1)]) : _vm._e()], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "loading": _vm.busy,
      "disabled": !_vm.getTeamPlanUsage.auto_archive_release,
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.confirmUpdate
    }
  }, [_vm._v(" Update ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }