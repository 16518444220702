var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "topbar-item quick-add-member"
  }, [!_vm.dropDown ? _c('v-btn', {
    staticClass: "main",
    attrs: {
      "id": "add-member-panel-button",
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.drawer = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("person_add_alt_1")]), _vm._v(" Add Member ")], 1) : _c('action-dropdown', {
    attrs: {
      "largeBtn": false,
      "button": "Add Member",
      "icon": "person_add_alt_1"
    },
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn() {
        return [_c('v-list-item', {
          attrs: {
            "id": "addMemberFromApp"
          },
          on: {
            "click": function click($event) {
              _vm.drawer = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("person_add_alt_1")]), _vm._v(" Add from team ")], 1), _c('v-list-item', {
          attrs: {
            "id": "inviteToTeamBtn"
          },
          on: {
            "click": function click($event) {
              _vm.$router.push({
                name: 'app-settings-invite'
              }).catch(function () {});
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-email-outline")]), _vm._v(" Invite to team ")], 1)];
      },
      proxy: true
    }])
  }), _c('SidePanel', {
    attrs: {
      "loading": _vm.busy,
      "width": "520"
    },
    model: {
      value: _vm.drawer,
      callback: function callback($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('h1', {
    staticClass: "text-center mx-auto mb-8 display-1"
  }, [_vm._v("Add member")]), _c('div', {
    staticClass: "text-center bold-text title"
  }, [_vm._v(" " + _vm._s(_vm.app.name) + " ")]), _c('member-select', {
    key: _vm.app.id,
    attrs: {
      "app": _vm.app,
      "add-member": "",
      "invite-important": ""
    },
    on: {
      "isInvite": function isInvite($event) {
        _vm.isInvite = $event;
      },
      "selected": function selected($event) {
        _vm.selectedOption = $event;
      }
    },
    model: {
      value: _vm.selectedMember,
      callback: function callback($$v) {
        _vm.selectedMember = $$v;
      },
      expression: "selectedMember"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Notify members by email",
      "value": false
    },
    model: {
      value: _vm.notify,
      callback: function callback($$v) {
        _vm.notify = $$v;
      },
      expression: "notify"
    }
  }), _c('div', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "mt-13 px-15 py-7 text-transform-none",
    attrs: {
      "id": "invite-member-to-app",
      "disabled": _vm.busy || !_vm.app.id,
      "loading": _vm.busy,
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "x-large": ""
    },
    on: {
      "click": _vm.onAddMember
    }
  }, [_c('span', {
    staticClass: "font14"
  }, [_vm._v(" Submit ")])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }