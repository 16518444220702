var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "bottom": "",
      "left": "",
      "offset-y": "",
      "transition": "slide-x-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_vm.displayText ? _c('span', _vm._g({
          staticClass: "pointer"
        }, on), [_vm._v(" " + _vm._s(_vm.displayText || "displayText") + " ")]) : _vm.button ? _c('v-btn', _vm._g({
          staticClass: "text-transform-none",
          class: {
            'px-8 py-8': _vm.largeBtn
          },
          attrs: {
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary"
          }
        }, on), [_c('v-icon', {
          class: {
            'mr-5': _vm.largeBtn
          },
          attrs: {
            "large": _vm.largeBtn,
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icon || "insert_emoticon") + " ")]), _vm._v(" " + _vm._s(_vm.button) + " ")], 1) : _c('v-btn', _vm._g({
          attrs: {
            "id": "AppActionsDropdown",
            "absolute": _vm.absolute,
            "right": _vm.absolute,
            "top": _vm.absolute,
            "icon": ""
          }
        }, on), [_c('v-icon', {
          attrs: {
            "color": _vm.color ? _vm.color : 'primary'
          }
        }, [_vm._v("mdi-dots-horizontal")])], 1)];
      }
    }]),
    model: {
      value: _vm.menu,
      callback: function callback($$v) {
        _vm.menu = $$v;
      },
      expression: "menu"
    }
  }, [_c('v-list', {
    staticClass: "menu-list",
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item-group', {
    staticClass: "text-capitalize",
    model: {
      value: _vm.menu,
      callback: function callback($$v) {
        _vm.menu = $$v;
      },
      expression: "menu"
    }
  }, [_vm._t("list")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }