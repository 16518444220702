var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.detail.country ? _c('div', [_c('v-row', [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({}, on), [_vm.detail.country_code ? _c('Flag', {
          attrs: {
            "code": _vm.detail.country_code
          }
        }) : _vm._e()], 1)];
      }
    }], null, false, 1882243560)
  }, [_c('span', [_vm._v(_vm._s(_vm.detail.country))])])], 1), _c('v-col', {
    staticClass: "pr-0 pt-0 pb-0 pl-1",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.detail.city ? _c('span', [_c('MaxText', {
    attrs: {
      "text": _vm.detail.city,
      "max": "20"
    }
  })], 1) : _vm._e()])], 1)], 1) : _c('div', [_vm._v("-")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }