var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    attrs: {
      "height": "100%",
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('div', [_c('div', {
    staticClass: "bold-text text-body-1"
  }, [_vm._v(" Listed here are the team members granted access to this app. To adjust roles and permissions, please visit the "), _c('a', {
    staticClass: "text-decoration-underline",
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'team-members'
        });
      }
    }
  }, [_vm._v("team members")]), _vm._v(" page. ")])]), _c('v-spacer'), _vm.canManageApp ? _c('AddMemberPanel', {
    attrs: {
      "app-detail": _vm.info,
      "drop-down": ""
    },
    on: {
      "refresh": _vm.loadData
    }
  }) : _vm._e()], 1)], 1), _vm.getInfoErrorMessage ? _c('div', [_c('no-data', {
    attrs: {
      "first-text": _vm.getInfoErrorMessage,
      "permission-issues": ""
    }
  })], 1) : _c('div', [_c('div', [_vm.loading ? _c('v-skeleton-loader', {
    staticClass: "transparent",
    attrs: {
      "type": "table-thead, table-tbody"
    }
  }) : _vm.noData ? _c('noData') : _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('div', {
    attrs: {
      "flat": ""
    }
  }, [_vm.loading ? _c('Loader') : _vm._e(), !_vm.loading ? _c('div', [_c('DatatableWrapper', {
    attrs: {
      "loading": _vm.loading,
      "pagination": _vm.pagination,
      "on-change": _vm.handleOnChange
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.members,
      "hide-default-footer": "",
      "mobile-breakpoint": "0"
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('ProfileImage', {
          attrs: {
            "alt": item.name,
            "image": item.image,
            "size": "40"
          }
        })], 1), _c('v-col', [_c('div', [_vm._v(" " + _vm._s(_vm.get(item, "name", "")) + " ")]), _c('div', {
          staticClass: "text-muted"
        }, [_vm._v(" " + _vm._s(_vm.get(item, "role.name", "")) + " ")])])], 1)];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          class: item.status == 1 ? 'success--text' : 'error--text'
        }, [_vm._v(" " + _vm._s(item.status == 1 ? "Active" : "Inactive") + " ")])];
      }
    }, {
      key: "item.join",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('vue-hoverable-date', {
          attrs: {
            "date": item.created_at
          }
        })];
      }
    }, {
      key: "item.action",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm.user.id != item.user_id && _vm.canManageApp ? _c('action-dropdown', {
          attrs: {
            "id": "membersActionDropdown"
          },
          scopedSlots: _vm._u([{
            key: "list",
            fn: function fn() {
              return [_vm.currentUser.id !== item.user_id && _vm.canManageApp ? _c('v-list-item', {
                attrs: {
                  "id": "removeMemberFromApp"
                },
                on: {
                  "click": function click($event) {
                    return _vm.removeMember(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("cancel")]), _vm._v(" Remove ")], 1) : _vm._e()];
            },
            proxy: true
          }], null, true)
        }) : _c('v-icon', {
          staticClass: "mx-1",
          domProps: {
            "textContent": _vm._s('mdi-minus')
          }
        })];
      }
    }], null, false, 3948732462)
  })], 1), _c('Modal', {
    attrs: {
      "no-py": ""
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" Remove member ")]), _c('v-divider'), _c('v-card-text', {
          staticClass: "my-4 message"
        }, [_c('p', [_vm._v(" Are you sure you want to remove "), _c('b', [_vm._v(_vm._s(_vm.userName))]), _vm._v(" from this app? ")]), _c('v-alert', {
          staticClass: "mt-3",
          attrs: {
            "border": "left",
            "color": "info",
            "icon": "info",
            "outlined": ""
          }
        }, [_c('b', [_vm._v(_vm._s(_vm.userName))]), _vm._v(" will lose the access to any information regarding this app. "), _vm.readMore ? _c('div', [_c('br'), _vm._v(" Such as:"), _c('br'), _c('br'), _c('ul', [_c('li', [_vm._v("Releases")]), _c('li', [_vm._v("Notifications")]), _c('li', [_vm._v("Timeline")])]), _c('br'), _vm._v(" However, their data such as releases will remain the same in the app and they can still be added again to the app at any point of time. ")]) : _vm._e(), !_vm.readMore ? _c('b', {
          staticClass: "pointer",
          on: {
            "click": function click($event) {
              _vm.readMore = !_vm.readMore;
            }
          }
        }, [_vm._v(" Read more... ")]) : _vm._e()])], 1), _c('v-divider'), _c('v-card-actions', {
          staticClass: "py-3"
        }, [_c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": _vm.onCancelRemoveMember
          }
        }, [_vm._v(" No ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "loading": _vm.busy,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary"
          },
          on: {
            "click": _vm.deleteMember
          }
        }, [_vm._v("Yes ")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 3627783466),
    model: {
      value: _vm.showRemoveModal,
      callback: function callback($$v) {
        _vm.showRemoveModal = $$v;
      },
      expression: "showRemoveModal"
    }
  })], 1) : _vm._e()], 1)])], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }