<template>
  <v-card>
    <v-card-title>
      <div class="titleFont semibold">iOS App Sharing</div>
    </v-card-title>
    <v-card-text class="font-14">
      <v-row>
        <v-col cols="12">
          Share iOS apps within your team via our
          <a
            href="https://apps.apple.com/us/app/testapp-io/id1518972541"
            target="_blank"
            >iOS app</a
          >, in line with Apple's Ad-hoc build regulations.
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="semi-bold">Enterprise builds:</div>
          <ul>
            <li>Public link sharing enabled.</li>
            <li>No UDID collection needed.</li>
          </ul>
        </v-col>
        <v-col cols="12">
          <div class="semi-bold">Ad-Hoc builds:</div>
          <ul>
            <li>
              Install directly via our
              <a
                href="https://apps.apple.com/us/app/testapp-io/id1518972541"
                target="_blank"
                >iOS app</a
              >.
            </li>
            <li>Meets Apple's UDID guidelines.</li>
          </ul>
        </v-col>
        <v-col cols="12">
          <div class="semi-bold">All builds:</div>
          <ul>
            <li>
              Share your app with a TestFlight link with Android in a
              <span
                class="primary--text cursor-pointer"
                @click="
                  $router.push({
                    name: 'app-settings-share',
                    params: { app_id: $route.params.app_id },
                  })
                "
                >single page</span
              >.
            </li>
          </ul>
        </v-col>
        <v-col cols="12">
          <div class="semi-bold">Why the update?</div>
          <ul>
            <li>To resolve UDID management issues for Ad-Hoc builds.</li>
            <li>To align with Apple's distribution policies.</li>
          </ul>
        </v-col>
        <v-col cols="12">
          <div class="semi-bold">Take Action:</div>
          <ul>
            <li>
              Use '<span
                class="primary--text cursor-pointer"
                @click="$router.push({ name: 'invites' })"
                >Invite by URL</span
              >' to add Guests to apps:
            </li>
            <ul>
              <li>
                Seamless app installation from
                <a
                  href="https://apps.apple.com/us/app/testapp-io/id1518972541"
                  target="_blank"
                  >our app</a
                >.
              </li>
              <li>Notifications on updates.</li>
              <li>Feedback on specific releases.</li>
            </ul>
          </ul>
        </v-col>
        <v-col cols="12">
          <div class="semi-bold">Benefits of the Guest Role:</div>
          <ul>
            <li>Invite Guests for free, without affecting your team cap.</li>
            <li>
              Guests can interact with app releases while team privacy is
              maintained.
            </li>
            <li>Developer's name visible for transparency.</li>
          </ul>
        </v-col>
        <v-col cols="12">
          For support, please
          <span
            class="primary--text cursor-pointer"
            @click="openIntercomChat(contactMessage)"
            >contact our help team</span
          >.
        </v-col>

        <v-col
          cols="12"
          align="center"
          v-if="$route.name !== 'app-settings-share'"
        >
          <v-btn
            depressed
            class="text-transform-none"
            color="primary"
            @click="
              $router.push({
                name: 'app-settings-share',
                params: { app_id: $route.params.app_id },
              })
            "
          >
            Manage share links
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      contactMessage: {
        message: "Hi, I have a question regarding the iOS sharing",
        subject: "iOS Sharing",
      },
    };
  },
};
</script>
