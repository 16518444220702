var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "titleFont"
  }, [_vm._v(" Stats for " + _vm._s(_vm.fromSpecificLink ? "the selected public install link" : "all public install links") + " ")])]), _vm.fromSpecificLink ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "text-right"
  }, [_c('chart-date-selector', {
    on: {
      "change": function change($event) {
        _vm.installChartYear = $event;
      }
    }
  })], 1), _vm.loading ? _c('div', [_c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-avatar, divider, list-item-three-line, card-heading"
    }
  })], 1) : _vm._e(), !_vm.loading ? _c('apexchart', {
    attrs: {
      "options": Object.assign({}, _vm.barChartOptions, {
        title: {
          text: 'Installs',
          align: 'center',
          style: {
            fontSize: '16px'
          }
        }
      }),
      "series": _vm.installsChartData,
      "display-legend": "",
      "type": "bar"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "text-right"
  }, [_c('chart-date-selector', {
    on: {
      "change": function change($event) {
        _vm.viewsChartYear = $event;
      }
    }
  })], 1), _vm.loading ? _c('div', [_c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-avatar, divider, list-item-three-line, card-heading"
    }
  })], 1) : _vm._e(), !_vm.loading ? _c('apexchart', {
    attrs: {
      "options": Object.assign({}, _vm.barChartOptions, {
        title: {
          text: 'Views',
          align: 'center',
          style: {
            fontSize: '16px'
          }
        }
      }),
      "series": _vm.viewsChartData,
      "display-legend": "",
      "type": "bar"
    }
  }) : _vm._e()], 1)], 1)], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_c('span', {
    class: {
      'darkGrey--text': _vm.$vuetify.theme.dark
    }
  }, [_vm._v("Views")])]), _c('v-tab', [_c('span', {
    class: {
      'darkGrey--text': _vm.$vuetify.theme.dark
    }
  }, [_vm._v(" Installs ")])])], 1), _c('v-tabs-items', {
    attrs: {
      "touchless": ""
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('share-views', {
    attrs: {
      "fromSpecificLink": _vm.fromSpecificLink,
      "share-id": _vm.shareId
    }
  })], 1), _c('v-tab-item', [_c('install-list', {
    attrs: {
      "share-id": _vm.shareId
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }