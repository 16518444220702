<template>
  <div>
    <v-menu
      v-if="!getInactiveUserMessage && !getIsUserDisabled"
      v-model="menu"
      bottom
      left
      offset-y
      :close-on-click="false"
      transition="slide-x-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          :outlined="$vuetify.theme.dark"
          class="text-transform-none"
          color="primary"
          ref="invite-btn"
          id="inviteBtn"
          v-on="on"
        >
          <v-icon left> person_add_alt_1 </v-icon>
          Invite Member
        </v-btn>
      </template>
      <v-list>
        <v-list-item id="inviteByEmailBtn" @click="openEmailDrawer">
          <v-icon small>mdi-email-outline</v-icon>
          By Email
        </v-list-item>

        <!-- item in the drop down -->

        <v-list-item id="inviteByUrlBtn" @click="openURLDrawer">
          <v-icon small>mdi-link</v-icon>
          By URL
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- this is a sidepanel for invite by URL it should be in the dashboardEmpty components but here fine -->
    <SidePanel
      v-model="inviteDrawer"
      :hide-overlay="hideOverlay"
      :loading="inviteLoader"
    >
      <div class="invite-drawer">
        <div class="text-center mb-13 display-1 bold-text">
          {{ activeDrawer == "byEmail" ? "Invite by Email" : "Invite by URL" }}
        </div>
        <h2 class="mt-12 text-center">{{ info?.name }}</h2>
        <!--        <div class="text-center mb-8">-->
        <!--          <span class="mr-2">Total member: </span>-->
        <!--          <b-->
        <!--            >{{ getTotalMembersCount }}/{{-->
        <!--              getTeamPlanLimits.members || "∞"-->
        <!--            }}</b-->
        <!--          >-->
        <!--        </div>-->
        <!--  -->
        <invite-members-loader v-if="loading" />
        <!-- <div>
          <ContentLoader></ContentLoader>
        </div> -->
        <div>
          <div v-if="inviteURL">
            <!-- <v-btn id="invite-url-back" class="main mb-7" @click="refreshUrl">
              <v-icon left>keyboard_arrow_left</v-icon>
              back
            </v-btn> -->

            <v-text-field
              id="inviteUrlField"
              v-model="inviteURL"
              disabled
              filled
              label="Invite URL"
              messages="copy it, and send it to your testers"
              placeholder="invite's URL field"
            ></v-text-field>
            <v-btn
              id="copy-invite-url"
              color="primary"
              :outlined="$vuetify.theme.dark"
              class="mx-auto mt-6"
              @click="copy(inviteURL)"
            >
              copy
            </v-btn>
          </div>

          <!--  -->
          <div v-else>
            <div v-if="activeDrawer == 'byEmail'" class="mt-4">
              <v-row class="mt-4" wrap>
                <v-col cols="12">
                  <AutoComplete
                    id="inviteEmailField"
                    :email="true"
                    label="Emails"
                    placeholder="Enter or paste emails here..."
                    @update="setEmails"
                  />
                </v-col>
              </v-row>
            </div>
            <div class="mt-4">
              <v-row class="mt-4" wrap>
                <v-col class="text-left" cols="12">
                  <AppAutoComplete
                    v-if="!$route.params.app_id"
                    id="inviteAppAutocomplete"
                    ref="app-autocomplete"
                    v-model="selectedApps"
                    class="mb-4"
                    multiple
                  />
                  <div class="semi-bold" v-else>
                    App Name: {{ getAppInfo?.name }}
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="mt-4">
              <template>
                <v-textarea
                  id="inviteMessageField"
                  v-model="inviteMessage"
                  v-validate="'max:200'"
                  auto-grow
                  class="mt-8"
                  counter="200"
                  filled
                  label="Message"
                  placeholder="Your message for this invite... (Optional)"
                  v-bind="veeValidate('Message', 'Message')"
                ></v-textarea>
              </template>
            </div>

            <Roles
              v-if="inviteDrawer"
              ref="theRoleComponent"
              @updateRoles="saveRoles"
              :show-tour="showTour"
              @updateTheRole="saveTheRole"
              @create-invite="getAll"
            />
            <div class="mt-8">
              <v-divider></v-divider>
              <v-checkbox v-model="notify" class="mt-6">
                <template #label>
                  <div>
                    Notify me by email when accepting
                    <vue-tooltip
                      VHtml="Uncheck this if you don't want to be notified by email for this invite"
                      icon-color="darkGrey"
                    ></vue-tooltip>
                  </div>
                </template>
              </v-checkbox>
            </div>
            <div class="text-center mt-10">
              <v-btn
                id="inviteCreateBtn"
                :disabled="!ifallDone || inviteLoader"
                :loading="inviteLoader"
                :outlined="$vuetify.theme.dark"
                class="py-7 px-15 text-transform-none"
                color="primary"
                x-large
                @click="getAll"
              >
                <span class="font14"> Invite </span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </SidePanel>
  </div>
</template>

<script>
import Roles from "@/view/components/Team/Members/Roles.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import { currentTimestamp, get, isEmpty } from "@/core/services/helper.service";
import { CREAT_INVITE } from "@/store/team/invite.module";
import { GET_ROLES } from "@/store/team/team.module";
import AppAutoComplete from "@/view/components/Common/AppSearch.vue";
import veeValidate from "@/mixins/veeValidate";
import InviteMembersLoader from "@/view/components/Common/loaders/InviteMembers.vue";

export default {
  props: {
    isMember: {
      type: Boolean,
      default: false,
    },
    iGetStarted: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    hideOverlay: Boolean,
    displayText: String,
  },
  mixins: [veeValidate],
  data() {
    return {
      // these show for the tooltips
      show2: null,
      show3: null,
      search: null,
      menu: false,
      inviteMessage: null,
      selectedEmails: null,
      notify: false,
      selectedApps: {},
      selectedRoles: null,
      showTour: false,
      loading: false,
      mainRole: null,
      // two drawer, byEmail, byURL
      activeDrawer: null,
      inviteDrawer: false,
      URL: null,
      showCopyLink: false,
      inviteURL: "",
      copyAlert: null,
    };
  },
  watch: {
    inviteDrawer(val) {
      this.selectedEmails = null;
      this.inviteURL = null;
    },
    copyAlert() {
      this.notifyUserMessage({ message: "Invite URL copied successfully" });
    },
  },
  components: {
    Roles,
    AppAutoComplete,
    InviteMembersLoader,
  },
  computed: {
    ...mapState({
      info: (state) => get(state, "manageTeam.info", ""),
      appsDropdown: (state) => get(state, "apps.appsDropdown", []),
      errMsg: (state) => get(state, "invite.inviteErrMsg", ""),
      succMsg: (state) => get(state, "invite.inviteSuccMsg", ""),
      roles: (state) => get(state, "team.roles", ""),
      inviteLoader: (state) => get(state, "invite.inviteLoader", ""),
    }),
    ...mapGetters({
      currentUser: "currentUser",
      emailList: "getEmailList",
      getTotalMembersCount: "getTotalMembersCount",
      getTeamPlanLimits: "getTeamPlanLimits",
      urlList: "getUrlList",
      getAppInfo: "getAppInfo",
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
    // this is for disbling the invite button if ther is no email
    ifallDone() {
      // let done = false;
      // if (this.activeDrawer == "byEmail") {
      //   this.selectedEmails ? (done = true) : (done = false);
      // } else {
      //   done = true;
      // }
      // return done;
      return true;
    },
  },
  created() {
    this.inviteUrl = null;
  },
  methods: {
    stopTour() {
      let inviteBtn = document.getElementById("inviteBtn");
      inviteBtn.click();
      this.openURLDrawer();
      this.showTour = true;
    },
    setUserInInviteProcces(v) {
      this.$store.commit("whenUserInInviteProcess", v);
    },
    myCustomNextStepCallback(step) {},
    refreshUrl() {
      this.$store.commit("setInviteURL", null);
      this.inviteDrawer = false;
      this.$emit("success");
    },
    async loadData() {
      if (isEmpty(this.roles)) {
        if (this.loading) return;
        this.loading = true;
        await this.$store.dispatch(GET_ROLES).catch((err) => {
          this.notifyErrorMessage({ message: err.message });
        });
        this.loading = false;
      }
      // when you open the drawer, you need to empty the fields
      this.selectedEmails = {};
      this.selectedApps = {};
      if (this.$route.params.app_id) {
        this.selectedApps = [{ name: "", id: this.$route.params.app_id }];
      }
      this.inviteMessage = "";
    },
    // we have one drawer for each of byEmail & byURL
    openEmailDrawer() {
      this.inviteDrawer = true;
      this.loadData();
      this.activeDrawer = "byEmail";
    },
    openURLDrawer() {
      this.inviteDrawer = true;
      this.loadData();
      this.activeDrawer = "byURL";
    },
    ...mapMutations({
      appendTeamInvites: "appendTeamInvites",
    }),

    // this getAll method call when submit
    getAll() {
      // if (this.activeDrawer == "byEmail" && !this.selectedEmails.length) {
      //   this.notifyErrorMessage({ message: "Please enter email to invite." });
      //   return;
      // }
      // get all the data
      let data = {};
      // why trycatch block? because .map((app) => app.id) give us an error if no apps
      try {
        data = {
          emails: this.selectedEmails,
          apps:
            this.selectedApps && this.selectedApps.length
              ? this.selectedApps.map((app) => app.id)
              : [],
          message: this.inviteMessage,
          role_id: this.mainRole,
          notify: this.notify,
          permissions: this.selectedRoles,
        };
      } catch (error) {
        data = {
          emails: this.selectedEmails,
          apps: "",
          notify: this.notify,
          message: this.inviteMessage,
          role_id: this.mainRole,
          permissions: this.selectedRoles,
        };
      }

      // if there are no emails delete the propertie email from the object, cuz we don't need to send email=""
      isEmpty(data.emails) ? delete data["emails"] : "";

      // if no apps delete it
      isEmpty(data.apps) ? delete data["apps"] : "";

      // msg as well
      isEmpty(data.message) ? delete data["message"] : "";

      // roles as well
      isEmpty(data.role_id) ? delete data["role_id"] : "";

      // if no permissions set the selected roles to the permissions
      isEmpty(data.permissions) ? (data.permissions = this.selectedRoles) : "";
      let dataToAppend = {};
      this.$store
        .dispatch(CREAT_INVITE, JSON.stringify(data))
        .then((response) => {
          // this.activeDrawer = false;
          dataToAppend = {
            id: response.id,
            invited_by: { name: this.currentUser?.name },
            apps:
              this.selectedApps && this.selectedApps.length
                ? [...this.selectedApps]
                : "",
            email:
              data.emails && data.emails.length ? data.emails.join(",") : null,
            permissions: { ...data.permissions },
            role: {
              id: data.role_id,
              name: this.roles.find((el) => el.id === data.role_id)?.name,
            },
            create: true,
            status: 1,
            append:
              this.isMember && !this.emailList && !this.urlList ? false : true,
            created_at: currentTimestamp(),
          };
          if (data.emails && data.emails.length) {
            data.emails.forEach((email) => {
              dataToAppend = {
                id: response.id,
                invited_by: { name: this.currentUser?.name },
                apps:
                  this.selectedApps && this.selectedApps.length
                    ? [...this.selectedApps]
                    : "",
                email: email,
                status: 1,
                permissions: { ...data.permissions },
                role: {
                  id: data.role_id,
                  name: this.roles.find((el) => el.id === data.role_id)?.name,
                },
                create: true,
                append:
                  this.isMember && !this.emailList && !this.urlList
                    ? false
                    : true,
                created_at: currentTimestamp(),
              };
              this.appendTeamInvites(dataToAppend);
            });

            // solving scroll issue that <SidePanel /> causing!
            document.documentElement.classList.remove("overflow-y-hidden");
          } else {
            this.appendTeamInvites(dataToAppend);
            if (this.isMember) {
              this.inviteDrawer = false;

              // solving scroll issue that <SidePanel /> causing!
              document.documentElement.classList.remove("overflow-y-hidden");
            }
          }
          this.$emit("success");
          this.notifyUserMessage({
            message: response.message || "Invited successfully",
          });
          this.inviteURL = `${window?.location?.origin}/i/${response.id}`;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message || "Something went wrong");
        });

      // this.activeDrawer === "byURL" ? "" : (this.inviteDrawer = false);
      // this.drawer = false
    },

    // get emails data from the autocomplete component
    setEmails(emails) {
      this.selectedEmails = [];
      this.selectedEmails = [...new Set(emails)];
    },

    // set the apps
    setApps(apps) {
      this.selectedApps = apps;
    },

    // set the role name
    saveTheRole(v) {
      this.mainRole = v;
    },

    // set the selected roles
    saveRoles(v) {
      this.selectedRoles = v;
    },

    // this is for the tabs => [1]-Create Team  [2]-Plan  [3]-Members
    nextButtonStep1() {
      this.step = 2;
    },

    // as well
    nextButtonStep2() {
      this.step = 3;
    },

    // open side bar
    openInviteDrawer() {
      this.$store.commit("setInviteDrawer2", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.left-tour-step .tourend-tooltip {
  transform: translateX(
    -100%
  ); /* Move the content to the left of the target element */
  top: 50%; /* Adjust the vertical position as needed */
  left: 0; /* Align the left side of the content with the target element */
  transform-origin: right center; /* Set the transform origin to the right */
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.9);
}
</style>
