var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "titleFont"
  }, [_vm._v(" Screenshots "), _c('VueTooltip', {
    attrs: {
      "icon-color": "grey",
      "color": "primary",
      "text-class": "white--text",
      "v-html": 'App screenshots will be displayed in the public install page. Check share page for more info. <br /><br />The actual image size can be found in the install page. We are going to enhance the screenshots, we welcome any feedfback!'
    }
  })], 1), _c('div', {
    staticClass: "smallFont mb-6"
  }, [_vm._v(" Images will be resized to 250x500 pixels from the center ")]), _c('div', {
    staticClass: "mb-6"
  }, [!_vm.getAppScreenShotList.length ? _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "loading": _vm.loading,
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.openUploadModal
    }
  }, [_c('v-icon', [_vm._v("mdi-image")]), _vm._v(" Upload screenshot ")], 1) : _vm._e()], 1)]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "auto",
      "md": "6"
    }
  }, [_vm.getAppScreenShotList.length ? _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "loading": _vm.loading,
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.openUploadModal
    }
  }, [_c('v-icon', [_vm._v("mdi-image")]), _vm._v(" Upload screenshot ")], 1) : _vm._e()], 1), _vm.getAppScreenShotList.length ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "screenShotContainer",
    attrs: {
      "id": "screenShotBox"
    }
  }, _vm._l(_vm.getAppScreenShotList, function (item, index) {
    return _c('div', {
      key: index,
      staticStyle: {
        "display": "flex"
      }
    }, [_c('v-hover', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var hover = _ref.hover;
          return [_c('v-sheet', {
            staticClass: "imageBorder mr-2"
          }, [_c('v-img', {
            staticStyle: {
              "border-radius": "20px"
            },
            attrs: {
              "src": item.url,
              "width": "250",
              "cover": "",
              "height": "500"
            }
          }, [hover ? _c('v-icon', {
            staticClass: "float-right ma-1",
            attrs: {
              "color": "danger"
            },
            domProps: {
              "textContent": _vm._s('mdi-close')
            },
            on: {
              "click": function click($event) {
                return _vm.removeImage(item, index);
              }
            }
          }) : _c('v-chip', {
            staticClass: "float-right ma-1",
            attrs: {
              "color": "primary",
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(index + 1) + " ")])], 1)], 1)];
        }
      }], null, true)
    })], 1);
  }), 0)])], 1)], 1) : _vm._e()], 1)], 1), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.openRemoveImageModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', {
          staticClass: "font-size-h3 bold-text",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Delete screenshot ")]), _c('v-divider'), _c('v-card-text', {
          staticClass: "bodyFont message my-4",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Are you sure you want to delete this image? ")]), _c('v-divider'), _c('v-card-actions', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', [_vm._v("No undo for this action")]), _c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "text": "",
            "color": "primary",
            "disabled": _vm.deleteLoading
          },
          on: {
            "click": function click($event) {
              _vm.openRemoveImageModal = false;
            }
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "depressed": "",
            "color": "primary",
            "outlined": _vm.$vuetify.theme.dark,
            "loading": _vm.deleteLoading
          },
          on: {
            "click": _vm.deleteImage
          }
        }, [_vm._v(" Yes ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openRemoveImageModal,
      callback: function callback($$v) {
        _vm.openRemoveImageModal = $$v;
      },
      expression: "openRemoveImageModal"
    }
  }), _c('Modal', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.mdAndUp ? '650' : ''
    },
    on: {
      "close": function close($event) {
        _vm.appImageUpload = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.appImageUpload ? _c('image-upload', {
          attrs: {
            "title": "Upload app screenshot",
            "hide-overlay": "",
            "view-port": {
              width: 250,
              height: 500
            }
          },
          on: {
            "close": function close($event) {
              _vm.appImageUpload = false;
            },
            "cropped-image": _vm.onFileSelected
          },
          model: {
            value: _vm.appImage,
            callback: function callback($$v) {
              _vm.appImage = $$v;
            },
            expression: "appImage"
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.appImageUpload,
      callback: function callback($$v) {
        _vm.appImageUpload = $$v;
      },
      expression: "appImageUpload"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }