<template>
  <v-card>
    <v-card-title>
      <div>Update Auto Archive settings for {{ settingName }}</div>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('close')" icon>
        <v-icon v-text="'mdi-close'"></v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col v-if="settingName === 'team'" cols="12">
          <v-alert outlined color="info">
            This setting will be applied to all apps in your team for new
            release
          </v-alert>
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12">
              <v-checkbox
                label="Never"
                :value="0"
                hide-details
                :disabled="!getTeamPlanUsage.auto_archive_release"
                v-model="noValue"
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-row align="center">
                <v-col>
                  <v-text-field
                    :value="selectedDay"
                    hide-details
                    :disabled="
                      noValue || !getTeamPlanUsage.auto_archive_release
                    "
                    filled
                    v-model="selectedDay"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    hide-details
                    :disabled="
                      noValue || !getTeamPlanUsage.auto_archive_release
                    "
                    filled
                    v-model="selectedTime"
                    :items="['Days', 'Months']"
                  ></v-select>
                </v-col>
                <v-col
                  v-if="!getTeamPlanUsage.auto_archive_release"
                  cols="auto"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-btn icon @click="$emit('start-trail')">
                          <v-icon
                            class="cursor-pointer"
                            color="primary"
                            v-text="'mdi-lock-outline'"
                          >
                          </v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span
                      >Auto archive settings is available for Starter & Pro team
                      plans</span
                    >
                  </v-tooltip>
                </v-col>
                <v-col class="py-0" cols="12">
                  Note: Maximum 90 days or 3 months
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="!noValue && selectedDay != 0" cols="12">
          <div class="subListFont">
            <v-alert
              class="mt-3"
              border="left"
              color="info"
              icon="info"
              outlined
            >
              By archiving releases, we will be deleting the (.aab / .apk /
              .ipa)
              <br /><br />

              Those releases will not be visible or available for installation
              in our app and public install links.<br /><br />

              Keeping all the data attached to these releases such as notes,
              installs, feedback,... for your future reference.
            </v-alert>
            <v-alert
              class="mt-3"
              border="left"
              color="success"
              icon="info"
              outlined
            >
              Archived releases will not be counted towards your team's storage
              quota 👍
            </v-alert>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="text-transform-none"
        color="primary"
        :loading="busy"
        :disabled="!getTeamPlanUsage.auto_archive_release"
        :outlined="$vuetify.theme.dark"
        @click="confirmUpdate"
      >
        Update
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import {
  ARCHIVE_RELEASE_SCHEDULE,
  ARCHIVE_TEAM_RELEASE_SCHEDULE,
} from "@/store/releases/releases.module";
export default {
  props: {
    settingName: {
      type: String,
      default: "app",
    },
  },
  data() {
    return {
      selectedDay: 0,
      selectedTime: "Days",
      showAutoArchiveLearnMore: false,
      noValue: false,
      busy: false,
    };
  },
  computed: {
    ...mapGetters({
      getTeamPlanUsage: "getTeamPlanUsage",
      appInfo: "getAppInfo",
    }),
  },
  watch: {
    appInfo() {
      this.populateDefaultValue();
    },
    selectedDay(val) {
      if (val < 0) {
        this.selectedDay = 0;
      }
      if (this.selectedTime === "Months" && val > 3) {
        this.selectedDay = 3;
      }
      if (val > 90 && this.selectedTime === "Days") {
        this.selectedDay = 90;
      }
      if (val) {
        this.noValue = false;
      }
    },
    selectedTime(val) {
      if (this.selectedDay > 3 && val === "Months") {
        this.selectedDay = 3;
      }
      if (this.selectedDay > 90 && val === "Days") {
        this.selectedDay = 90;
      }
    },
  },
  mounted() {
    if (this.settingName === "team") {
      this.populateTeamData();
    } else {
      this.populateDefaultValue();
    }
  },
  methods: {
    ...mapMutations({
      updateAppAutoArchiveSettings: "updateAppAutoArchiveSettings",
      updateTeamAutoArchiveSettings: "updateTeamAutoArchiveSettings",
    }),
    populateDefaultValue() {
      this.selectedDay = this.appInfo?.settings?.auto_archive_release
        ? this.appInfo?.settings?.auto_archive_release / 86400
        : 0;
      if (this.selectedDay > 30 && this.selectedDay % 30 === 0) {
        this.selectedDay = this.selectedDay / 30;
        this.selectedTime = "Months";
      } else {
        this.selectedTime = "Days";
      }
      this.$nextTick(() => {
        if (this.selectedDay === 0) {
          this.noValue = true;
        }
      });
    },
    confirmUpdate() {
      if (this.settingName === "team") {
        this.UpdateTeamAutoArchive();
      } else {
        this.updateReleaseSchedule();
      }
    },
    populateTeamData() {
      this.selectedDay = this.getTeamPlanUsage?.release_expire
        ? this.getTeamPlanUsage?.release_expire / 86400
        : 0;
      if (this.selectedDay > 30 && this.selectedDay % 30 === 0) {
        this.selectedDay = this.selectedDay / 30;
        this.selectedTime = "Months";
      } else {
        this.selectedTime = "Days";
      }
      this.$nextTick(() => {
        if (this.selectedDay === 0) {
          this.noValue = true;
        }
      });
    },
    updateReleaseSchedule() {
      if (this.busy) return;
      this.busy = true;
      let value = this.noValue ? 0 : this.selectedDay;
      if (this.selectedTime === "Months") {
        value = this.selectedDay * 30;
      }
      const payload = {
        id: this.$route.params.app_id,
        action: "add_scheduled_archive",
        method: "time",
        value: parseInt(value),
        ids: [],
      };
      this.$store
        .dispatch(ARCHIVE_RELEASE_SCHEDULE, payload)
        .then((response) => {
          let value = this.noValue ? 0 : this.selectedDay;
          if (value !== 0 && this.selectedTime === "Months") {
            value = this.selectedDay * 30 * 86400;
          } else if (value !== 0 && this.selectedTime === "Days") {
            value = this.selectedDay * 86400;
          }
          this.updateAppAutoArchiveSettings(value);
          this.$nextTick(() => {
            this.calculateInitialValue(value);
          });
          this.$emit("close");
          this.busy = false;
          this.notifyUserMessage({ message: response.message });
        })
        .catch((err) => {
          if (err.status === 402) {
            this.$emit("open-subscription");
          } else {
            this.notifyErrorMessage(err.message);
          }
          this.busy = false;
        });
    },
    UpdateTeamAutoArchive() {
      if (this.busy) return;
      this.busy = true;
      let value = this.noValue ? 0 : this.selectedDay;
      if (this.selectedTime === "Months") {
        value = this.selectedDay * 30;
      }
      const dataToPost = {
        value,
      };
      this.$store
        .dispatch(ARCHIVE_TEAM_RELEASE_SCHEDULE, dataToPost)
        .then((response) => {
          let value = this.noValue ? 0 : this.selectedDay;
          if (value !== 0 && this.selectedTime === "Months") {
            value = this.selectedDay * 30 * 86400;
          } else if (value !== 0 && this.selectedTime === "Days") {
            value = this.selectedDay * 86400;
          }
          this.notifyUserMessage({ message: response.message });
          this.updateTeamAutoArchiveSettings(value);
          this.$emit("close");
          this.busy = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
        });
    },
    calculateInitialValue(value) {
      this.selectedDay = value ? value / 86400 : 0;
      if (this.selectedDay > 30 && this.selectedDay % 30 === 0) {
        this.selectedDay = this.selectedDay / 30;
        this.selectedTime = "Months";
      } else {
        this.selectedTime = "Days";
      }
      if (this.selectedDay === 0) {
        this.noValue = true;
      }
    },
  },
};
</script>
<style></style>
