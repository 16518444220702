<template>
  <v-row>
    <v-col cols="12">
      <div
        id="successModalText"
        class="font-size-h3 bold-text text-center mt-4"
      >
        All done 🎉
      </div>
    </v-col>
    <v-col class="text-center" cols="12">
      <qrcode :value="getShareLink" :options="{ width: 200 }" />
    </v-col>
    <v-col class="bodyFont py-0 text-center" cols="12">
      Share this URL with everyone!
    </v-col>
    <v-col class="text-center" cols="12">
      <div class="mt-4 text-center">
        <v-chip class="radius-10">
          <MaxText id="copyShareLinkChip" :text="getShareLink" :max="34" />
        </v-chip>
        <v-icon
          v-if="!copied"
          class="ml-2"
          @click="copy(getShareLink)"
          :small="copied"
          :color="copied ? 'success' : ''"
          v-text="copied ? 'mdi-check' : 'mdi-content-copy'"
        />
        <span v-if="copied" class="success--text ml-2">
          <v-icon color="success" small v-text="'mdi-check'"></v-icon>
          {{ copied ? "Copied" : "" }}
        </span>
      </div>
      <div
        @click="$router.push({ name: 'app-settings-share' })"
        class="cursor-pointer mt-4 primary--text"
        v-if="fromReleasePage"
      >
        View all links
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    shareId: {
      type: String,
      default: "",
    },
    fromReleasePage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      copied: false,
    };
  },
  computed: {
    ...mapGetters({
      appInfo: "getAppInfo",
    }),
    getShareLink() {
      return `${location.origin}/apps/install/${this.shareId}`;
      // if (process.env.VUE_APP_ENV !== "production") {
      // } else {
      // return `https://testapp.io/apps/install/${this.shareId}`;
      // }
    },
  },
  methods: {
    async copy(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 3000);
        // this.notifyUserMessage({
        //   message: "Copied successfully",
        //   timeout: true,
        // });
      } catch (error) {
        this.notifyErrorMessage({
          message: "An error occurred while copying",
          timeout: true,
        });
      }
    },
  },
};
</script>
