var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-5 mb-5"
  }, [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(5, function (i) {
    return _c('v-col', {
      key: i,
      staticClass: "px-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-skeleton-loader', {
      staticClass: "px-0",
      attrs: {
        "type": "list-item-avatar-three-line"
      }
    })], 1);
  }), 1), _c('v-overlay', {
    attrs: {
      "z-index": "1",
      "light": "",
      "absolute": "",
      "value": true
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "mb-3",
    attrs: {
      "size": "40"
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-lock')
    }
  })], 1), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": function click($event) {
        _vm.openSubscriptionModal = true;
      }
    }
  }, [_vm._v(" Upgrade to unlock all records ")])], 1)]), _c('Modal', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 1100 : '',
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.openSubscriptionModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('plan-pricing', {
          attrs: {
            "from-modal": "",
            "from-overlay": "",
            "upgrade-plan-text": _vm.message
          },
          on: {
            "success": function success($event) {
              _vm.$emit('close'), _vm.openSubscriptionModal = false;
            },
            "close": function close($event) {
              _vm.openSubscriptionModal = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openSubscriptionModal,
      callback: function callback($$v) {
        _vm.openSubscriptionModal = $$v;
      },
      expression: "openSubscriptionModal"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }