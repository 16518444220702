<template>
  <v-card>
    <v-card-title>
      <div cols="12" class="mt-4 bodyFont bold-text">
        Change Page Status
      </div>
      <v-spacer/>
      <v-btn @click="$emit('close')" icon>
        <v-icon v-text="'close'"></v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-radio-group
        v-model="formValues.show_as"
        class="mt-3"
      >
      <template v-for="(item) in previewItems">
        <v-row :key="item.value" align="center">
          <v-col class="pr-0" cols="1">
            <v-radio :value="item.value">
            </v-radio>
          </v-col>
          <v-col class="pl-0" cols="11">
            <b>{{ item.title }}:</b> <span>{{ item.description}}</span>
          </v-col>
          <v-col class="px-4" v-if="[4].includes(formValues.show_as) && item.value === 4" cols="12">
            <v-text-field v-model="formValues.show_as_message" class="mt-n4"
                          v-validate="'required|min:3|max:70'" v-bind="veeValidate('Message', '')" filled label="Custom Message"
                          hint="Optional - By default, we display a message but you can choose a custom one to be displayed at top of the page for the external testers."
                          counter="70" persistent-hint></v-text-field>
          </v-col>
        </v-row>
        <v-row :key="item.value">
          <v-col class="py-0" cols="12">
            <v-divider/>
          </v-col>
        </v-row>
      </template>
      </v-radio-group>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn @click="submitDetail" class="text-transform-none" color="primary" :outlined="$vuetify.theme.dark" :loading="busy" depressed>
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { UPDATE_SHARE_LINK_STATUS } from "@/store/releases/releases.module";
import veeValidate from "@/mixins/veeValidate";
import { mapMutations } from "vuex";
export default {
    mixins: [veeValidate],
    props: {
        selectedDetail: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            formValues: {
                show_as: "",
                show_as_message: "",
                enabled: false,
            },
            openDisablePopup: false,
            busy: false,
            previewItems: [
                { title: "Enabled", description: "This link will be active accessible for testers.", value: 1 },
                { title: "Disabled", description: "The link will be disabled and not accessible for testers.", value: 3 },
                { title: "Custom Message",
                  description: "You can add a custom message to display on the app page without the ability to download app.",
                  value: 4 },
            ],
        };
    },
    watch: {
        "formValues.show_as"(val) {
            if ([1, 2].includes(val)) {
                this.formValues.show_as_message = "";
            }
        },
        selectedDetail(val) {
            this.formValues.show_as = val.status;
            this.formValues.show_as_message = val.settings.custom_message;
        },
    },
    mounted() {
        this.formValues.show_as = this.selectedDetail.status;
        this.formValues.show_as_message = this.selectedDetail.settings.custom_message;
    },
    methods: {
        ...mapMutations({
            updateShareListStatus: "updateShareListStatus",
        }),
        submitDetail() {
            if (this.busy) return;
            this.busy = true;
            const dataToPost = {
                app_id: this.$route.params.app_id,
                share_id: this.selectedDetail.id,
                status: this.formValues.show_as,
                message: this.formValues.show_as_message,
            };
            this.$store
                .dispatch(UPDATE_SHARE_LINK_STATUS, dataToPost)
                .then((response) => {
                    this.busy = false;
                    this.$emit("close");
                    this.updateShareListStatus({
                        id: dataToPost.share_id,
                        message: dataToPost.message,
                        status: dataToPost.status,
                    });
                    this.notifyUserMessage({ message: response.message });
                })
                .catch((err) => {
                    this.busy = false;
                    if (err.status == 402) {
                        this.$emit("showUpgradeModal");
                    } else {
                        this.notifyErrorMessage(err.message);
                    }
                });
        },
    },
};
</script>
