<template>
  <div>
    <v-row>
      <v-col v-if="loading" cols="12">
        <v-skeleton-loader
          class="transparent"
          type="table-thead, table-tbody"
        />
      </v-col>
      <v-col v-else cols="12">
        <DatatableWrapper
          v-if="canManageApp"
          :loading="installLoading"
          :pagination="pagination"
        >
          <v-data-table
            :headers="headers"
            :items="installData"
            :loading="loading"
            @click:row="
              $router.push({
                name: 'release-install',
                params: {
                  app_id: $route.params.app_id,
                  release_id: $event?.release?.id,
                },
              })
            "
            hide-default-footer
            mobile-breakpoint="0"
          >
            <template v-if="showUpgradeOverlay" slot="item">
              <tr>
                <td :colspan="headers.length">
                  <upgrade-table-overlay
                    :total-length="headers.length"
                    :message="errorMessage"
                  ></upgrade-table-overlay>
                </td>
              </tr>
            </template>
            <template v-if="!showUpgradeOverlay" #item.user="{ item }">
              <div v-if="item?.user?.name">
                <!-- <ProfileImage
                  size="40"
                  :image="get(item, 'member.image', '')"
                  :alt="get(item, 'member.name', '')"
                /> -->
                <user-avatar :user-detail="item.user"></user-avatar>
              </div>
              <div v-else>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <!-- <v-avatar v-on="on" size="40">
                      <div>?</div>
                    </v-avatar> -->
                    <span v-on="on">
                      <i> Anonymous </i>
                    </span>
                  </template>
                  <span>
                    <div class="mb-2">
                      Anonymous users can be possible of the following
                      scenarios:
                    </div>
                    <ul>
                      <li>Public app release download</li>
                      <li>Direct release file download</li>
                    </ul>
                  </span>
                </v-tooltip>
              </div>
            </template>
            <template v-if="!showUpgradeOverlay" #item.device="{ item }">
              <user-agent-parser
                v-if="!showUpgradeOverlay"
                :full="true"
                :default-device="item.device"
              ></user-agent-parser>
            </template>
            <template v-if="!showUpgradeOverlay" #item.country="{ item }">
              <country-city
                v-if="!showUpgradeOverlay"
                :detail="item.location"
              ></country-city>
            </template>
            <template v-if="!showUpgradeOverlay" #item.release="{ item }">
              <div v-if="item.release">
                <release-chip :release="item.release"></release-chip>
              </div>
              <!-- No release selected -->
              <div v-else-if="!showUpgradeOverlay">-</div>
            </template>
            <template v-if="!showUpgradeOverlay" #item.date="{ item }">
              <vue-hoverable-date
                v-if="item.installed_at"
                :date="item.installed_at"
              ></vue-hoverable-date>
            </template>
            <template slot="no-data">
              <no-data
                image="install_empty_state"
                :first-text="
                  showUpgradeOverlay
                    ? 'Feature not available'
                    : 'No installs or downloads logged for this release'
                "
                :second-text="
                  showUpgradeOverlay
                    ? errorMessage
                    : 'Install logs can be useful to trace the devices that installed your release for debugging purposes'
                "
              ></no-data>
              <v-btn
                v-if="showUpgradeOverlay"
                color="primary"
                class="text-transform-none mb-10"
                :outlined="$vuetify.theme.dark"
                @click.stop="showStartTrialModal = true"
              >
                Upgrade Plan
              </v-btn>
            </template>
            <template #footer.page-text>
              <div>
                <v-btn
                  v-if="!showUpgradeOverlay"
                  class="text-transform-none"
                  @click="getReleaseInstallList()"
                  color="darkGrey"
                  text
                >
                  <v-icon
                    :color="$vuetify.theme.dark ? '' : 'grey'"
                    v-text="'mdi-reload'"
                    left
                  >
                  </v-icon>
                  Reload
                </v-btn>
                Total: <b>{{ numberWithCommas(totalCount) }}</b>
              </div>
            </template>
          </v-data-table>
        </DatatableWrapper>
        <template v-else>
          <no-data
            image="install_empty_state"
            :first-text="'You don\'t have the permission to view or manage release installs'"
          ></no-data>
        </template>
      </v-col>
    </v-row>
    <v-card outlined>
      <Modal
        :width="$vuetify.breakpoint.mdAndUp ? 1100 : ''"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        @close="openSubscriptionModal = false"
        v-model="openSubscriptionModal"
      >
        <template #message>
          <plan-pricing
            from-modal
            from-overlay
            :upgrade-plan-text="message"
            @success="$emit('close'), (openSubscriptionModal = false)"
            @close="openSubscriptionModal = false"
          ></plan-pricing>
        </template>
      </Modal>
      <Modal
        v-model="showStartTrialModal"
        @close="showStartTrialModal = false"
        no-py
        :fullscreen="$vuetify.breakpoint.mdAndDown"
        :width="$vuetify.breakpoint.mdAndUp ? 880 : ''"
      >
        <template #message>
          <start-trial-modal
            v-if="showStartTrialModal"
            trigger-from="exportInstalls"
            @close="showStartTrialModal = false"
            @open-subscribe="openSubscriptionModal = true"
          ></start-trial-modal>
        </template>
      </Modal>
    </v-card>
  </div>
</template>

<script>
import {
  getInstallTime,
  get,
  isEmpty,
  formatBytes,
  numberWithCommas,
} from "@/core/services/helper.service.js";
import {
  GET_APP_INSTALL_LIST,
  GET_RELEASE_INSTALL_LIST,
} from "@/store/releases/createRelease.module.js";
import { mapGetters, mapMutations } from "vuex";
import { GENERATE_APP_EXPORT } from "@/store/apps/app.module";
import UserAgentParser from "@/view/components/Common/UserAgentParser";
import CountryCity from "@/view/components/Common/CountryCity";
import UpgradeTableOverlay from "@/view/components/Common/UpgradeTableOverlay";
import PlanPricing from "@/view/components/Team/PlanPricing";
import StartTrialModal from "@/view/components/Common/StartTrialModal";
import DatatableWrapper from "@/view/components/App/DatatableWrapper";
import UserAvatar from "@/view/components/Common/UserAvatar";

export default {
  name: "install-list",
  props: {
    fromReleasePage: {
      type: Boolean,
      default: false,
    },
    releaseId: {
      type: String,
      default: "",
    },
    fromLatest: {
      type: Boolean,
      default: false,
    },
    platform: {
      type: String,
      default: "",
    },
    app_id: {
      type: String,
      default: "",
    },
    shareId: {
      type: String,
      default: "",
    },
    release: {
      type: [Object, Boolean],
      default() {
        return {};
      },
    },
    app: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    DatatableWrapper,
    StartTrialModal,
    PlanPricing,
    UserAvatar,
    UpgradeTableOverlay,
    CountryCity,
    UserAgentParser,
  },
  data() {
    return {
      get,
      isEmpty,
      formatBytes,
      releaseInfo: null,
      numberWithCommas,
      showStartTrialModal: false,
      getInstallTime,
      openSubscriptionModal: false,
      message: null,
      member: "",
      page: 1,
      installLoading: false,
      installData: [],
      totalInstallCount: 0,
      pagination: {
        page: this.toNumber(this.$route.query.page),
        itemsPerPage: 10,
        totalItems: 0,
      },
      showUpgradeOverlay: false,
      releaseInstallList: [],
      totalReleaseInstallList: [],
      errorMessage: "",
      readMore: true,
      devices: [],
      headers: [
        { text: "User", width: "200", value: "user", sortable: false },
        { text: "Device", width: "250", value: "device", sortable: false },
        { text: "Location", width: "100", value: "country", sortable: false },
        { text: "Release", width: "100", value: "release", sortable: false },
        { text: "Date", width: "150", value: "date", sortable: false },
      ],
      search: "",
      loading: false,
      totalMembers: 0,
      totalCount: 0,
      busy: false,
      showDeviceInfo: false,
      memberList: [],
    };
  },
  RELEASE_STATUS: {
    0: { title: "Deleted", color: "error" },
    1: { title: "Active", color: "green" },
    2: { title: "Failed", color: "red" },
    3: { title: "Archived", color: "grey" },
    4: { title: "Uploading", color: "orange" },
    5: { title: "Processing", color: "primary" },
  },
  watch: {
    async "pagination.page"(newPage) {
      await this.$router.push({
        query: {
          ...this.$route.query,
          page: newPage,
        },
      });

      this.pagination.page = newPage;
      this.getAllInstalls({ page: newPage, last_id: this.lastId });
    },
    totalInstallCount(value) {
      this.pagination.totalItems = value;
    },
  },
  created() {
    if (this.fromReleasePage) {
      this.headers = this.headers.filter((header) => header.text !== "Release");
    }
    if (this.canManageApp && !this.getInfoErrorMessage) {
      this.getAllInstalls();
    }
  },
  computed: {
    ...mapGetters({
      getAndroidLatestInstallData: "getAndroidLatestInstallData",
      getIosLatestInstallData: "getIosLatestInstallData",
      appDetail: "getAppInfo",
      getTeamPlanUsage: "getTeamPlanUsage",
      getInfoErrorMessage: "getInfoErrorMessage",
      getTeamPlanAnalytics: "getTeamPlanAnalytics",
    }),
    installLink() {
      return `${location.origin}/install/${this.$route.params.app_id}/${this.release.id}`;
    },
  },
  methods: {
    ...mapMutations({
      setAndroidLatestInstallData: "setAndroidLatestInstallData",
      setIosLatestInstallData: "setIosLatestInstallData",
    }),
    exportInstallList() {
      if (!this.getTeamPlanUsage.analytics.export_installs) {
        this.showStartTrialModal = true;
        return;
      }
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(GENERATE_APP_EXPORT, {
          app_id: this.$route.params.app_id,
          type: "installs",
        })
        .then((response) => {
          this.notifyUserMessage({ message: response.message });
          if (response.file) {
            window.open(response.file);
          }
          this.$emit("success");
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          if (err.status == 402) {
            this.message = err.message;
            this.openSubscriptionModal = true;
          } else {
            this.notifyErrorMessage(err.message);
          }
        });
    },
    getAllInstalls({ page = this.pagination.page, last_id = "" } = {}) {
      if (this.installLoading) return;
      this.installLoading = true;
      const params = {
        page,
        last_id,
        release_id: this.$route.params.release_id || this.releaseId || "",
        shareId: this.shareId || "",
      };
      this.$coreApi
        .get(
          `${this.apiRoutes.core.app.getInstallList(
            this.$route.params.app_id
          )}?${this.getQuery(params)}`
        )
        .then(({ data }) => {
          this.installData = data.data ?? [];
          this.totalInstallCount = data.meta.total ?? 0;
        })
        .catch((err) => {
          if (err?.status === 402) {
            this.showUpgradeOverlay = true;
            this.errorMessage = err?.message;
          } else {
            this.notifyErrorMessage(err?.message);
          }
        })
        .finally(() => {
          this.installLoading = false;
        });
    },
    getReleaseInstallList(lastInstalledAt) {
      this.errorMessage = null;
      if (!lastInstalledAt) {
        this.page = 1;
      }
      if (this.loading) return;
      this.loading = true;
      const endpoint = this.fromReleasePage
        ? GET_RELEASE_INSTALL_LIST
        : GET_APP_INSTALL_LIST;
      this.$store
        .dispatch(endpoint, {
          release_id: this.release.id,
          app_id: this.$route.params.app_id,
          lastInstalledAt: lastInstalledAt,
        })
        .then((response) => {
          this.loading = false;
          if (lastInstalledAt) {
            this.totalReleaseInstallList = [
              ...this.totalReleaseInstallList,
              ...response.data[0].data,
            ];
            this.releaseInstallList = response.data;
          } else {
            this.releaseInstallList = response.data;
            this.totalCount = response.meta.total;
            this.totalReleaseInstallList = response.data;
          }
          if (this.platform === "ios" && !this.getIosLatestInstallData) {
            this.setIosLatestInstallData(response.data);
          }
          if (
            this.platform === "android" &&
            !this.getAndroidLatestInstallData
          ) {
            this.setAndroidLatestInstallData(response.data.data);
          }
        })
        .catch((err) => {
          if (err.status === 402) {
            this.showUpgradeOverlay = true;
            this.errorMessage = err.message;
            this.releaseInstallList = [{}];
            this.totalCount = this.appDetail.stats.total_installs || 1;
          } else {
            this.notifyErrorMessage(err.message);
          }
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.empty_icon {
  width: 100px;
}
.member-search {
  border: 1px solid #b5b5c3;
  background-color: #fff !important;
}
.member-panel {
  padding: 10px;
  border: 1px solid rgba(57, 107, 219, 0.2);
  border-radius: 10px;
  margin-bottom: 10px;
  img {
    height: 40px;
    width: 40px;
  }
}
</style>
