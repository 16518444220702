var render = function render(){
  var _vm$releaseDetail, _vm$releaseDetail2, _vm$releaseDetail3, _vm$appInfo, _vm$releaseDetail4, _vm$appInfo2, _vm$appInfo2$latest_r, _vm$releaseDetail5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "mx-0 px-0"
  }, [_c('v-col', {
    staticClass: "mt-8",
    class: _vm.$vuetify.breakpoint.smAndDown ? 'px-0' : '',
    attrs: {
      "cols": "12"
    }
  }, [_c('v-stepper', {
    attrs: {
      "flat": "",
      "non-linear": ""
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-stepper-header', {
    attrs: {
      "flat": ""
    }
  }, [((_vm$releaseDetail = _vm.releaseDetail) === null || _vm$releaseDetail === void 0 ? void 0 : _vm$releaseDetail.platform) === 'android' || !_vm.releaseDetail ? _c('v-stepper-step', {
    attrs: {
      "complete": "",
      "step": 1,
      "complete-icon": "mdi-android",
      "edit-icon": "mdi-android"
    }
  }, [_vm._v(" Android ")]) : _vm._e(), _c('v-divider'), _c('v-stepper-step', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ((_vm$releaseDetail2 = _vm.releaseDetail) === null || _vm$releaseDetail2 === void 0 ? void 0 : _vm$releaseDetail2.platform) === 'ios' || !_vm.releaseDetail,
      expression: "releaseDetail?.platform === 'ios' || !releaseDetail"
    }],
    attrs: {
      "complete": "",
      "step": 2,
      "complete-icon": "mdi-apple",
      "edit-icon": "mdi-apple"
    }
  }, [_vm._v(" iOS ")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "complete": "",
      "step": 3,
      "complete-icon": "mdi-cogs",
      "edit-icon": "mdi-cogs"
    }
  }, [_vm._v(" Settings ")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "complete": "",
      "complete-icon": "mdi-eye",
      "step": 4
    }
  }, [_vm._v(" Review & Submit ")])], 1), _c('v-stepper-items', [_c('v-stepper-content', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ((_vm$releaseDetail3 = _vm.releaseDetail) === null || _vm$releaseDetail3 === void 0 ? void 0 : _vm$releaseDetail3.platform) === 'android' || !_vm.releaseDetail,
      expression: "releaseDetail?.platform === 'android' || !releaseDetail"
    }],
    staticClass: "pa-3",
    attrs: {
      "step": 1
    }
  }, [_c('v-row', {
    staticClass: "pa-4"
  }, [(_vm$appInfo = _vm.appInfo) !== null && _vm$appInfo !== void 0 && _vm$appInfo.latest_releases.android ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('div', [_vm._v("Select link type for Android")]), _c('v-radio-group', {
    attrs: {
      "disabled": !!_vm.releaseDetail,
      "hide-details": ""
    },
    model: {
      value: _vm.androidOption,
      callback: function callback($$v) {
        _vm.androidOption = $$v;
      },
      expression: "androidOption"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pr-0",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-radio', {
    attrs: {
      "value": "skip"
    }
  })], 1), _c('v-col', {
    staticClass: "pl-0",
    attrs: {
      "cols": "11"
    }
  }, [_c('b', [_vm._v("Skip:")]), _vm._v(" "), _c('span', [_vm._v("No android release available.")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pr-0",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-radio', {
    attrs: {
      "value": "latest"
    }
  })], 1), _c('v-col', {
    staticClass: "pl-0",
    attrs: {
      "cols": "11"
    }
  }, [_c('b', [_vm._v("Self-Update Link: ")]), _c('span', [_vm._v("Automatically updates with each new Android release. Testers receive the latest version. No manual link updates needed.")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pr-0",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-radio', {
    attrs: {
      "value": "specific_release"
    }
  })], 1), _c('v-col', {
    staticClass: "pl-0",
    attrs: {
      "cols": "11"
    }
  }, [_c('b', [_vm._v("Specific Release: ")]), _c('span', [_vm._v("Choose a specific release to be installed. Change versions anytime using the same link.")])])], 1)], 1)], 1) : [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('no-data', {
    attrs: {
      "first-text": "No active Android releases"
    }
  })], 1)], _vm.androidOption === 'specific_release' ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('release-auto-complete', {
    attrs: {
      "app_id": _vm.appId,
      "disable": !!_vm.releaseDetail,
      "default-release": _vm.selectedAndroid,
      "first-load": _vm.firstLoadAndroid,
      "platform": "android"
    },
    on: {
      "loaded": function loaded($event) {
        _vm.firstLoadAndroid = false;
      }
    },
    model: {
      value: _vm.selectedAndroid,
      callback: function callback($$v) {
        _vm.selectedAndroid = $$v;
      },
      expression: "selectedAndroid"
    }
  })], 1) : _vm._e(), _c('v-col', {
    class: _vm.$vuetify.breakpoint.smAndDown ? 'text-left' : 'text-right',
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "id": "proceedToIosBtn",
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "depressed": ""
    },
    on: {
      "click": _vm.proceedToIOS
    }
  }, [_vm._v("Next ")])], 1)], 2)], 1), _c('v-stepper-content', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ((_vm$releaseDetail4 = _vm.releaseDetail) === null || _vm$releaseDetail4 === void 0 ? void 0 : _vm$releaseDetail4.platform) === 'ios' || !_vm.releaseDetail,
      expression: "releaseDetail?.platform === 'ios' || !releaseDetail"
    }],
    class: _vm.$vuetify.breakpoint.smAndDown ? 'px-0' : '',
    attrs: {
      "step": 2
    }
  }, [_c('v-row', [(_vm$appInfo2 = _vm.appInfo) !== null && _vm$appInfo2 !== void 0 && (_vm$appInfo2$latest_r = _vm$appInfo2.latest_releases) !== null && _vm$appInfo2$latest_r !== void 0 && _vm$appInfo2$latest_r.ios ? [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "bodyFont text-left"
  }, [_vm._v("Select link type for iOS")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-radio-group', {
    staticClass: "mt-3",
    attrs: {
      "disabled": !!_vm.releaseDetail
    },
    model: {
      value: _vm.iosOption,
      callback: function callback($$v) {
        _vm.iosOption = $$v;
      },
      expression: "iosOption"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pr-0",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-radio', {
    attrs: {
      "value": "skip"
    }
  })], 1), _c('v-col', {
    staticClass: "pl-0",
    attrs: {
      "cols": "11"
    }
  }, [_c('b', [_vm._v("Skip:")]), _vm._v(" "), _c('span', [_vm._v("No iOS release available.")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pr-0 pb-0",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-radio', {
    attrs: {
      "value": "testflight"
    }
  })], 1), _c('v-col', {
    staticClass: "pl-0",
    attrs: {
      "cols": "11"
    }
  }, [_c('b', [_vm._v("Testflight: ")]), _c('span', [_vm._v("External testers without having to collect their UDIDs.")])]), _vm.iosOption === 'testflight' ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|url',
      expression: "'required|url'"
    }],
    staticClass: "py-0 my-0",
    attrs: {
      "dense": "",
      "type": "url",
      "hint": "Check developer.apple.com -> TestFlight -> External Testing",
      "outlined": "",
      "placeholder": "https://testflight.apple.com/join/pC3Sf5yp"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "text-transform-none mb-2",
          attrs: {
            "color": _vm.validated ? 'success' : 'primary',
            "disabled": _vm.errors.any(),
            "loading": _vm.busy,
            "outlined": _vm.$vuetify.theme.dark,
            "small": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.validateTestFlightLink.apply(null, arguments);
            }
          }
        }, [_vm.validated ? _c('v-icon', {
          attrs: {
            "left": "",
            "small": ""
          },
          domProps: {
            "textContent": _vm._s('mdi-check')
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(_vm.validated ? "Validated" : "Validate") + " ")], 1)];
      },
      proxy: true
    }], null, false, 923630367),
    model: {
      value: _vm.testflightLink,
      callback: function callback($$v) {
        _vm.testflightLink = $$v;
      },
      expression: "testflightLink"
    }
  }, 'v-text-field', _vm.veeValidate('testflight link', ''), false))], 1) : _vm._e()], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pr-0",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-radio', {
    attrs: {
      "value": "specific_release"
    }
  })], 1), _c('v-col', {
    staticClass: "pl-0",
    attrs: {
      "cols": "11"
    }
  }, [_c('b', [_vm._v("Specific Release (Enterprise Build):")]), _c('span', [_vm._v(" Choose a specific release to be installed that's built with Apple's Enterprise Certificate. Change versions anytime using the same link.")]), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.iosShareLearnMore = true;
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-help-circle-outline ")])], 1)], 1), _vm.iosOption === 'specific_release' ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('release-auto-complete', {
    staticClass: "mt-n4",
    attrs: {
      "app_id": _vm.appId,
      "disable": !!_vm.releaseDetail,
      "first-load": _vm.firstLoadIOS,
      "default-release": _vm.selectedIos,
      "destination": "share",
      "platform": "ios",
      "no-data-message": "No iOS releases with enterprise certificate"
    },
    on: {
      "loaded": function loaded($event) {
        _vm.firstLoadIOS = false;
      }
    },
    model: {
      value: _vm.selectedIos,
      callback: function callback($$v) {
        _vm.selectedIos = $$v;
      },
      expression: "selectedIos"
    }
  })], 1) : _vm._e()], 1)], 1)], 1)] : [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('no-data', {
    attrs: {
      "first-text": "No active iOS releases"
    }
  })], 1)], _c('v-col', {
    class: _vm.$vuetify.breakpoint.smAndDown ? 'text-left' : 'text-right',
    attrs: {
      "cols": "12"
    }
  }, [((_vm$releaseDetail5 = _vm.releaseDetail) === null || _vm$releaseDetail5 === void 0 ? void 0 : _vm$releaseDetail5.platform) !== 'android' ? _c('v-btn', {
    staticClass: "text-transform-none mr-2",
    attrs: {
      "color": "primary",
      "depressed": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.tab = 1;
      }
    }
  }, [_vm._v("Previous ")]) : _vm._e(), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "id": "proceedToSettingsBtn",
      "outlined": _vm.$vuetify.theme.dark,
      "loading": _vm.busy,
      "color": "primary",
      "depressed": ""
    },
    on: {
      "click": _vm.proceedToSettings
    }
  }, [_vm._v("Next ")])], 1)], 2)], 1), _c('v-stepper-content', {
    class: _vm.$vuetify.breakpoint.smAndDown ? 'px-0' : '',
    attrs: {
      "step": 3
    }
  }, [_c('v-row', {
    staticClass: "px-3"
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pr-0 pb-0",
    attrs: {
      "cols": "10"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.expiryDays,
      "dense": "",
      "filled": "",
      "hide-details": "",
      "item-text": "title",
      "item-value": "value"
    },
    model: {
      value: _vm.expire_date,
      callback: function callback($$v) {
        _vm.expire_date = $$v;
      },
      expression: "expire_date"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('vue-tooltip', {
    attrs: {
      "icon-color": "darkGrey",
      "text": "Automatically disable the link. You can change the option at any time before it expires but once it does, you cannot make active again"
    }
  })], 1), _vm.expire_date > 0 ? _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Notify by email when this link expires",
      "hide-details": ""
    },
    model: {
      value: _vm.formValues.settings.expire_notify,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues.settings, "expire_notify", $$v);
      },
      expression: "formValues.settings.expire_notify"
    }
  })], 1) : _vm._e()], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "bodyFont text-left"
  }, [_vm._v(" Install instructions "), _c('span', {
    staticClass: "primary--text text-right cursor-pointer caption",
    on: {
      "click": _vm.copyFromAppInstruction
    }
  }, [_vm._v(" Copy from global instructions ")]), _c('vue-tooltip', {
    attrs: {
      "icon-color": "darkGrey",
      "text": "Install instructions will be display in the public install page"
    }
  })], 1), _c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:600',
      expression: "'max:600'"
    }],
    attrs: {
      "counter": "600",
      "filled": "",
      "placeholder": "Any special instructions for the testers to start testing this app? Specific environment or a demo account?",
      "rows": "3"
    },
    model: {
      value: _vm.formValues.instructions,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "instructions", $$v);
      },
      expression: "formValues.instructions"
    }
  }, 'v-textarea', _vm.veeValidate('instructions', ''), false))], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "bodyFont text-left"
  }, [_vm._v(" Internal notes for team "), _c('vue-tooltip', {
    attrs: {
      "icon-color": "darkGrey",
      "text": "These notes are only visible to your team (owners & developers)"
    }
  })], 1), _c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:600',
      expression: "'max:600'"
    }],
    attrs: {
      "counter": "600",
      "filled": "",
      "placeholder": "Where do you want to share this link or with who? Only visible to your team (owners & developers)",
      "rows": "3"
    },
    model: {
      value: _vm.formValues.note,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "note", $$v);
      },
      expression: "formValues.note"
    }
  }, 'v-textarea', _vm.veeValidate('note', ''), false))], 1), _c('v-col', {
    staticClass: "pt-7",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-switch', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', [_c('div', {
          staticClass: "semi-bold"
        }, [_vm._v("Password protected")]), _c('div', [_vm._v("Secure the install page with password")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.passwordProtected,
      callback: function callback($$v) {
        _vm.passwordProtected = $$v;
      },
      expression: "passwordProtected"
    }
  }), _c('v-text-field', _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.passwordProtected,
      expression: "passwordProtected"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: 'max:30|min:3',
      expression: "'max:30|min:3'"
    }],
    staticClass: "mt-1",
    attrs: {
      "hint": _vm.actionData && _vm.actionData.id && _vm.actionData.settings.password_protected ? 'Type a new password to change it' : '',
      "counter": "30",
      "dense": "",
      "filled": "",
      "persistent-hint": "",
      "placeholder": "Protected password",
      "type": "password"
    },
    model: {
      value: _vm.protectedPassword,
      callback: function callback($$v) {
        _vm.protectedPassword = $$v;
      },
      expression: "protectedPassword"
    }
  }, 'v-text-field', _vm.veeValidate('Password', ''), false))], 1), _c('v-col', {
    class: _vm.$vuetify.breakpoint.smAndDown ? 'text-left' : 'text-right',
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none mr-2",
    attrs: {
      "color": "primary",
      "depressed": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.releaseDetail ? _vm.tab = 1 : _vm.tab = 2;
      }
    }
  }, [_vm._v("Previous ")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "id": "proceedToReviewBtn",
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "depressed": ""
    },
    on: {
      "click": _vm.goToReview
    }
  }, [_vm._v("Next ")])], 1)], 1)], 1), _c('v-stepper-content', {
    class: _vm.$vuetify.breakpoint.smAndDown ? 'px-0' : '',
    attrs: {
      "step": 4
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "align-center"
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-android')
    }
  }), _vm._v(" Android: ")], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_vm.androidOption === 'specific_release' ? _c('release-chip', {
    attrs: {
      "release": _vm.selectedAndroid
    }
  }) : _vm.androidOption === 'latest' ? _c('div', [_vm._v("Latest release")]) : _c('div', [_vm._v("-")])], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "align-center"
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-apple')
    }
  }), _vm._v(" iOS: ")], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_vm.iosOption === 'specific_release' ? _c('release-chip', {
    attrs: {
      "release": _vm.selectedIos
    }
  }) : _vm.iosOption === 'testflight' ? _c('div', [_vm._v(" " + _vm._s(_vm.testflightLink) + " ")]) : _c('div', [_vm._v("-")])], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "align-center"
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-calendar')
    }
  }), _vm._v(" Expiration: ")], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.expire_date ? "".concat(_vm.expire_date).concat(_vm.expire_date === 1 ? " Day" : " days") : "-") + " ")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "align-center"
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-lock-outline')
    }
  }), _vm._v(" Passport Protected: ")], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.protectedPassword ? 'success' : 'error',
      "right": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.protectedPassword ? 'mdi-check' : 'mdi-close')
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "align-center"
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-file-swap-outline')
    }
  }), _vm._v(" Internal Instructions: ")], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticStyle: {
      "white-space": "pre-line"
    }
  }, [_vm._v(" " + _vm._s(_vm.formValues.instructions || "-") + " ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "align-center"
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-comment-account-outline')
    }
  }), _vm._v(" Internal Notes: ")], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticStyle: {
      "white-space": "pre-line"
    }
  }, [_vm._v(" " + _vm._s(_vm.formValues.note || "-") + " ")])])], 1), _c('v-row', [_c('v-col', {
    class: _vm.$vuetify.breakpoint.smAndDown ? 'text-left' : 'text-right',
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none mr-2",
    attrs: {
      "color": "primary",
      "depressed": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.tab = 3;
      }
    }
  }, [_vm._v("Previous ")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "id": "createNewLinkBtn",
      "loading": _vm.loading,
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "depressed": ""
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.actionData && _vm.actionData.id ? "Update link" : "Generate link") + " ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('Modal', {
    attrs: {
      "closeable": ""
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('ios-share-info')];
      },
      proxy: true
    }]),
    model: {
      value: _vm.iosShareLearnMore,
      callback: function callback($$v) {
        _vm.iosShareLearnMore = $$v;
      },
      expression: "iosShareLearnMore"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.mdAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 880 : '',
      "no-py": ""
    },
    on: {
      "close": function close($event) {
        _vm.showStartTrialModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm.showStartTrialModal ? _c('start-trial-modal', {
          attrs: {
            "from-share-link": "",
            "trigger-from": "externalFeedback"
          },
          on: {
            "close": function close($event) {
              _vm.showStartTrialModal = false, _vm.selectedPrivacy = 0;
            },
            "open-subscribe": function openSubscribe($event) {
              _vm.openSubscriptionModal = true;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showStartTrialModal,
      callback: function callback($$v) {
        _vm.showStartTrialModal = $$v;
      },
      expression: "showStartTrialModal"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }