<template>
    <div>
        <v-menu nudge-bottom="26">
            <template v-slot:activator="{ on }">
                <v-btn outlined class="text-transform-none" v-on="on" color="primary" depressed>
                    <span class="font14">
                        <span> Y. </span>
                        {{ selectedYear }}
                    </span>
                    <v-icon right v-text="'mdi-chevron-down'"></v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item v-for="(item, index) in yearOptions" :key="index" @click="selectedYear = item">
                    {{ item }}
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
export default {
    name: "chart-date-selector",
    props: {
        fromTeamPlan: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedYear: 2023,
            option: [2021, 2022, 2023],
        };
    },
    computed: {
        ...mapGetters({
            appInfo: "getAppInfo",
        }),
        yearOptions() {
            let appCreatedYear = dayjs.unix(this.appInfo.created_at).year();
            let currentYear = dayjs().year();
            const yearFilterOptions = [];

            for (let year = appCreatedYear; year <= currentYear; year++) {
                yearFilterOptions.push(year);
            }
            if (this.fromTeamPlan) {
                return this.option;
            } else {
                return yearFilterOptions;
            }
        },
    },
    watch: {
        selectedYear(val, oldVal) {
            if (val !== oldVal) {
                this.$emit("change", val);
            }
        },
    },
};
</script>
