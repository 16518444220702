var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "pa-3",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "subListFont"
  }, [_vm._v(" Schedule your app releases for automatic archiving after a set period. This ensures your app remain tidy, organized, and optimizes storage space. ")])])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('div', [_vm._v(" Auto Archive "), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.showAutoArchiveLearnMore = true;
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-help-circle-outline')
    }
  })], 1)], 1)]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "bodyFont",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', [_vm._v(" Team auto-archive is set to: "), _c('b', [_vm._v(_vm._s(_vm.getTeamAutoArchive()))]), _vm._v(". ")])]), _vm.canManageTeam ? _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openSettingForm('team');
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-pencil')
    }
  })], 1)], 1) : _vm._e(), _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "x-small": "",
      "color": "success",
      "label": "",
      "outlined": ""
    }
  }, [_vm._v("NEW")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "bodyFont",
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', [_vm._v(" This app auto-archive is set to: "), _c('b', [_vm._v(_vm._s(_vm.getTeamPlanUsage.auto_archive_release ? _vm.getAutoArchiveValue() : _vm.getTeamAutoArchive()))]), _vm._v(". ")])]), _vm.canManageRelease ? _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openSettingForm('app');
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-pencil')
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('Modal', {
    attrs: {
      "no-py": "",
      "fullscreen": _vm.$vuetify.breakpoint.mdAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 880 : ''
    },
    on: {
      "close": function close($event) {
        _vm.showStartTrialModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm.showStartTrialModal ? _c('start-trial-modal', {
          attrs: {
            "trigger-from": "autoArchiveRelease",
            "tracker-event": {
              app_id: _vm.$route.params.app_id,
              action: 'Auto Archive Release'
            }
          },
          on: {
            "close": function close($event) {
              _vm.showStartTrialModal = false;
            },
            "open-subscribe": function openSubscribe($event) {
              _vm.openSubscriptionModal = true;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showStartTrialModal,
      callback: function callback($$v) {
        _vm.showStartTrialModal = $$v;
      },
      expression: "showStartTrialModal"
    }
  }), _c('Modal', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 1100 : '',
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.openSubscriptionModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('plan-pricing', {
          attrs: {
            "from-modal": "",
            "from-upgrade-component": "",
            "upgrade-plan-text": 'This feature is only available for Starter and Pro plans.'
          },
          on: {
            "success": function success($event) {
              _vm.$emit('close'), _vm.openSubscriptionModal = false;
            },
            "close": function close($event) {
              _vm.openSubscriptionModal = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openSubscriptionModal,
      callback: function callback($$v) {
        _vm.openSubscriptionModal = $$v;
      },
      expression: "openSubscriptionModal"
    }
  }), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.showSettingForm ? _c('settings-form', {
          attrs: {
            "setting-name": _vm.settingName
          },
          on: {
            "close": function close($event) {
              _vm.showSettingForm = false;
            },
            "show-trail": function showTrail($event) {
              _vm.showSettingForm = false, _vm.showStartTrialModal = true;
            },
            "open-subscription": function openSubscription($event) {
              _vm.showSettingForm = false, _vm.openSubscriptionModal = true;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showSettingForm,
      callback: function callback($$v) {
        _vm.showSettingForm = $$v;
      },
      expression: "showSettingForm"
    }
  }), _c('Modal', {
    attrs: {
      "closeable": ""
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('auto-archive-information')];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showAutoArchiveLearnMore,
      callback: function callback($$v) {
        _vm.showAutoArchiveLearnMore = $$v;
      },
      expression: "showAutoArchiveLearnMore"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }