<template>
  <v-card flat class="pa-4">
    <invites />
  </v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { get } from "@/core/services/helper.service";
import invites from "@/view/components/Invites/invites";
export default {
  props: {
    currentTab: {
      type: String,
      default: null,
    },
    fromAppPage: {
      type: Boolean,
      default: false,
    },
    fromDashboard: {
      type: Boolean,
      default: false,
    },
    activeTabType: {
      type: Object,
      default: null,
    },
  },
  components: { invites },
  data() {
    return {
      loading: false,
      page: 1,
    };
  },
  computed: {
    ...mapGetters({
      getTotalTeamInvitesCount: "getTotalTeamInvitesCount",
      getTotalTeamInvitesList: "getTotalTeamInvitesList",
      getUrlTotalList: "getUrlTotalList",
    }),
    ...mapState({
      info: (state) => get(state, "manageTeam.info", ""),
      // inviteLoader: (state) => get(state, "invite.inviteLoader", ""),
      inviteURL: (state) => get(state, "invite.inviteURL", ""),
    }),
  },
};
</script>

<style lang="scss" scoped></style>
