var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('div', [_c('div', {
    staticClass: "bold-text text-body-1"
  }, [_vm._v("Views collected for this link")])])]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_vm.loading ? _c('v-skeleton-loader', {
    staticClass: "transparent",
    attrs: {
      "type": "table-thead, table-tbody"
    }
  }) : _vm._e(), !_vm.loading ? _c('v-data-table', {
    staticClass: "hide-v-data-footer__select release-table",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.getViewsList,
      "page": _vm.page,
      "mobile-breakpoint": "100",
      "server-items-length": _vm.getTotalViewsCount || 0
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      }
    },
    scopedSlots: _vm._u([!_vm.showUpgradeOverlay ? {
      key: "item.device",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.device ? _c('user-agent-parser', {
          attrs: {
            "full": "",
            "default-device": item.device
          }
        }) : _vm._e()];
      }
    } : null, !_vm.showUpgradeOverlay ? {
      key: "item.country",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.country ? _c('country-city', {
          attrs: {
            "detail": item
          }
        }) : _vm._e()];
      }
    } : null, !_vm.showUpgradeOverlay ? {
      key: "item.created_at",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.created_at ? _c('vue-hoverable-date', {
          attrs: {
            "date": item.created_at
          }
        }) : _vm._e()];
      }
    } : null, {
      key: "footer.page-text",
      fn: function fn() {
        return [_c('div', [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": "auto"
          }
        }, [!_vm.showUpgradeOverlay ? _c('span', [_vm._v(" Updates every minute ")]) : _vm._e(), !_vm.showUpgradeOverlay ? _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "darkGrey",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.loadData();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": _vm.$vuetify.theme.dark ? '' : 'grey',
            "left": ""
          },
          domProps: {
            "textContent": _vm._s('mdi-reload')
          }
        }), _vm._v(" Reload ")], 1) : _vm._e(), _vm._v(" Total: "), _c('b', [_vm._v(_vm._s(_vm.numberWithCommas(_vm.getTotalViewsCount)))])], 1), _c('v-col', {
          staticClass: "py-0",
          attrs: {
            "cols": "auto"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-col', {
          staticClass: "no-data-found"
        }, [_c('inline-svg', {
          staticClass: "mt-6 svg-icon empty_icon",
          attrs: {
            "src": "/media/placeholders/all-done".concat(_vm.$vuetify.theme.dark ? '-dark' : '', ".svg")
          }
        }), _c('p', {
          staticClass: "mt-4 empty-title bold-text text-center"
        }, [_vm._v(" No views yet for this link ")]), _c('p', {
          staticClass: "mt-4 mb-5 bodyFont font-weight-normal text-dark text-center"
        }, [_vm._v(" Views are collected from the public install page ")])], 1)], 1)], 1)];
      },
      proxy: true
    }], null, true)
  }, [_vm.showUpgradeOverlay ? _c('template', {
    slot: "item"
  }, [_c('tr', [_c('td', {
    attrs: {
      "colspan": _vm.headers.length
    }
  }, [_c('upgrade-table-overlay', {
    attrs: {
      "total-length": _vm.headers.length,
      "message": _vm.errorMessage
    }
  })], 1)])]) : _vm._e()], 2) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }