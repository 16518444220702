<template>
    <v-card outlined>
        <v-card-title>
            <div>
                <div class="bold-text text-body-1">Views collected for this link</div>
            </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
            <v-skeleton-loader class="transparent" type="table-thead, table-tbody" v-if="loading" />
            <v-data-table v-if="!loading" :headers="headers" :items="getViewsList" :page.sync="page" mobile-breakpoint="100"
                class="hide-v-data-footer__select release-table" :server-items-length="getTotalViewsCount || 0">
                <template v-if="showUpgradeOverlay" slot="item">
                    <tr>
                        <td :colspan="headers.length">
                            <upgrade-table-overlay :total-length="headers.length" :message="errorMessage"></upgrade-table-overlay>
                        </td>
                    </tr>
                </template>
                <template v-if="!showUpgradeOverlay" #item.device="{ item }">
                    <user-agent-parser v-if="item.device" full :default-device="item.device"></user-agent-parser>
                </template>
                <template v-if="!showUpgradeOverlay" #item.country="{ item }">
                    <country-city v-if="item.country" :detail="item"></country-city>
                </template>
                <template v-if="!showUpgradeOverlay" #item.created_at="{ item }">
                    <vue-hoverable-date v-if="item.created_at" :date="item.created_at"></vue-hoverable-date>
                </template>
                <template #footer.page-text>
                    <div>
                        <v-row>
                            <v-col cols="12" md="auto">
                                <span v-if="!showUpgradeOverlay"> Updates every minute </span>
                                <v-btn v-if="!showUpgradeOverlay" class="text-transform-none" @click="loadData()" color="darkGrey" text>
                                    <v-icon v-text="'mdi-reload'" :color="$vuetify.theme.dark ? '' : 'grey'" left>
                                    </v-icon>
                                    Reload
                                </v-btn>
                                Total: <b>{{ numberWithCommas(getTotalViewsCount) }}</b>
                            </v-col>
                            <v-col class="py-0" cols="auto"> </v-col>
                        </v-row>
                    </div>
                </template>
                <template #no-data>
                    <v-row>
                        <v-col cols="12">
                            <!-- <no-data first-text="No feedback given... just yet!"></no-data> -->
                            <v-col class="no-data-found">
                                <inline-svg class="mt-6 svg-icon empty_icon" :src="
                                    `/media/placeholders/all-done${$vuetify.theme.dark ? '-dark' : ''
                                    }.svg`
                                " />
                                <p class="mt-4 empty-title bold-text text-center">
                                    No views yet for this link
                                </p>
                                <p class="
                        mt-4
                        mb-5
                        bodyFont
                        font-weight-normal
                        text-dark text-center
                      ">
                                    Views are collected from the public install page
                                </p>
                            </v-col>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { GET_SHARE_VIEWS_LIST } from "@/store/releases/releases.module";
import { get, isEmpty, numberWithCommas } from "@/core/services/helper.service";
import UserAgentParser from "@/view/components/Common/UserAgentParser";
import CountryCity from "@/view/components/Common/CountryCity";
import UpgradeTableOverlay from "@/view/components/Common/UpgradeTableOverlay";

export default {
    components: { UpgradeTableOverlay, CountryCity, UserAgentParser },
    props: {
        shareId: {
            type: String,
            default: "",
        },
        fromSpecificLink: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            get,
            isEmpty,
            numberWithCommas,
            page: 1,
            loading: false,
            showUpgradeOverlay: false,
            errorMessage: null,
            headers: [
                {
                    text: "Device",
                    align: "start",
                    width: "200",
                    sortable: false,
                    value: "device",
                },
                {
                    text: "Location",
                    align: "start",
                    width: "150",
                    sortable: false,
                    value: "country",
                },
                {
                    text: "Date",
                    align: "end",
                    sortable: false,
                    width: "120",
                    value: "created_at",
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            getViewsList: "getViewsList",
            appInfo: "getAppInfo",
            getTeamPlanAnalytics: "getTeamPlanAnalytics",
            getShareStatsCount: "getShareStatsCount",
            getTotalViewsCount: "getTotalViewsCount",
            getTotalViewsList: "getTotalViewsList",
        }),
    },
    watch: {
        page(val, oldVal) {
            this.showUpgradeOverlay = false;
            this.errorMessage = null;
            let offset = (val - 1) * 10;
            let limit = val * 10;
            // checked if it is next or previous
            if (val > oldVal) {
                //  if next and all the data are already fetched
                if (this.getTotalViewsList.length > oldVal * 10) {
                    this.setPreviousViewsList(
                        this.getTotalViewsList.slice(offset, limit)
                    );
                } else {
                    // if it is first time, get last id and hit api
                    const lastId = this.getViewsList[this.getViewsList.length - 1].id;
                    this.loadData(lastId);
                }
            } else {
                // if it is previous then show already fetched data
                this.setPreviousViewsList(this.getTotalViewsList.slice(offset, limit));
            }
        },
    },
    created() {
        this.loadData();
    },
    methods: {
        ...mapMutations({
            setPreviousViewsList: "setPreviousViewsList",
            setTotalViewsCount: "setTotalViewsCount",
        }),
        loadData(lastId) {
            this.errorMessage = null;
            if (this.loading) return;
            this.loading = true;
            let payload = {
                app_id: this.$route.params.app_id,
                link_id: this.fromSpecificLink ? this.shareId : "",
                lastId: lastId || "",
            };
            this.$store
                .dispatch(GET_SHARE_VIEWS_LIST, payload)
                .then(() => {
                    this.loading = false;
                })
                .catch((err) => {
                    if (err.status === 402) {
                        this.showUpgradeOverlay = true;
                        this.errorMessage = err.message;
                        this.setPreviousViewsList([{}]);
                        this.setTotalViewsCount(this.getShareStatsCount.views || 1);
                    } else {
                        this.notifyErrorMessage(err.message);
                    }
                    this.loading = false;
                });
        },
    },
};
</script>
