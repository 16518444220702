<template>
  <div>
    <template v-if="getInfoErrorMessage">
      <no-data
        :first-text="getInfoErrorMessage"
        with-button
        btn-text="Go back"
      ></no-data>
    </template>
    <template v-else>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="pa-2" flat>
            <v-row>
              <v-col cols="auto">
                <app-image-edit
                  :app="appInfo"
                  :height="80"
                  :width="80"
                ></app-image-edit>
              </v-col>
              <v-col cols="auto">
                <div class="titleFont">
                  {{ appInfo.name }}
                </div>
                <div
                  id="edit-app"
                  class="cursor-pointer"
                  @click="copy(appInfo.id)"
                >
                  <span class="mr-1 primary--text">{{ appInfo.id }}</span>
                  <vue-tooltip
                    :v-html="'App ID can be used in TA-CLI'"
                    color="primary"
                  ></vue-tooltip>
                </div>
                <div>
                  <vue-hoverable-date
                    v-if="appInfo?.created_at"
                    :date="appInfo.created_at"
                  ></vue-hoverable-date>
                  by {{ appInfo?.user?.name }}
                </div>
              </v-col>
              <v-col>
                <div class="top float-right">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <span v-on="on">
                        <v-btn icon @click="onLeaveClick">
                          <v-icon>logout</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span> Leave App </span>
                  </v-tooltip>
                  <v-tooltip v-if="canManageApp" bottom>
                    <template #activator="{ on }">
                      <span v-on="on">
                        <v-btn icon @click="onDeleteClick">
                          <v-icon color="error">mdi-delete</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span> Delete App </span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <template v-if="canManageApp">
          <v-col cols="12" md="12">
            <v-tabs v-model="selectedOption">
              <tab
                v-for="(value, key) in optionList"
                :key="key"
                :label="value.title"
                @click="setAction(value.value)"
              />
            </v-tabs>
          </v-col>

          <v-col cols="12" md="6" v-if="selectedOption === 0">
            <v-card outlined class="my-5" flat>
              <v-card-text>
                <v-row>
                  <!-- Package with help icon -->
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="4">
                        <v-text-field
                          id="packageId"
                          v-model="appInfo.package"
                          disabled
                          filled
                          hide-details
                          label="Package"
                          persistent-hint
                        />
                      </v-col>
                      <v-col cols="auto">
                        <v-dialog v-model="packageHelpDialog" width="500">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              @click="packageHelpDialog = true"
                              v-on="on"
                            >
                              <v-icon>mdi-help-circle-outline</v-icon>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-text class="font-14">
                              <div class="mb-2">
                                This Package or Bundle ID is a unique identifier
                                for your app and is connected to its
                                distribution, releases, SDK, and sessions. It
                                ensures consistency across both Android and iOS
                                platforms.
                              </div>
                              <ul>
                                <li class="mb-2">
                                  Distribution & Releases: Every app must have
                                  one and the same Package or Bundle ID for
                                  creating a release. This ID is central to
                                  aligning your app's project throughout its
                                  lifecycle.
                                </li>

                                <li class="mb-2">
                                  SDK Connection: The ID is vital for linking
                                  the SDK to the correct app or project,
                                  enabling seamless integration and
                                  functionality.
                                </li>

                                <li class="mb-2">
                                  Read-Only: Once set, this ID cannot be
                                  changed. If you need to alter the Package or
                                  Bundle ID, a new app or project must be
                                  created.
                                </li>
                              </ul>
                            </v-card-text>
                            <v-card-subtitle>
                              <flex-row>
                                <v-btn
                                  class="mx-auto"
                                  color="primary"
                                  @click="packageHelpDialog = false"
                                  >Ok
                                </v-btn>
                              </flex-row>
                            </v-card-subtitle>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <!-- Lock Package -->
                    <v-row
                      class="d-flex align-center"
                      v-if="!appInfo?.settings?.lock_package"
                    >
                      <v-col cols="auto">
                        <v-checkbox
                          :input-value="tempLockPackage"
                          label="Lock Package"
                          @change="enableLockPackage"
                          :disabled="loadingPackage"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="auto">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small class="ml-2">
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          <span>
                            Locking the package ensures that releases and
                            sessions are tied together, providing better
                            tracking and analysis.
                          </span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="auto">
                        <v-progress-circular
                          v-if="loadingPackage"
                          indeterminate
                          color="primary"
                          size="24"
                        ></v-progress-circular>
                      </v-col>
                    </v-row>

                    <!-- Lock Unique Releases -->
                    <v-row
                      class="d-flex align-center"
                      v-if="!appInfo?.settings?.lock_unique_releases"
                    >
                      <v-col cols="auto">
                        <v-checkbox
                          :input-value="tempLockUniqueReleases"
                          label="Unique Releases"
                          @change="enableLockUniqueReleases"
                          :disabled="loadingReleaseIncrement"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="auto">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small class="ml-2">
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          <span>
                            Locking unique releases allows for precise error
                            tracking by mapping errors directly to specific
                            release versions.
                          </span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="auto">
                        <v-progress-circular
                          v-if="loadingReleaseIncrement"
                          indeterminate
                          color="primary"
                          size="24"
                        ></v-progress-circular>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="12">
            <app-information v-if="selectedOption === 0" />
            <v-row v-if="selectedOption === 1">
              <v-col cols="12">
                <app-members></app-members>
                <members-and-invites></members-and-invites>
              </v-col>
            </v-row>
            <div v-if="selectedOption === 2" outlined>
              <no-data
                v-if="!canManageApp"
                first-text="No app invites"
                permission-issues
                second-text="You don't have the permission to view or manage the app invites"
              ></no-data>
              <invites-list v-else class="mx-3" from-app-page from-dashboard />
            </div>
            <release-settings v-if="selectedOption === 3"></release-settings>
            <share-link v-if="selectedOption === 4"></share-link>
            <setting-form v-if="selectedOption === 5"></setting-form>
          </v-col>
        </template>
        <Modal v-model="viewEditApp" hideOverlay @close="viewEditApp = false">
          <template #message>
            <EditAppPanel
              :app="appInfo"
              @close="viewEditApp = false"
              @success="$emit('success', $event)"
            />
          </template>
        </Modal>
        <Modal v-model="deleteModal" no-py>
          <template #card>
            <v-card>
              <v-card-title class="font-weight-bold">Delete app</v-card-title>
              <v-divider />
              <v-card-text class="my-4 message">
                <p>Are you sure you want to delete this app?</p>

                <v-alert
                  border="left"
                  class="mt-3"
                  color="info"
                  icon="info"
                  outlined
                >
                  You will lose the access to any information regarding this
                  app.
                  <div v-if="readMore">
                    <br />
                    This app will be deleted along with everything attached to
                    it such as:<br />
                    <br />

                    <ul>
                      <li>Chat</li>
                      <li>Timeline</li>
                      <li>Analytics</li>
                      <li>App Tokens</li>
                      <li>Integrations</li>
                      <li>Releases & Comments</li>
                      <li>Sessions & Feedback</li>
                      <li>Public install links</li>
                      <li>Notifications related this app</li>
                    </ul>

                    <br />
                  </div>
                  <b
                    v-if="!readMore"
                    class="pointer"
                    @click="readMore = !readMore"
                  >
                    {{ "Read more..." }}
                  </b>
                  <br />
                </v-alert>
              </v-card-text>
              <v-divider />
              <v-card-actions class="py-3">
                <div>No undo for this action</div>
                <v-spacer />
                <v-btn
                  id="leaveAppCancelBtn"
                  class="text-transform-none"
                  color="primary"
                  text
                  @click="onCancel"
                  >No
                </v-btn>
                <v-btn
                  id="leaveAppConfirmBtn"
                  :loading="loading"
                  :outlined="$vuetify.theme.dark"
                  class="text-transform-none"
                  color="primary"
                  @click="deleteAppConfirm"
                  >Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </Modal>
        <Modal v-model="modal" no-py>
          <template #card>
            <v-card>
              <v-card-title class="font-weight-bold">Leave app</v-card-title>
              <v-divider />
              <v-card-text class="my-4 message">
                <p>Are you sure you want to leave this app?</p>

                <v-alert
                  border="left"
                  class="mt-3"
                  color="info"
                  icon="info"
                  outlined
                >
                  You will lose the access to any information regarding this
                  app.
                  <div v-if="readMore">
                    <br />
                    If you are the only one in the app, the app will be deleted
                    along with everything attached to it such as:<br />
                    <br />

                    <ul>
                      <li>Releases</li>
                      <li>Timeline</li>
                      <li>Notifications</li>
                    </ul>

                    <br />
                    Otherwise, your data such as releases will remain the same
                    in the app but you will not have the access to them.
                    However, you can still be added
                    {{ canManageTeam ? "or rejoin" : "to" }} the app again at
                    any time if it's still exits.
                  </div>
                  <b
                    v-if="!readMore"
                    class="pointer"
                    @click="readMore = !readMore"
                  >
                    {{ "Read more..." }}
                  </b>
                  <br />
                </v-alert>
              </v-card-text>
              <v-divider />
              <v-card-actions class="py-3">
                <div>No undo for this action</div>
                <v-spacer />
                <v-btn
                  id="leaveAppCancelBtn"
                  class="text-transform-none"
                  color="primary"
                  text
                  @click="onCancelApp"
                  >No
                </v-btn>
                <v-btn
                  id="leaveAppConfirmBtn"
                  :loading="loadingConfirm"
                  :outlined="$vuetify.theme.dark"
                  class="text-transform-none"
                  color="primary"
                  @click="onConfirmApp"
                  >Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </Modal>
      </v-row>
    </template>
  </div>
</template>

<script>
import InvitesList from "@/view/components/Invites/List";
import AppMembers from "@/view/components/App/Members.vue";
import AppImageEdit from "@/view/components/App/AppImageEdit";
import EditAppPanel from "@/view/components/App/EditAppPanel.vue";
import { mapGetters, mapMutations } from "vuex";
import {
  DELETE_APP,
  LEAVE_APP,
  ENABLE_LOCK_PACKAGE,
  ENABLE_LOCK_UNIQUE_RELEASES,
} from "@/store/apps/app.module";
import ReleaseSettings from "@/view/components/Releases/ReleaseSettings";
import AppInformation from "@/view/components/App/AppInformation";
import ShareLink from "@/view/components/App/ShareLink";
import Tab from "@/view/components/Common/Tab.vue";
import SettingForm from "@/view/components/App/SettingForm";

export default {
  components: {
    AppMembers,
    SettingForm,
    ShareLink,
    AppInformation,
    ReleaseSettings,
    Tab,
    AppImageEdit,
    EditAppPanel,
    InvitesList,
  },
  TABS: {
    "app-settings-information": 0,
    "app-settings-members": 1,
    "app-settings-invite": 2,
    "app-settings-release": 3,
    "app-settings-share": 4,
    "app-settings-setting": 5,
  },
  data() {
    return {
      packageHelpDialog: false,
      viewEditApp: false,
      deleteModal: false,
      modal: false,
      activeTab: 0,
      readMore: false,
      openSubscriptionModal: false,
      busy: false,
      loadingConfirm: false,
      showStartTrialModal: false,
      loading: false,

      loadingPackage: false,
      loadingReleaseIncrement: false,
      tempLockPackage: false,
      tempLockUniqueReleases: false,

      selectedOption: 0,
      scheduleList: [],
    };
  },
  watch: {
    $route(to) {
      this.selectedOption = this.$options.TABS[to.name];
    },
  },
  computed: {
    ...mapGetters({
      appInfo: "getAppInfo",
      getInfoErrorMessage: "getInfoErrorMessage",
      getTeamPlanUsage: "getTeamPlanUsage",
    }),
    optionList() {
      return [
        {
          title: "Information",
          show: this.canManageApp,
          value: 0,
        },
        {
          title: "Members",
          show: this.canViewTeamMembers,
          value: 1,
        },
        {
          title: "Invites",
          show: this.canViewInvite,
          value: 2,
        },
        {
          title: "Releases",
          show: this.canManageApp,
          value: 3,
        },
        {
          title: "Share Links",
          show: this.canManageApp,
          value: 4,
        },
        {
          title: "Public App page",
          show: this.canManageApp,
          value: 5,
        },
      ];
    },
  },
  mounted() {
    this.selectedOption = this.$options.TABS[this.$route.name];
  },
  methods: {
    ...mapMutations({
      removeAppFromList: "removeAppFromList",
      removeAppStats: "removeAppStats",
      clearAppInfo: "clearAppInfo",
    }),
    setAction(detail) {
      this.$router.push({
        name: Object.keys(this.$options.TABS)[detail],
        params: { app_id: this.$route.params.app_id },
      });
      this.selectedOption = detail;
    },
    onEditClick() {
      this.viewEditApp = true;
    },
    onLeaveClick() {
      this.modal = true;
    },
    onDeleteClick() {
      this.deleteModal = true;
    },
    onCancelApp() {
      this.modal = false;
      this.deleteModal = false;
    },
    onCancel() {
      this.deleteModal = false;
    },

    async enableLockPackage(newValue) {
      if (
        this.loadingPackage ||
        newValue === this.appInfo.settings.lock_package
      )
        return;

      this.loadingPackage = true;
      this.tempLockPackage = newValue;

      try {
        const response = await this.$store.dispatch(ENABLE_LOCK_PACKAGE, {
          app_id: this.appInfo.id,
          enabled: newValue,
        });
        this.appInfo.settings.lock_package = newValue;
        this.appInfo.package = response.package;
        this.notifyUserMessage({ message: response.message });
      } catch (err) {
        this.tempLockPackage = !newValue; // Revert the temp state on error
        this.notifyErrorMessage(err.message);
      } finally {
        this.loadingPackage = false;
      }
    },

    async enableLockUniqueReleases(newValue) {
      if (
        this.loadingReleaseIncrement ||
        newValue === this.appInfo.settings.lock_unique_releases
      )
        return;

      this.loadingReleaseIncrement = true;
      this.tempLockUniqueReleases = newValue;

      try {
        await this.$store.dispatch(ENABLE_LOCK_UNIQUE_RELEASES, {
          app_id: this.appInfo.id,
          enabled: newValue,
        });
        this.appInfo.settings.lock_unique_releases = newValue;
        this.notifyUserMessage({ message: response.message });
      } catch (err) {
        this.tempLockUniqueReleases = !newValue; // Revert the temp state on error
        this.notifyErrorMessage(err.message);
      } finally {
        this.loadingReleaseIncrement = false;
      }
    },

    deleteAppConfirm() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(DELETE_APP, this.appInfo.id)
        .then((response) => {
          this.notifyUserMessage({ message: response.message });
          this.removeAppFromList(this.appInfo.id);
          this.clearAppInfo();
          this.removeAppStats({
            appCount: 1,
          });
          this.$router.push({ name: "apps" }).catch(() => {});
          this.loading = false;
          this.deleteModal = false;
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },

    async onConfirmApp() {
      this.loadingConfirm = true;
      try {
        const response = await this.$store.dispatch(LEAVE_APP, this.appInfo.id);
        this.modal = false;
        this.notifyUserMessage({
          message: response.message || "Successfully left the app",
        });
        this.$router.push({ name: "apps" }).catch(() => {});
        this.loadingConfirm = false;
        this.removeAppFromList(this.appInfo.id);
        this.removeAppStats({
          appCount: 1,
        });
        this.clearAppInfo();
        this.leave = false;
      } catch (err) {
        this.modal = false;
        this.notifyErrorMessage(err.message || "Failed to leave app");
        this.loadingConfirm = false;
      }
    },
  },
};
</script>
