<template>
  <v-row class="mx-0 px-0">
    <v-col
      :class="$vuetify.breakpoint.smAndDown ? 'px-0' : ''"
      class="mt-8"
      cols="12"
    >
      <v-stepper v-model="tab" flat non-linear>
        <v-stepper-header flat>
          <v-stepper-step
            complete
            :step="1"
            v-if="releaseDetail?.platform === 'android' || !releaseDetail"
            complete-icon="mdi-android"
            edit-icon="mdi-android"
          >
            Android
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            complete
            :step="2"
            v-show="releaseDetail?.platform === 'ios' || !releaseDetail"
            complete-icon="mdi-apple"
            edit-icon="mdi-apple"
          >
            iOS
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            complete
            :step="3"
            complete-icon="mdi-cogs"
            edit-icon="mdi-cogs"
          >
            Settings
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step complete complete-icon="mdi-eye" :step="4">
            Review & Submit
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content
            v-show="releaseDetail?.platform === 'android' || !releaseDetail"
            :step="1"
            class="pa-3"
          >
            <v-row class="pa-4">
              <v-col v-if="appInfo?.latest_releases.android" cols="12" md="12">
                <div>Select link type for Android</div>
                <v-radio-group
                  :disabled="!!releaseDetail"
                  v-model="androidOption"
                  hide-details
                >
                  <v-row align="center">
                    <v-col class="pr-0" cols="1">
                      <v-radio value="skip"> </v-radio>
                    </v-col>
                    <v-col class="pl-0" cols="11">
                      <b>Skip:</b> <span>No android release available.</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col class="pr-0" cols="1">
                      <v-radio value="latest"> </v-radio>
                    </v-col>
                    <v-col class="pl-0" cols="11">
                      <b>Self-Update Link: </b>
                      <span
                        >Automatically updates with each new Android release.
                        Testers receive the latest version. No manual link
                        updates needed.</span
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col class="pr-0" cols="1">
                      <v-radio value="specific_release"> </v-radio>
                    </v-col>
                    <v-col class="pl-0" cols="11">
                      <b>Specific Release: </b>
                      <span
                        >Choose a specific release to be installed. Change
                        versions anytime using the same link.</span
                      >
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
              <template v-else>
                <v-col cols="12">
                  <no-data first-text="No active Android releases"></no-data>
                </v-col>
              </template>
              <v-col v-if="androidOption === 'specific_release'" cols="12">
                <release-auto-complete
                  v-model="selectedAndroid"
                  :app_id="appId"
                  :disable="!!releaseDetail"
                  :default-release="selectedAndroid"
                  :first-load="firstLoadAndroid"
                  platform="android"
                  @loaded="firstLoadAndroid = false"
                ></release-auto-complete>
              </v-col>
              <v-col
                :class="
                  $vuetify.breakpoint.smAndDown ? 'text-left' : 'text-right'
                "
                cols="12"
              >
                <v-btn
                  id="proceedToIosBtn"
                  :outlined="$vuetify.theme.dark"
                  class="text-transform-none"
                  color="primary"
                  depressed
                  @click="proceedToIOS"
                  >Next
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content
            :class="$vuetify.breakpoint.smAndDown ? 'px-0' : ''"
            v-show="releaseDetail?.platform === 'ios' || !releaseDetail"
            :step="2"
          >
            <v-row>
              <template v-if="appInfo?.latest_releases?.ios">
                <v-col cols="12">
                  <div class="bodyFont text-left">Select link type for iOS</div>
                </v-col>
                <v-col cols="12" md="12">
                  <v-radio-group
                    v-model="iosOption"
                    :disabled="!!releaseDetail"
                    class="mt-3"
                  >
                    <v-row align="center">
                      <v-col class="pr-0" cols="1">
                        <v-radio value="skip"> </v-radio>
                      </v-col>
                      <v-col class="pl-0" cols="11">
                        <b>Skip:</b> <span>No iOS release available.</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0" cols="12">
                        <v-divider />
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col class="pr-0 pb-0" cols="1">
                        <v-radio value="testflight"> </v-radio>
                      </v-col>
                      <v-col class="pl-0" cols="11">
                        <b>Testflight: </b
                        ><span
                          >External testers without having to collect their
                          UDIDs.</span
                        >
                      </v-col>
                      <v-col v-if="iosOption === 'testflight'" cols="12">
                        <v-text-field
                          v-model="testflightLink"
                          v-validate="'required|url'"
                          class="py-0 my-0"
                          dense
                          type="url"
                          hint="Check developer.apple.com -> TestFlight -> External Testing"
                          outlined
                          placeholder="https://testflight.apple.com/join/pC3Sf5yp"
                          v-bind="veeValidate('testflight link', '')"
                        >
                          <template #append>
                            <v-btn
                              :color="validated ? 'success' : 'primary'"
                              :disabled="errors.any()"
                              :loading="busy"
                              :outlined="$vuetify.theme.dark"
                              class="text-transform-none mb-2"
                              small
                              @click.stop="validateTestFlightLink"
                            >
                              <v-icon
                                v-if="validated"
                                left
                                small
                                v-text="'mdi-check'"
                              ></v-icon>
                              {{ validated ? "Validated" : "Validate" }}
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0" cols="12">
                        <v-divider />
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col class="pr-0" cols="1">
                        <v-radio value="specific_release"> </v-radio>
                      </v-col>
                      <v-col class="pl-0" cols="11">
                        <b>Specific Release (Enterprise Build):</b>
                        <span>
                          Choose a specific release to be installed that's built
                          with Apple's Enterprise Certificate. Change versions
                          anytime using the same link.</span
                        >
                        <v-btn icon @click="iosShareLearnMore = true">
                          <v-icon> mdi-help-circle-outline </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col v-if="iosOption === 'specific_release'" cols="12">
                        <release-auto-complete
                          v-model="selectedIos"
                          :app_id="appId"
                          :disable="!!releaseDetail"
                          :first-load="firstLoadIOS"
                          :default-release="selectedIos"
                          destination="share"
                          class="mt-n4"
                          platform="ios"
                          no-data-message="No iOS releases with enterprise certificate"
                          @loaded="firstLoadIOS = false"
                        ></release-auto-complete>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="12">
                  <no-data first-text="No active iOS releases"></no-data>
                </v-col>
              </template>
              <v-col
                :class="
                  $vuetify.breakpoint.smAndDown ? 'text-left' : 'text-right'
                "
                cols="12"
              >
                <v-btn
                  v-if="releaseDetail?.platform !== 'android'"
                  class="text-transform-none mr-2"
                  color="primary"
                  depressed
                  outlined
                  @click="tab = 1"
                  >Previous
                </v-btn>
                <v-btn
                  id="proceedToSettingsBtn"
                  :outlined="$vuetify.theme.dark"
                  class="text-transform-none"
                  :loading="busy"
                  color="primary"
                  depressed
                  @click="proceedToSettings"
                  >Next
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content
            :class="$vuetify.breakpoint.smAndDown ? 'px-0' : ''"
            :step="3"
          >
            <v-row class="px-3">
              <v-col class="pb-0" cols="12" md="12">
                <v-row align="center">
                  <v-col class="pr-0 pb-0" cols="10">
                    <v-select
                      v-model="expire_date"
                      :items="expiryDays"
                      dense
                      filled
                      hide-details
                      item-text="title"
                      item-value="value"
                    >
                    </v-select>
                  </v-col>
                  <v-col class="pb-0" cols="auto">
                    <vue-tooltip
                      icon-color="darkGrey"
                      text="Automatically disable the link. You can change the option at any time before it expires but once it does, you cannot make active again"
                    ></vue-tooltip>
                  </v-col>
                  <v-col v-if="expire_date > 0" class="pt-0" cols="12">
                    <v-checkbox
                      v-model="formValues.settings.expire_notify"
                      label="Notify by email when this link expires"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-divider />
              </v-col>
              <v-col class="py-0" cols="12" md="12">
                <div class="bodyFont text-left">
                  Install instructions
                  <span
                    class="primary--text text-right cursor-pointer caption"
                    @click="copyFromAppInstruction"
                  >
                    Copy from global instructions
                  </span>
                  <vue-tooltip
                    icon-color="darkGrey"
                    text="Install instructions will be display in the public install page"
                  ></vue-tooltip>
                </div>
                <v-textarea
                  v-model="formValues.instructions"
                  v-validate="'max:600'"
                  counter="600"
                  filled
                  placeholder="Any special instructions for the testers to start testing this app? Specific environment or a demo account?"
                  rows="3"
                  v-bind="veeValidate('instructions', '')"
                >
                </v-textarea>
              </v-col>
              <v-col class="py-0" cols="12" md="12">
                <div class="bodyFont text-left">
                  Internal notes for team
                  <vue-tooltip
                    icon-color="darkGrey"
                    text="These notes are only visible to your team (owners & developers)"
                  ></vue-tooltip>
                </div>
                <v-textarea
                  v-model="formValues.note"
                  v-validate="'max:600'"
                  counter="600"
                  filled
                  placeholder="Where do you want to share this link or with who? Only visible to your team (owners & developers)"
                  rows="3"
                  v-bind="veeValidate('note', '')"
                >
                </v-textarea>
              </v-col>
              <v-col class="pt-7" cols="12" md="12">
                <v-switch v-model="passwordProtected">
                  <template #label>
                    <div>
                      <div class="semi-bold">Password protected</div>
                      <div>Secure the install page with password</div>
                    </div>
                  </template>
                </v-switch>
                <v-text-field
                  v-show="passwordProtected"
                  v-model="protectedPassword"
                  v-validate="'max:30|min:3'"
                  :hint="
                    actionData &&
                    actionData.id &&
                    actionData.settings.password_protected
                      ? 'Type a new password to change it'
                      : ''
                  "
                  class="mt-1"
                  counter="30"
                  dense
                  filled
                  persistent-hint
                  placeholder="Protected password"
                  type="password"
                  v-bind="veeValidate('Password', '')"
                ></v-text-field>
              </v-col>

              <v-col
                :class="
                  $vuetify.breakpoint.smAndDown ? 'text-left' : 'text-right'
                "
                cols="12"
              >
                <v-btn
                  class="text-transform-none mr-2"
                  color="primary"
                  depressed
                  outlined
                  @click="releaseDetail ? (tab = 1) : (tab = 2)"
                  >Previous
                </v-btn>
                <v-btn
                  id="proceedToReviewBtn"
                  :outlined="$vuetify.theme.dark"
                  class="text-transform-none"
                  color="primary"
                  depressed
                  @click="goToReview"
                  >Next
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content
            :class="$vuetify.breakpoint.smAndDown ? 'px-0' : ''"
            :step="4"
          >
            <v-row align="center">
              <v-col cols="12" md="3">
                <div class="align-center">
                  <v-icon v-text="'mdi-android'"></v-icon>
                  Android:
                </div>
              </v-col>
              <v-col cols="12" md="9">
                <release-chip
                  v-if="androidOption === 'specific_release'"
                  :release="selectedAndroid"
                />
                <div v-else-if="androidOption === 'latest'">Latest release</div>
                <div v-else>-</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12">
                <v-divider />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12" md="3">
                <div class="align-center">
                  <v-icon v-text="'mdi-apple'"></v-icon>
                  iOS:
                </div>
              </v-col>
              <v-col cols="12" md="9">
                <release-chip
                  v-if="iosOption === 'specific_release'"
                  :release="selectedIos"
                />
                <div v-else-if="iosOption === 'testflight'">
                  {{ testflightLink }}
                </div>
                <div v-else>-</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12">
                <v-divider />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12" md="3">
                <div class="align-center">
                  <v-icon v-text="'mdi-calendar'"></v-icon>
                  Expiration:
                </div>
              </v-col>
              <v-col cols="12" md="9">
                {{
                  expire_date
                    ? `${expire_date}${expire_date === 1 ? " Day" : " days"}`
                    : "-"
                }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12">
                <v-divider />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12" md="3">
                <div class="align-center">
                  <v-icon v-text="'mdi-lock-outline'"></v-icon>
                  Passport Protected:
                </div>
              </v-col>
              <v-col cols="12" md="9">
                <v-icon
                  :color="protectedPassword ? 'success' : 'error'"
                  right
                  v-text="protectedPassword ? 'mdi-check' : 'mdi-close'"
                ></v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12">
                <v-divider />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12" md="3">
                <div class="align-center">
                  <v-icon v-text="'mdi-file-swap-outline'"></v-icon>
                  Internal Instructions:
                </div>
              </v-col>
              <v-col cols="12" md="9">
                <div style="white-space: pre-line">
                  {{ formValues.instructions || "-" }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12">
                <v-divider />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12" md="3">
                <div class="align-center">
                  <v-icon v-text="'mdi-comment-account-outline'"></v-icon>
                  Internal Notes:
                </div>
              </v-col>
              <v-col cols="12" md="9">
                <div style="white-space: pre-line">
                  {{ formValues.note || "-" }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :class="
                  $vuetify.breakpoint.smAndDown ? 'text-left' : 'text-right'
                "
                cols="12"
              >
                <v-btn
                  class="text-transform-none mr-2"
                  color="primary"
                  depressed
                  outlined
                  @click="tab = 3"
                  >Previous
                </v-btn>
                <v-btn
                  id="createNewLinkBtn"
                  :loading="loading"
                  :outlined="$vuetify.theme.dark"
                  class="text-transform-none"
                  color="primary"
                  depressed
                  @click="submitForm"
                >
                  {{
                    actionData && actionData.id
                      ? "Update link"
                      : "Generate link"
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-col>
    <Modal closeable v-model="iosShareLearnMore">
      <template #card>
        <ios-share-info></ios-share-info>
      </template>
    </Modal>
    <Modal
      v-model="showStartTrialModal"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      :width="$vuetify.breakpoint.mdAndUp ? 880 : ''"
      no-py
      @close="showStartTrialModal = false"
    >
      <template #message>
        <start-trial-modal
          v-if="showStartTrialModal"
          from-share-link
          trigger-from="externalFeedback"
          @close="(showStartTrialModal = false), (selectedPrivacy = 0)"
          @open-subscribe="openSubscriptionModal = true"
        ></start-trial-modal>
      </template>
    </Modal>
  </v-row>
</template>

<script>
import ReleaseAutoComplete from "@/view/components/Common/ReleaseAutoComplete";
import { formatBytes, get, isEmpty } from "@/core/services/helper.service.js";
import veeValidate from "@/mixins/veeValidate";
import {
  CREATE_SHAREABLE_LINK,
  UPDATE_SHAREABLE_LINK,
  VALIDATE_TESTFLIGHT_LINK,
} from "@/store/releases/releases.module";
import { mapGetters, mapMutations } from "vuex";
import StartTrialModal from "@/view/components/Common/StartTrialModal";
import IosShareInfo from "@/view/components/Releases/ReleaseDetailPage/IosShareInfo";

export default {
  props: {
    releaseDetail: {
      type: Object,
      default: null,
    },
    actionData: {
      type: Object,
      default() {
        return {
          release: null,
          instructions: "",
          settings: {
            expire: 0,
            expire_notify: false,
            public_access: true,
            allow_subscribing: false,
          },
        };
      },
    },
  },
  components: {
    StartTrialModal,
    IosShareInfo,
    ReleaseAutoComplete,
  },
  mixins: [veeValidate],
  data() {
    return {
      tab: 1,
      isEmpty,
      formatBytes,
      iosShareLearnMore: false,
      showStartTrialModal: false,
      get,
      passwordVisible: false,
      androidOption: "skip",
      selectedPrivacy: 0,
      passwordProtected: false,
      protectedPassword: "",
      selectedLimit: "limited",
      visibilityOption: [
        {
          text: "Private & Unlisted",
          icon: "mdi-lock-outline",
          value: "private_unlisted",
        },
        {
          text: "Public & listed",
          icon: "mdi-earth",
          value: "public_listed",
        },
      ],
      expiryDays: [
        { title: "No expiry date", value: 0 },
        { title: "Expire in 1 day", value: 1 },
        { title: "Expire in 2 days", value: 2 },
        { title: "Expire in 3 days", value: 3 },
        { title: "Expire in 4 days", value: 4 },
        { title: "Expire in 5 days", value: 5 },
        { title: "Expire in 6 days", value: 6 },
        { title: "Expire in 7 days", value: 7 },
        { title: "Expire in 8 days", value: 8 },
        { title: "Expire in 9 days", value: 9 },
        { title: "Expire in 10 days", value: 10 },
        { title: "Expire in 11 days", value: 11 },
        { title: "Expire in 12 days", value: 12 },
        { title: "Expire in 13 days", value: 13 },
        { title: "Expire in 14 days", value: 14 },
        { title: "Expire in 15 days", value: 15 },
        { title: "Expire in 16 days", value: 16 },
        { title: "Expire in 17 days", value: 17 },
        { title: "Expire in 18 days", value: 18 },
        { title: "Expire in 19 days", value: 19 },
        { title: "Expire in 20 days", value: 20 },
        { title: "Expire in 21 days", value: 21 },
        { title: "Expire in 22 days", value: 22 },
        { title: "Expire in 23 days", value: 23 },
        { title: "Expire in 24 days", value: 24 },
        { title: "Expire in 25 days", value: 25 },
        { title: "Expire in 26 days", value: 26 },
        { title: "Expire in 27 days", value: 27 },
        { title: "Expire in 28 days", value: 28 },
        { title: "Expire in 29 days", value: 29 },
        { title: "Expire in 30 days", value: 30 },
      ],
      iosOption: "skip",
      validated: false,
      expire_date: 0,
      shareType: "internal",
      testflightLink: "",
      allow_expire: false,
      selectedAndroid: null,
      selectedReleaseDialog: false,
      releasePlatform: false,
      busy: false,
      firstLoadIOS: true,
      firstLoadAndroid: true,
      selectedIos: null,
      loading: false,
      formValues: {
        android: {
          id: "",
          latest: false,
        },
        ios: {
          id: "",
          latest: false,
        },
        instructions: "",
        settings: {
          expire: 0,
          expire_notify: false,
          allow_subscribing: false,
        },
      },
      allowAutoUpdate: false,
    };
  },
  computed: {
    ...mapGetters({
      appInfo: "getAppInfo",
      getTeamPlanUsage: "getTeamPlanUsage",
      getTeamTrial: "getTeamTrial",
    }),
  },
  watch: {
    expire_date(val) {
      if (val > 0 && !this.actionData.id) {
        this.formValues.settings.expire_notify = true;
      }
      if (val == 0) {
        this.formValues.settings.expire_notify = false;
      }
      if (this.actionData && this.actionData.id) {
        this.formValues.settings.expire_notify =
          this.actionData.settings.expire_notify;
      }
    },
    testflightLink() {
      this.validated = false;
    },
    passwordProtected(val) {
      if (!val) {
        this.protectedPassword = "";
      }
    },
    selectedLimit(val) {
      if (val === "unlimited" && isEmpty(this.getTeamTrial)) {
        this.selectedLimit = "limited";
        this.showStartTrialModal = true;
      }
    },
    selectedIos() {
      this.selectedReleaseDialog = false;
    },
    selectedAndroid() {
      this.selectedReleaseDialog = false;
    },
    androidOption(val) {
      if (val !== "specific_release") {
        this.selectedAndroid = null;
      }
    },
    iosOption(val) {
      if (val !== "specific_release") {
        this.selectedIos = null;
      }
    },
    shareType() {
      this.iosOption = "skip";
      this.selectedIos = null;
    },
  },
  created() {
    this.appId = this.$route.params.app_id;
    if (this.releaseDetail?.id) {
      if (this.releaseDetail?.platform === "android") {
        this.androidOption = "specific_release";
        this.selectedAndroid = this.releaseDetail;
      } else {
        this.tab = 2;
        this.iosOption = "specific_release";
        this.selectedIos = this.releaseDetail;
      }
    }
    this.populateInitialData();
  },
  methods: {
    ...mapMutations({
      setShareLinkUpdatedData: "setShareLinkUpdatedData",
    }),
    openSelectedReleaseForm(platform) {
      this.releasePlatform = platform;
      this.selectedReleaseDialog = true;
    },
    async goToReview() {
      if (await this.validateAllFields()) {
        this.tab = 4;
      }
    },
    populateInitialData() {
      if (this.actionData && this.actionData.id) {
        this.formValues = { ...this.actionData };
      }
      if (this.actionData && this.actionData.id) {
        this.passwordProtected =
          this.actionData.settings.password_protected || false;
        if (this.actionData.settings.password_protected) {
          this.protectedPassword = "******";
        }
        this.expire_date = this.actionData.settings.expire || 0;
        const android = this.actionData.android;
        if (this.actionData.settings.latest_android) {
          this.androidOption = "latest";
        } else if (!android || isEmpty(android)) {
          this.androidOption = "skip";
        } else {
          this.selectedAndroid = android;
          this.androidOption = "specific_release";
        }
        const ios = this.actionData.ios;
        if (ios && !isEmpty(ios) && ios.testflight) {
          this.iosOption = "testflight";
          this.testflightLink = ios.testflight;
        }
        if (ios && !isEmpty(ios) && !this.actionData.settings.latest_ios) {
          this.selectedIos = ios;
          this.iosOption = "specific_release";
        }
        if (!ios || (isEmpty(ios) && !this.actionData.settings.latest_ios)) {
          this.iosOption = "skip";
        }
      }
    },
    copyFromAppInstruction() {
      this.formValues.instructions =
        this.appInfo?.settings?.global_instructions || "";
    },
    disableSubmit() {
      if ([this.iosOption, this.androidOption].includes("skip")) {
        return true;
      }
      if (
        [this.iosOption, this.androidOption].includes("specific_release") &&
        isEmpty(this.selectedIos) &&
        isEmpty(this.androidOption)
      ) {
        return true;
      }
      return false;
    },
    submitForm() {
      if (
        this.androidOption === "specific_release" &&
        isEmpty(this.selectedAndroid)
      ) {
        this.androidOption = "skip";
      }
      if (this.iosOption === "specific_release" && isEmpty(this.selectedIos)) {
        this.iosOption = "skip";
      }
      if (
        (this.androidOption === "specific_release" &&
          isEmpty(this.selectedAndroid)) ||
        (this.iosOption === "specific_release" && isEmpty(this.selectedIos)) ||
        (this.iosOption === "testflight" && !this.testflightLink)
      ) {
        this.notifyErrorMessage("Please select valid iOS option");
        return;
      }

      if (
        this.androidOption === "skip" &&
        this.iosOption === "skip" &&
        this.iosOption !== "testflight"
      ) {
        this.notifyErrorMessage("Please select valid android option");
        return;
      }

      if (this.actionData && this.actionData.id) {
        this.updateShareAbleLink();
      } else {
        this.generateShareableLink();
      }
    },
    proceedToIOS() {
      if (
        this.androidOption === "specific_release" &&
        isEmpty(this.selectedAndroid)
      ) {
        this.notifyErrorMessage("Please select Android release");
      } else {
        this.tab = this.releaseDetail ? 3 : 2;
      }
    },
    proceedToSettings() {
      if (this.iosOption === "specific_release" && isEmpty(this.selectedIos)) {
        this.notifyErrorMessage("Please select iOS release");
      } else if (this.iosOption === "testflight") {
        if (this.errors.any()) {
          this.notifyErrorMessage("Please enter a valid TestFlight link");
        } else if (!this.validated) {
          this.validateTestFlightLink().then(() => {
            if (this.validated) {
              this.tab = 3;
            }
          });
        } else {
          this.tab = 3;
        }
      } else {
        this.tab = 3;
      }
    },
    generateShareableLink() {
      if (this.loading) return;
      this.loading = true;
      if (!this.protectedPassword && this.passwordProtected) {
        this.notifyErrorMessage(
          "You've enabled the password protection for this link but haven't provided a password"
        );
        this.tab = 3;
        this.loading = false;
        return;
      }
      this.formValues.password = this.protectedPassword || "";
      if (this.passwordProtected === "******") {
        this.formValues.password = "";
      }
      this.formValues.settings.password_protected =
        this.passwordProtected || false;
      if (this.androidOption === "specific_release") {
        this.formValues.android.id = this.selectedAndroid.id;
      } else if (this.androidOption === "latest") {
        this.formValues.android.latest = true;
      }
      this.formValues.settings.expire = this.expire_date || 0;
      if (this.iosOption === "testflight") {
        this.formValues.ios.testflight_link = this.testflightLink;
      } else {
        if (this.iosOption === "specific_release") {
          this.formValues.ios.id = this.selectedIos.id;
        } else if (this.iosOption === "skip") {
          this.formValues.ios.latest = false;
        }
      }
      this.formValues.app_id = this.$route.params.app_id;
      this.$store
        .dispatch(CREATE_SHAREABLE_LINK, this.formValues)
        .then((response) => {
          this.loading = false;
          this.$emit("close");
          this.$emit("refresh", response);
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    validateTestFlightLink() {
      const dataToPost = {
        link: this.testflightLink,
        app_id: this.$route.params.app_id,
      };
      if (this.busy) return;
      this.busy = true;
      return this.$store
        .dispatch(VALIDATE_TESTFLIGHT_LINK, dataToPost)
        .then(() => {
          this.busy = false;
          this.validated = true;
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
    updateShareAbleLink() {
      if (this.loading) return;
      this.loading = true;
      if (!this.protectedPassword && this.passwordProtected) {
        this.notifyErrorMessage(
          "You've enabled the password protection for this link but haven't provided a password"
        );
        this.tab = 3;
        this.loading = false;
        return;
      }
      this.formValues.android = {
        id: "",
        latest: false,
      };
      this.formValues.ios = {
        id: "",
        latest: false,
      };
      this.formValues.password = this.protectedPassword || "";
      this.formValues.settings.expire = this.expire_date;
      this.formValues.settings.password_protected =
        this.passwordProtected || false;
      if (this.protectedPassword == "******") {
        this.formValues.password = "";
      }
      if (this.formValues.settings.allow_subscribing === "false") {
        this.formValues.settings.allow_subscribing = false;
      }
      if (this.formValues.settings.allow_subscribing === "true") {
        this.formValues.settings.allow_subscribing = true;
      }
      if (this.androidOption === "specific_release") {
        this.formValues.android.id = this.selectedAndroid.id;
      } else if (this.androidOption === "latest") {
        this.formValues.android.latest = true;
      }

      if (this.shareType === "external") {
        this.formValues.ios.testflight_link = this.testflightLink;
      } else {
        if (this.iosOption === "specific_release") {
          this.formValues.ios.id = this.selectedIos.id;
        } else if (this.iosOption === "latest") {
          this.formValues.ios.latest = true;
        }
      }
      this.formValues.app_id = this.$route.params.app_id;

      this.$store
        .dispatch(UPDATE_SHAREABLE_LINK, this.formValues)
        .then((response) => {
          this.loading = false;
          this.setShareLinkUpdatedData(this.formValues);
          this.notifyUserMessage({ message: response.message });
          this.$emit("close");
          this.$emit("updated", response);
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
  },
};
</script>

<style scoped>
.successBorder {
  border: #0abb87 1px solid;
}
</style>
