<template>
  <div>
    <v-row v-if="!canManageApp">
      <v-col cols="12">
        <no-data
          :first-text="'You don\'t have the permission to view or manage the public page'"
        >
        </no-data>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-col class="text-center px-0" cols="12">
          <vue-inline-alert
            message="The details provided here will be applied to all your install links.
            Everything on this page will be showcased publicly on the app page
            for external users."
            message-type="info"
            outlined
            no-close-btn
          >
          </vue-inline-alert>
        </v-col>
        <v-card v-if="!getAppInfoLoading" class="pb-10 pr-10" outlined>
          <v-card-title>
            <div>
              <div class="titleFont">App Information</div>
              <div class="font-weight-regular smallFont">
                Upload screenshots and complete your app details to craft a
                captivating page for your external testers.
              </div>
            </div>
            <v-spacer />
            <v-tooltip bottom>
              <template #activator="{ on }">
                <span v-on="on">
                  <v-switch
                    v-model="public_access"
                    hide-details
                    label="Public Access"
                    @click="updatePublicAccess"
                  ></v-switch>
                </span>
              </template>
              <span>
                Enable or disable public access for all public links in this app
              </span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-row align="center">
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="form.categories"
                      v-validate="'categories_validation'"
                      :items="getCategoriesList"
                      chips
                      counter="3"
                      filled
                      flat
                      hint=""
                      item-text="name"
                      item-value="id"
                      label="App categories"
                      multiple
                      persistent-hint
                      v-bind="veeValidate('category', '')"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          :input-value="data.selected"
                          close
                          v-bind="data.attrs"
                          @click="data.select"
                          @click:close="remove(data.item)"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      id="settingsDescription"
                      v-model="form.description"
                      v-validate="'max:600'"
                      auto-grow
                      counter="600"
                      filled
                      hint="This will be displayed in the public install page"
                      label="Description"
                      placeholder="Description about this app"
                      rows="4"
                      v-bind="veeValidate('Description', '')"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  id="settingGlobalInstructions"
                  v-model="form.settings.global_instructions"
                  v-validate="'max:600'"
                  auto-grow
                  counter="600"
                  filled
                  hint="This will be displayed in the public install page. Ex: Demo account, environment configuration, tips,..."
                  label="Global install instruction"
                  placeholder="Any special instructions for the testers to start testing this app?"
                  rows="4"
                  v-bind="veeValidate('Instruction', '')"
                >
                </v-textarea>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <div>
                      App store links (Optional)
                      <VueTooltip
                        :v-html="'App store links will be displayed in the the public install page'"
                        color="primary"
                        icon-color="grey"
                        text-class="white--text"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      id="androidStoreLink"
                      v-model="form.settings.store_links.android"
                      v-validate="'url'"
                      class="mt-n5"
                      filled
                      hint="Google Play store, Huawei AppGallery, Samsung Galaxy Store,..."
                      label="Android app store"
                      placeholder="https://play.google.com/store/apps/details?id=testapp.io"
                      v-bind="veeValidate('link', '')"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      id="appStoreLink"
                      v-model="form.settings.store_links.ios"
                      v-validate="'url'"
                      class="mt-n5"
                      filled
                      hint="The URL for the live app in Apple app store"
                      label="Apple app store"
                      placeholder="https://apps.apple.com/us/app/testapp-io/id1518972541"
                      v-bind="veeValidate('Link', '')"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <screen-shot-upload
                  :screenshot-list="info?.screenshots"
                ></screen-shot-upload>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-title>
            <div>
              <div class="titleFont">Developer Information</div>
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  id="developerName"
                  v-model="form.settings.developer_contact.name"
                  v-validate="'required|min:3|max:80'"
                  filled
                  hint="This name will be displayed in the the public install page"
                  label="Developer Name"
                  placeholder="Company name or your name"
                  v-bind="veeValidate('Developer Name', '')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  id="settingCompanyEmail"
                  v-model="form.settings.developer_contact.email"
                  v-validate="'required|email'"
                  filled
                  hint="This email will be displayed in the the public install page"
                  label="Support Email"
                  placeholder="Company email or your email"
                  v-bind="veeValidate('Email', '')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  id="settingPrivacyPolicy"
                  v-model="form.settings.developer_contact.privacy"
                  v-validate="'url'"
                  filled
                  hint="Optional - Ex: https://company.com/privacy-policy"
                  label="Privacy Policy"
                  placeholder="Privacy Policy URL for this app"
                  v-bind="veeValidate('Privacy', '')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  id="settingTerms&Conditions"
                  v-model="form.settings.developer_contact.terms"
                  v-validate="'url'"
                  filled
                  hint="Optional - Ex: https://company.com/terms-conditions"
                  label="Terms & Conditions"
                  placeholder="Terms & Conditions URL for this app"
                  v-bind="veeValidate('Terms and condition', '')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  id="settingsWebsite"
                  v-model="form.settings.developer_contact.website"
                  v-validate="'url'"
                  filled
                  hint="Optional - Ex: https://testapp.io"
                  label="Website"
                  placeholder="App or company website (Ex: https://testapp.io)"
                  v-bind="veeValidate('Website', '')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              id="settingUpdateBtn"
              :disabled="!canManageApp || errors.any() || busy"
              :loading="busy"
              :outlined="$vuetify.theme.dark"
              class="text-transform-none"
              color="primary"
              depressed
              large
              @click="updateSettings"
              >Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader type="form"></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { get } from "@/core/services/helper.service";
import veeValidate from "@/mixins/veeValidate";
import {
  DELETE_IMAGE,
  GET_APP_CATEGORIES,
  UPDATE_APP_SETTINGS,
  UPDATE_IMAGE,
} from "@/store/apps/app.module";
import ScreenShotUpload from "@/view/components/App/ScreenShotUpload";
import { mapGetters, mapMutations } from "vuex";
import { Validator } from "vee-validate";
import { UPDATE_APP_PUBLIC_STATUS } from "@/store/releases/releases.module";
// import AppMemberAutocomplete from "@/view/components/Common/AppMemberAutocomplete";
Validator.extend("categories_validation", {
  getMessage: (field) => {
    return `App ${field} should be less or equal to 3 `;
  },
  // Returns a boolean value
  validate: (value) => {
    return value.length <= 3;
  },
});
export default {
  mixins: [veeValidate],
  submitLoader: false,
  components: { ScreenShotUpload },
  data() {
    return {
      get,
      busy: false,
      modal: false,
      loading: false,
      selectedMember: [],
      panel: [0, 1, 2, 3],
      appImage: "",
      appImageUpload: false,
      public_access: false,
      loadingConfirm: false,
      readMore: false,
      form: {
        categories: [],
        description: "",
        settings: {
          global_instructions: "",
          store_links: {
            android: "",
            ios: "",
          },
          developer_contact: {
            name: "",
            email: "",
            website: "",
            terms: "",
            privacy: "",
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      info: "getAppInfo",
      getTeamName: "getTeamName",
      getCategoriesList: "getCategoriesList",
      getAppInfoLoading: "getAppInfoLoading",
    }),
  },
  watch: {
    info() {
      if (this.info) {
        this.public_access = this.info?.public;
        this.form = {
          categories: this.info?.categories,
          description: this.info?.description,
          settings: {
            global_instructions: this.info?.settings?.global_instructions || "",
            store_links: this.info?.settings?.store_links || {
              android: "",
              ios: "",
            },
            developer_contact: this.info?.settings?.developer_contact || {
              name: this.getTeamName,
              email: "",
              website: "",
              terms: "",
              privacy: "",
            },
          },
        };
      }
    },
  },
  mounted() {
    this.public_access = this.info?.public;
    if (!this.getAppInfoLoading && this.canManageApp) {
      this.getAppCategories();
      if (this.info) {
        this.form = {
          categories: this.info?.categories,
          description: this.info?.description,
          settings: {
            global_instructions: this.info?.settings?.global_instructions || "",
            store_links: this.info?.settings?.store_links || {
              android: "",
              ios: "",
            },
            developer_contact: this.info?.settings?.developer_contact || {
              name: this.getTeamName,
              email: "",
              website: "",
              terms: "",
              privacy: "",
            },
          },
        };
      }
    }
  },
  methods: {
    ...mapMutations({
      updateAppInfo: "updateAppInfo",
      updateAppShareStatus: "updateAppShareStatus",
    }),
    remove(item) {
      const index = this.form.categories.indexOf(item.id);
      if (index >= 0) this.form.categories.splice(index, 1);
      if (this.form.categories.length <= 3) {
        this.$validator.errors.clear();
      }
    },
    updatePublicAccess() {
      this.$store
        .dispatch(UPDATE_APP_PUBLIC_STATUS, {
          app_id: this.$route.params.app_id,
          enabled: this.public_access,
        })
        .then((response) => {
          this.notifyUserMessage({ message: response.message });
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.form[name];
      return $dirty ? !$error : null;
    },
    getAppCategories() {
      if (this.busy) return;
      this.busy = false;
      this.$store.dispatch(GET_APP_CATEGORIES).then(() => {
        this.busy = false;
      });
    },
    async updateSettings() {
      if (await this.validateAllFields()) {
        if (this.errors.any() || this.busy) {
          this.busy = false;
          return false;
        }
        this.busy = true;
        const name = this.form.name;
        this.form.id = this.$route.params.app_id;

        this.$store
          .dispatch(UPDATE_APP_SETTINGS, this.form)
          .then((response) => {
            this.busy = false;
            this.onClose && this.onClose();
            this.updateAppInfo(this.form);
            this.$emit("success", { name });
            this.notifyUserMessage({
              message: response.message || "Successfully updated",
              timeout: true,
            });
            this.$emit("close");
          })
          .catch((err) => {
            this.notifyErrorMessage(err.message || "Update failed");
            this.busy = false;
          });
      }
    },
    async onFileUpload(e) {
      if (this.loading) return;
      this.loading = true;
      const form = new FormData();
      form.append("image", e);
      try {
        const response = await this.$store.dispatch(UPDATE_IMAGE, {
          id: this.info.id,
          image: form,
        });
        this.notifyUserMessage({
          message: response.message || "App image successfully updated",
        });
        this.loading = false;
      } catch (err) {
        this.notifyErrorMessage(
          err.message || "Failed to update the app image"
        );
        this.loading = false;
      }
      this.loading = false;
    },
    onDeleteImage() {
      this.$store
        .dispatch(DELETE_IMAGE, this.info.id)
        .then((response) => {
          this.notifyUserMessage({
            message: response.message || "Screenshot successfully deleted",
          });
        })
        .catch((err) => {
          this.notifyErrorMessage(
            err.message || `Something went wrong, please try again`
          );
        });
    },
  },
};
</script>
