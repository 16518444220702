var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "transparent",
    attrs: {
      "tile": "",
      "flat": "",
      "disabled": _vm.busy
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('v-form', {
    ref: "ios",
    staticClass: "form col-12 mt-10",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return function () {
          return _vm.edit();
        }.apply(null, arguments);
      }
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|min:3|max:27',
      expression: "'required|min:3|max:27'"
    }],
    attrs: {
      "id": "editAppName",
      "label": "Name",
      "counter": "27",
      "placeholder": "App name",
      "filled": ""
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', _vm.veeValidate('Name', 'Name'), false)), _c('div', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "text-transform-none py-7 px-10 mt-10",
    attrs: {
      "id": "editAppSubmit",
      "loading": _vm.busy,
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark,
      "disabled": _vm.errors.any() || _vm.busy || !_vm.form.name
    },
    on: {
      "click": _vm.edit
    }
  }, [_c('span', {
    staticClass: "font14"
  }, [_vm._v(" Save ")])])], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }