<template>
  <v-row>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="auto" md="6">
          <div class="titleFont">
            Screenshots
            <VueTooltip
              icon-color="grey"
              color="primary"
              text-class="white--text"
              :v-html="
                'App screenshots will be displayed in the public install page. Check share page for more info. <br /><br />The actual image size can be found in the install page. We are going to enhance the screenshots, we welcome any feedfback!'
              "
            />
          </div>
          <div class="smallFont mb-6">
            Images will be resized to 250x500 pixels from the center
          </div>
          <div class="mb-6">
            <v-btn
              v-if="!getAppScreenShotList.length"
              @click="openUploadModal"
              :loading="loading"
              color="primary"
              :outlined="$vuetify.theme.dark"
              class="text-transform-none"
            >
              <v-icon>mdi-image</v-icon>
              Upload screenshot
            </v-btn>
          </div>
        </v-col>
        <v-col class="text-right" cols="auto" md="6">
          <v-btn
            v-if="getAppScreenShotList.length"
            @click="openUploadModal"
            :loading="loading"
            color="primary"
            :outlined="$vuetify.theme.dark"
            class="text-transform-none"
          >
            <v-icon>mdi-image</v-icon>
            Upload screenshot
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="getAppScreenShotList.length">
          <v-row>
            <v-col cols="12">
              <div class="screenShotContainer" id="screenShotBox">
                <!--                <draggable class="screenShotContainer"  @start="onDragStart" @end="onDragEnd" v-model="screenshotList">-->
                <div
                  v-for="(item, index) in getAppScreenShotList"
                  :key="index"
                  style="display: flex"
                >
                  <v-hover v-slot="{ hover }">
                    <v-sheet class="imageBorder mr-2">
                      <v-img
                        style="border-radius: 20px"
                        :src="item.url"
                        width="250"
                        cover
                        height="500"
                      >
                        <v-icon
                          v-if="hover"
                          class="float-right ma-1"
                          color="danger"
                          @click="removeImage(item, index)"
                          v-text="'mdi-close'"
                        ></v-icon>
                        <v-chip
                          v-else
                          class="float-right ma-1"
                          color="primary"
                          small
                        >
                          {{ index + 1 }}
                        </v-chip>
                      </v-img>
                    </v-sheet>
                  </v-hover>
                </div>
                <!--                </draggable>-->
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <Modal v-model="openRemoveImageModal" @close="openRemoveImageModal = false">
      <template #card>
        <v-card>
          <v-card-title class="font-size-h3 bold-text" cols="12">
            Delete screenshot
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="bodyFont message my-4" cols="12">
            Are you sure you want to delete this image?
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions cols="12">
            <div>No undo for this action</div>
            <v-spacer />
            <v-btn
              text
              class="text-transform-none"
              color="primary"
              :disabled="deleteLoading"
              @click="openRemoveImageModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              depressed
              class="text-transform-none"
              color="primary"
              :outlined="$vuetify.theme.dark"
              :loading="deleteLoading"
              @click="deleteImage"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </Modal>
    <Modal
      :width="$vuetify.breakpoint.mdAndUp ? '650' : ''"
      v-model="appImageUpload"
      @close="appImageUpload = false"
    >
      <template #card>
        <image-upload
          v-if="appImageUpload"
          v-model="appImage"
          title="Upload app screenshot"
          hide-overlay
          :view-port="{ width: 250, height: 500 }"
          @close="appImageUpload = false"
          @cropped-image="onFileSelected"
        ></image-upload>
      </template>
    </Modal>
  </v-row>
</template>

<script>
import {
  DELETE_APP_SCREENSHOT,
  GET_APP_SCREENSHOT,
  UPLOAD_APP_SCREENSHOT,
} from "@/store/apps/app.module";
import { mapGetters, mapMutations } from "vuex";
import ImageUpload from "@/view/components/Common/ImageUpload";
// import UppyUpload from "@/view/components/Common/UppyUpload";
// import draggable from "vuedraggable";

export default {
  props: {
    screenshotList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: { ImageUpload },
  data() {
    return {
      fileNames: [],
      totalFileSize: [],
      appImageUpload: false,
      appImage: "",
      screenshots: [],
      deleteLoading: false,
      removeIndex: "",
      removeId: "",
      loading: false,
      openRemoveImageModal: false,
    };
  },
  computed: {
    ...mapGetters({
      getAppScreenShotList: "getAppScreenShotList",
      appInfo: "getAppInfo",
    }),
  },
  created() {
    this.getScreenshotList();
  },
  methods: {
    ...mapMutations({
      setAppScreenshot: "setAppScreenshot",
      removeAppScreenshot: "removeAppScreenshot",
    }),
    // onDragStart(event) {
    // },
    // onDragEnd(event) {
    // },
    getScreenshotList() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_APP_SCREENSHOT, { app_id: this.$route.params.app_id })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setUploadedImage(data) {
      this.appImageUpload = false;
      this.setAppScreenshot({ id: data.id, url: data.url });
      this.$nextTick(() => {
        this.slideRight();
      });
    },
    onFileSelected(event) {
      if (this.screenshots.length < 8) {
        if (this.loading) return;
        this.loading = true;
        if (event.size > 8000000) {
          this.loading = false;
          this.notifyErrorMessage("Image size should not exceed 8 MB");
          return;
        }
        let form = new FormData();
        form.append("image", event);
        this.$store
          .dispatch(UPLOAD_APP_SCREENSHOT, {
            app_id: this.$route.params.app_id,
            form: form,
          })
          .then((response) => {
            this.loading = false;
            this.appImageUpload = false;
            this.setAppScreenshot({ id: response.id, url: response.url });
          })
          .catch((err) => {
            this.loading = false;
            this.notifyErrorMessage(err.message);
          });
      } else {
        this.notifyErrorMessage("Max of 8 screenshots in total for each app");
      }
    },
    removeImage(image, imageIndex) {
      this.openRemoveImageModal = true;
      this.removeIndex = imageIndex;
      this.removeId = image.id;
    },
    openUploadModal() {
      if (this.getAppScreenShotList.length >= 8) {
        this.notifyErrorMessage("You can only upload 8 screenshots");
      } else {
        this.appImageUpload = true;
      }
    },
    slideRight() {
      let box = document.getElementById("screenShotBox");
      box.scrollLeft = box.scrollWidth;
    },
    deleteImage() {
      if (this.deleteLoading) return;
      this.deleteLoading = true;
      // this.attachments.splice(this.removeIndex, 1);
      this.totalFileSize.splice(this.removeIndex, 1);
      this.$store
        .dispatch(DELETE_APP_SCREENSHOT, {
          app_id: this.$route.params.app_id,
          screenshot_id: this.removeId,
        })
        .then((response) => {
          this.deleteLoading = false;
          this.openRemoveImageModal = false;
          this.removeAppScreenshot(this.removeIndex);
          this.notifyUserMessage({ message: response.message });
        })
        .catch((err) => {
          this.deleteLoading = false;
          this.notifyErrorMessage(err.message);
        });
    },
  },
};
</script>

<style scoped>
.imageBorder {
  border-radius: 22px;
  margin-right: 24px;
  border: 0.5px solid rgba(0, 0, 0, 0.08);
}
.dottedBoarder {
  border: 1px dashed #878787;
  box-sizing: border-box;
  border-radius: 16px;
}
.screenShotContainer {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  white-space: nowrap;
}
</style>
