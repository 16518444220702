var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_vm.canManageInvite ? _c('v-col', {
    staticClass: "d-flex"
  }, [_c('v-spacer'), _c('InviteMembersPanel', {
    on: {
      "success": _vm.loadInvitesList
    }
  })], 1) : _vm._e(), !_vm.canViewInvite ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('no-data', {
    attrs: {
      "first-text": "Ops!",
      "second-text": "You don't have permission to view invites"
    }
  })], 1) : _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "members mt-4"
  }, [_vm.loading ? _c('v-skeleton-loader', {
    staticClass: "transparent",
    attrs: {
      "type": "table-thead, table-tbody"
    }
  }) : _vm.noData ? _c('div', {
    staticClass: "no-data-found"
  }, [_c('inline-svg', {
    staticClass: "mt-6 svg-icon empty_icon",
    attrs: {
      "src": "/media/placeholders/no-app-members".concat(_vm.$vuetify.theme.dark ? '-dark' : '', ".svg")
    }
  }), _c('p', {
    staticClass: "mt-4 bold-text bodyFont darkGrey--text text-center"
  }, [_vm._v(" No Invites Yet! ")]), _c('p', {
    staticClass: "mt-4 mb-5 bodyFont darkGrey--text text-center"
  }, [_vm._v(" Boost collaboration by inviting team members. They can help "), _c('strong', [_vm._v("manage")]), _vm._v(" your app or "), _c('strong', [_vm._v("test")]), _vm._v(" it internally via our App. Start inviting now! ")])], 1) : _c('DatatableWrapper', {
    attrs: {
      "pagination": _vm.pagination,
      "loading": _vm.loading
    },
    on: {
      "onChange": _vm.handleOnChange
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.getTeamInvitesList,
      "hide-default-footer": "",
      "mobile-breakpoint": "0"
    },
    scopedSlots: _vm._u([{
      key: "item.idAndEmail",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('b', [_vm._v(" " + _vm._s(item.email ? item.email : item.id) + " ")]), _c('div', [_vm._v(" " + _vm._s("By" + " " + item.invited_by.name) + " ")])];
      }
    }, {
      key: "item.role",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.role.name) + " ")];
      }
    }, {
      key: "item.totalApps",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.openAppsModal(item.apps);
            }
          }
        }, [_c('b', [_vm._v(" " + _vm._s(item.apps.length) + " ")])])];
      }
    }, _vm.urlList ? {
      key: "item.total_joins",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item && item.meta ? item.meta.total_joins || "-" : "-") + " ")];
      }
    } : null, {
      key: "item.status",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.status == 1 ? "Active" : "In active") + " ")];
      }
    }, {
      key: "item.lastUpdate",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('vue-hoverable-date', {
          attrs: {
            "date": parseInt(item.created_at)
          }
        })];
      }
    }, {
      key: "item.action",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm.canManageInvite ? _c('action-dropdown', {
          scopedSlots: _vm._u([{
            key: "list",
            fn: function fn() {
              return [_c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.setEdit(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("edit")]), _vm._v(" edit ")], 1), _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.copyText(item.id);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                },
                domProps: {
                  "textContent": _vm._s('mdi-content-copy')
                }
              }), _vm._v(" Copy URL ")], 1), _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.openDelete(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("cancel")]), _vm._v(" Withdraw invite ")], 1)];
            },
            proxy: true
          }], null, true)
        }) : _vm._e()];
      }
    }, {
      key: "footer.page-text",
      fn: function fn() {
        return [_c('div', [_c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "darkGrey",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('refresh');
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": _vm.$vuetify.theme.dark ? '' : 'grey',
            "left": ""
          },
          domProps: {
            "textContent": _vm._s('mdi-reload')
          }
        }), _vm._v(" Reload ")], 1), _vm._v(" Showing "), _c('b', [_vm._v(_vm._s(_vm.totalItems ? _vm.offset : 0) + " to " + _vm._s(_vm.offset + (_vm.data.length - 1)))]), _vm._v(" of "), _c('b', [_vm._v(_vm._s(_vm.totalItems))])], 1)];
      },
      proxy: true
    }], null, true)
  })], 1), _c('SidePanel', {
    attrs: {
      "loading": _vm.busy
    },
    on: {
      "close": function close($event) {
        _vm.drawer = false;
      }
    },
    model: {
      value: _vm.drawer,
      callback: function callback($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_vm.drawer ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "display-1 justify-center mb-8 mt-n3"
  }, [_vm._v(" Edit ")]), _c('div', {
    staticClass: "mt-4"
  }, [_c('v-row', {
    staticClass: "mt-4"
  }, [!_vm.$route.params.app_id ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('AppAutoComplete', {
    staticClass: "mb-4",
    attrs: {
      "initial-data": _vm.initailData,
      "multiple": ""
    },
    model: {
      value: _vm.selectedapps,
      callback: function callback($$v) {
        _vm.selectedapps = $$v;
      },
      expression: "selectedapps"
    }
  })], 1) : _c('v-col', {
    staticClass: "semi-bold bodyFont mb-3",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.appInfo.name) + " ")])], 1)], 1), _vm.drawer ? _c('Roles', {
    attrs: {
      "permission": _vm.selectedPermission,
      "update-roles": _vm.updateroles
    },
    on: {
      "updateRoles": _vm.saveRoles,
      "updateTheRole": _vm.saveTheRole
    }
  }) : _vm._e(), _c('div', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "text-transform-none py-7 px-15 mx-auto mt-13",
    attrs: {
      "loading": _vm.busy,
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "x-large": ""
    },
    on: {
      "click": _vm.updateInvite
    }
  }, [_c('span', {
    staticClass: "font14"
  }, [_vm._v(" Save ")])])], 1)], 1) : _vm._e()], 1), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.modal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Delete invite")]), _c('v-divider'), _c('v-card-text', {
          staticClass: "my-4 message"
        }, [_c('p', [_vm._v("Are you sure you want to delete this invite?")])]), _c('v-divider'), _c('v-card-actions', {
          staticClass: "py-3"
        }, [_c('div', [_vm._v("No undo for this action")]), _c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": _vm.onCancel
          }
        }, [_vm._v("No ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "loading": _vm.loading,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary"
          },
          on: {
            "click": _vm.onConfirm
          }
        }, [_vm._v("Yes ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.modal,
      callback: function callback($$v) {
        _vm.modal = $$v;
      },
      expression: "modal"
    }
  }), _c('v-snackbar', {
    attrs: {
      "color": _vm.notify.color
    },
    model: {
      value: _vm.notify.show,
      callback: function callback($$v) {
        _vm.$set(_vm.notify, "show", $$v);
      },
      expression: "notify.show"
    }
  }, [_vm._v(" " + _vm._s(_vm.notify.message) + " ")]), _c('Modal', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "560"
    },
    scopedSlots: _vm._u([_vm.showAppModal ? {
      key: "message",
      fn: function fn() {
        return [_c('v-card-title', [_c('div', {
          staticClass: "mr-1 mb-4"
        }, [_vm._v(" Apps ("), _c('b', [_vm._v(_vm._s(_vm.modalsApps.length))]), _vm._v(") ")]), _c('v-spacer')], 1), _c('v-card-text', {
          staticClass: "pb-3"
        }, [!_vm.searchedModalsApp.length ? _c('div', {
          staticClass: "text-center bold-text title"
        }, [_vm._v(" " + _vm._s(!_vm.search ? "No apps added in this invite" : "No app match " + _vm.search + " keys") + " ")]) : _c('div', _vm._l(_vm.searchedModalsApp, function (app, index) {
          return _c('v-card', {
            key: index,
            staticClass: "mb-2 pa-2",
            attrs: {
              "outlined": ""
            }
          }, [_c('v-row', {
            attrs: {
              "align": "center"
            }
          }, [_c('v-col', [_c('div', {
            staticClass: "d-flex align-center"
          }, [_c('div', {
            staticClass: "flex-shrink-0 mr-4"
          }, [_c('v-avatar', {
            attrs: {
              "size": "50"
            }
          }, [_c('img', {
            attrs: {
              "src": _vm.getAppImage(app)
            },
            on: {
              "error": _vm.replaceDefaultUserImage
            }
          })])], 1), _c('div', {}, [_vm._v(_vm._s(app.name))])])]), _c('v-col', {
            class: {
              'text-center': _vm.$vuetify.breakpoint.mdAndDown
            },
            attrs: {
              "cols": "6",
              "md": "3"
            }
          }, [app.platforms.includes('android') ? _c('PlatformIcon', {
            attrs: {
              "color": app.platforms.includes('android') ? 'grey' : 'primary',
              "android": ""
            }
          }) : _vm._e(), _c('span', {
            staticClass: "mx-2"
          }), app.platforms.includes('ios') ? _c('PlatformIcon', {
            attrs: {
              "color": app.platforms.includes('ios') ? 'grey' : 'primary',
              "apple": ""
            }
          }) : _vm._e()], 1)], 1)], 1);
        }), 1)])];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.showAppModal,
      callback: function callback($$v) {
        _vm.showAppModal = $$v;
      },
      expression: "showAppModal"
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }