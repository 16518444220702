<template>
    <div style="white-space: pre-line">
        <div v-if="readMoreDesc">
            {{ text }}
            <div v-if="!controlOutSide && !small" class="primary--text body-1 cursor-pointer" @click="readMoreDesc = !readMoreDesc">
                {{ "SHOW LESS" }}
            </div>
        </div>
        <div v-if="!readMoreDesc">
            {{ text ? text.substring(0, length) : "" }}
            <span v-if="text.length > length" class="text-muted">
                {{ text ? text.substring(length, length) : "" }}
            </span>
            <span v-if="text && text.length > length && !controlOutSide && !small" class="primary--text body-1 text-center cursor-pointer"
                @click="readMoreDesc = !readMoreDesc">{{ "SHOW MORE" }}</span>
            <span v-if="text && text.length > length && !controlOutSide && small" class="primary--text bold-text text-left cursor-pointer"
                @click="$emit('readMore')">{{ "..." }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        controlOutSide: {
            type: Boolean,
            default: false,
        },
        readMore: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: "",
        },
        small: {
            type: Boolean,
            default: false,
        },
        length: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            readMoreDesc: false,
        };
    },
    created() {
        if (this.controlOutSide) {
            this.readMoreDesc = this.readMore;
        }
    },
    watch: {
        readMore(val) {
            this.readMoreDesc = val;
        },
    },
};
</script>
