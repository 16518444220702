<template>
  <v-row>
    <v-col class="text-center" cols="12">
      <div class="titleFont">
        Stats for
        {{
          fromSpecificLink
            ? "the selected public install link"
            : "all public install links"
        }}
      </div>
    </v-col>
    <v-col v-if="fromSpecificLink" cols="12">
      <v-row>
        <v-col cols="12" md="6">
          <div class="text-right">
            <chart-date-selector
              @change="installChartYear = $event"
            ></chart-date-selector>
          </div>
          <div v-if="loading">
            <v-skeleton-loader
              type="list-item-avatar, divider, list-item-three-line, card-heading"
            ></v-skeleton-loader>
          </div>
          <apexchart
            v-if="!loading"
            :options="{
              ...barChartOptions,
              title: {
                text: 'Installs',
                align: 'center',
                style: {
                  fontSize: '16px',
                },
              },
            }"
            :series="installsChartData"
            display-legend
            type="bar"
          >
          </apexchart>
        </v-col>
        <v-col cols="12" md="6">
          <div class="text-right">
            <chart-date-selector
              @change="viewsChartYear = $event"
            ></chart-date-selector>
          </div>
          <div v-if="loading">
            <v-skeleton-loader
              type="list-item-avatar, divider, list-item-three-line, card-heading"
            ></v-skeleton-loader>
          </div>
          <apexchart
            v-if="!loading"
            :options="{
              ...barChartOptions,
              title: {
                text: 'Views',
                align: 'center',
                style: {
                  fontSize: '16px',
                },
              },
            }"
            :series="viewsChartData"
            display-legend
            type="bar"
          >
          </apexchart>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-tabs v-model="tab">
        <v-tab>
          <span :class="{ 'darkGrey--text': $vuetify.theme.dark }">Views</span>
        </v-tab>
        <v-tab>
          <span :class="{ 'darkGrey--text': $vuetify.theme.dark }">
            Installs
          </span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item>
          <share-views
            :fromSpecificLink="fromSpecificLink"
            :share-id="shareId"
          ></share-views>
        </v-tab-item>
        <v-tab-item>
          <install-list
            :share-id="shareId"
          ></install-list>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import { GET_SHARE_CHART_LIST } from "@/store/releases/releases.module";
import ShareViews from "@/view/components/Releases/ShareViews";
import ChartDateSelector from "@/view/components/Common/ChartDateSelector";
import { numberWithCommas } from "@/core/services/helper.service";
import InstallList from "@/view/components/App/Dashboard/InstallList";

const defaultYear = new Date().getFullYear();
const barChartOptions = {
  noData: {
    text: "No data available", // This is the default message
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "14px",
      fontFamily: undefined,
    },
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    custom: function ({ seriesIndex, w, dataPointIndex }) {
      // let labels =
      let labels = w.globals.initialConfig.xaxis.categories[dataPointIndex];
      let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
      // const start = dayjs(data.x * 1000).format= ('hh:mm:ss a')
      return `<div class="">
                 <div class="mt-1 mx-2">
                    ${labels}
                  </div>
                  <hr class="my-1"/>
                  <div class="mx-2 mb-1">
                   ${numberWithCommas(data)}
                  </div>
                 </div>`;
    },
  },
  colors: ["rgb(144, 164, 174)", "rgb(255, 183, 77)"],
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
};

export default {
  props: {
    shareId: {
      type: String,
      default: "",
    },
    shareUid: {
      type: String,
      default: "",
    },
    fromSpecificLink: {
      type: Boolean,
      default: false,
    },
    selectedTab: {
      type: Number,
      default: 0,
    },
  },
  components: {
    InstallList,
    ChartDateSelector,
    ShareViews,
  },
  data() {
    return {
      loading: false,
      tab: 0,
      busy: false,
      tooltip: {
        custom: function ({ seriesIndex, w, dataPointIndex }) {
          // let labels =
          let labels = w.globals.initialConfig.xaxis.categories[dataPointIndex];
          let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          // const start = dayjs(data.x * 1000).format= ('hh:mm:ss a')
          return `<div class="">
                 <div class="mt-1 mx-2">
                    ${labels}
                  </div>
                  <hr class="my-1"/>
                  <div class="mx-2 mb-1">
                   ${numberWithCommas(data)}
                  </div>
                 </div>`;
        },
      },
      installChartYear: defaultYear,
      viewsChartYear: defaultYear,
      barChartOptions,
      installsChartData: [
        {
          name: "Installs",
          data: [],
        },
      ],
      viewsChartData: [
        {
          name: "Views",
          data: [],
        },
      ],
    };
  },
  watch: {
    installChartYear() {
      this.getViewsAndInstallChart();
    },
    viewsChartYear() {
      this.getViewsAndInstallChart();
    },
  },
  created() {
    this.getViewsAndInstallChart();
    if (this.selectedTab) {
      this.tab = this.selectedTab;
    }
  },
  methods: {
    getViewsAndInstallChart() {
      if (this.loading) return;
      this.loading = true;
      let payload = {
        app_id: this.$route.params.app_id,
        link_id: this.shareId,
        type: "views_installs",
        year: this.installChartYear,
      };
      this.$store
        .dispatch(GET_SHARE_CHART_LIST, payload)
        .then((data) => {
          this.setChartData(data.results ? data.results : {});
          this.$nextTick(() => {
            this.loading = false;
          });
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    setChartData(results) {
      if (results && results.installs.length) {
        this.installsChartData = [
          { data: this.populateChartData(results.installs) },
        ];
      }
      if (results && results.views.length) {
        this.viewsChartData = [{ data: this.populateChartData(results.views) }];
      }
    },
    populateChartData(items) {
      const result = Array(12).fill(0);

      items.forEach((item) => {
        let month = new Date(item.to_date).getMonth();
        result[month] = parseInt(item.count);
      });

      return result;
    },
  },
};
</script>
