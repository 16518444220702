<template>
  <div>
    <v-card class="transparent" tile flat :disabled="busy">
      <div class="row">
        <v-form
          ref="ios"
          class="form col-12 mt-10"
          @submit.prevent="() => edit()"
        >
          <v-text-field
            id="editAppName"
            label="Name"
            v-model="form.name"
            counter="27"
            placeholder="App name"
            v-validate="'required|min:3|max:27'"
            v-bind="veeValidate('Name', 'Name')"
            filled
          />
          <div class="text-center">
            <v-btn
              id="editAppSubmit"
              :loading="busy"
              color="primary"
              :outlined="$vuetify.theme.dark"
              :disabled="errors.any() || busy || !form.name"
              class="text-transform-none py-7 px-10 mt-10"
              @click="edit"
            >
              <span class="font14"> Save </span>
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-card>
  </div>
</template>

<style scoped>
.app-logo img {
  height: 100px;
  width: 100px;
}

.app-logo input {
  height: 0px;
  width: 0px;
}

.logo-edit {
  right: -8px;
  top: -8px;
}
</style>

<script>
import { get } from "@/core/services/helper.service";
import veeValidate from "@/mixins/veeValidate";
import { UPDATE } from "@/store/apps/app.module";
import { mapState } from "vuex";

export default {
  name: "edit-app",
  props: ["app", "onClose"],
  mixins: [veeValidate],
  submitLoader: false,
  data() {
    return {
      get,
      busy: false,
      loading: false,
      form: {
        name: "",
        description: "",
        public: false,
      },
    };
  },
  watch: {
    app: function(value) {
      this.form.name = get(value, "name", "");
    },
  },
  computed: {
    ...mapState({
      info: (state) => state.app.info,
    }),
  },
  mounted() {
    this.form.name = this.app.name;
    this.form.description = this.app.description;
    this.form.public = this.app.public;
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        name: "",
        description: "",
        public: false,
      };
      // this.$nextTick(() => {
      //   this.$reset();
      // });
    },
    async edit() {
      if (this.errors.any() || this.busy) {
        this.busy = false;
        return false;
      }

      this.busy = true;

      const name = this.form.name;

      try {
        const response = await this.$store.dispatch(UPDATE, {
          id: this.app.id,
          name,
        });
        this.busy = false;
        this.onClose && this.onClose();
        this.$emit("success", { name });
        this.notifyUserMessage({
          message: response.message || "App details updated successfully",
          timeout: true,
        });
        this.$emit("close");
      } catch (err) {
        this.notifyErrorMessage(err.message || "Update failed");
      }
      this.busy = false;
    },
  },
};
</script>
