var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_c('div', {
    staticClass: "titleFont semibold"
  }, [_vm._v("iOS App Sharing")])]), _c('v-card-text', {
    staticClass: "font-14"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Share iOS apps within your team via our "), _c('a', {
    attrs: {
      "href": "https://apps.apple.com/us/app/testapp-io/id1518972541",
      "target": "_blank"
    }
  }, [_vm._v("iOS app")]), _vm._v(", in line with Apple's Ad-hoc build regulations. ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "semi-bold"
  }, [_vm._v("Enterprise builds:")]), _c('ul', [_c('li', [_vm._v("Public link sharing enabled.")]), _c('li', [_vm._v("No UDID collection needed.")])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "semi-bold"
  }, [_vm._v("Ad-Hoc builds:")]), _c('ul', [_c('li', [_vm._v(" Install directly via our "), _c('a', {
    attrs: {
      "href": "https://apps.apple.com/us/app/testapp-io/id1518972541",
      "target": "_blank"
    }
  }, [_vm._v("iOS app")]), _vm._v(". ")]), _c('li', [_vm._v("Meets Apple's UDID guidelines.")])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "semi-bold"
  }, [_vm._v("All builds:")]), _c('ul', [_c('li', [_vm._v(" Share your app with a TestFlight link with Android in a "), _c('span', {
    staticClass: "primary--text cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'app-settings-share',
          params: {
            app_id: _vm.$route.params.app_id
          }
        });
      }
    }
  }, [_vm._v("single page")]), _vm._v(". ")])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "semi-bold"
  }, [_vm._v("Why the update?")]), _c('ul', [_c('li', [_vm._v("To resolve UDID management issues for Ad-Hoc builds.")]), _c('li', [_vm._v("To align with Apple's distribution policies.")])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "semi-bold"
  }, [_vm._v("Take Action:")]), _c('ul', [_c('li', [_vm._v(" Use '"), _c('span', {
    staticClass: "primary--text cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'invites'
        });
      }
    }
  }, [_vm._v("Invite by URL")]), _vm._v("' to add Guests to apps: ")]), _c('ul', [_c('li', [_vm._v(" Seamless app installation from "), _c('a', {
    attrs: {
      "href": "https://apps.apple.com/us/app/testapp-io/id1518972541",
      "target": "_blank"
    }
  }, [_vm._v("our app")]), _vm._v(". ")]), _c('li', [_vm._v("Notifications on updates.")]), _c('li', [_vm._v("Feedback on specific releases.")])])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "semi-bold"
  }, [_vm._v("Benefits of the Guest Role:")]), _c('ul', [_c('li', [_vm._v("Invite Guests for free, without affecting your team cap.")]), _c('li', [_vm._v(" Guests can interact with app releases while team privacy is maintained. ")]), _c('li', [_vm._v("Developer's name visible for transparency.")])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" For support, please "), _c('span', {
    staticClass: "primary--text cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.openIntercomChat(_vm.contactMessage);
      }
    }
  }, [_vm._v("contact our help team")]), _vm._v(". ")]), _vm.$route.name !== 'app-settings-share' ? _c('v-col', {
    attrs: {
      "cols": "12",
      "align": "center"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'app-settings-share',
          params: {
            app_id: _vm.$route.params.app_id
          }
        });
      }
    }
  }, [_vm._v(" Manage share links ")])], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }