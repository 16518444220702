var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.noRolesData ? _c('invite-members-loader', {
    attrs: {
      "roles-only": ""
    }
  }) : _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.disableMemberRole ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-card', _vm._g({
          staticClass: "pa-2",
          attrs: {
            "outlined": "",
            "height": "100%"
          }
        }, on), [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('div', {
          staticClass: "bodyFont"
        }, [_vm._v("Members Role")])]), _c('v-col', {
          staticClass: "text-right"
        }, [_c('v-icon', {
          attrs: {
            "color": _vm.selectedType === 'memberRole' ? 'success' : 'info'
          }
        }, [_vm._v(" mdi-circle-outline ")])], 1)], 1)], 1)];
      }
    }], null, false, 3087988035)
  }, [_c('span', [_vm._v(" You cannot switch to this role. If you wish to change a user's role to a member, "), _c('br'), _vm._v(" you must first remove the guest and then invite them again with a member role. ")])]) : _c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "outlined": "",
      "id": "memberRoleTab",
      "height": "100%"
    },
    on: {
      "click": function click($event) {
        _vm.selectedType = 'memberRole';
      }
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "bodyFont"
  }, [_vm._v("Members Role")])]), _c('v-col', {
    staticClass: "text-right"
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.selectedType === 'memberRole' ? 'success' : 'info'
    },
    domProps: {
      "textContent": _vm._s(_vm.selectedType === 'memberRole' ? 'mdi-check-circle' : 'mdi-circle-outline')
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.disableGuestRole ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('v-card', _vm._g({
          staticClass: "pa-2 cursor-pointer",
          attrs: {
            "outlined": "",
            "height": "100%"
          }
        }, on), [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('div', {
          staticClass: "bodyFont"
        }, [_vm._v("Guest Role")])]), _c('v-col', {
          staticClass: "text-right"
        }, [_c('v-icon', {
          attrs: {
            "color": _vm.selectedType === 'guestRole' ? 'success' : 'info'
          },
          domProps: {
            "textContent": _vm._s(_vm.selectedType === 'guestRole' ? 'mdi-check-circle' : 'mdi-circle-outline')
          }
        })], 1)], 1)], 1)];
      }
    }], null, false, 774109580)
  }, [_c('span', [_vm._v(" You cannot switch to this role. If you wish to change a user's role to a guest, "), _c('br'), _vm._v(" you must first remove the member and then invite them again with a guest role. ")])]) : _c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "id": "guestRoleTab",
      "outlined": "",
      "height": "100%"
    },
    on: {
      "click": function click($event) {
        _vm.selectedType = 'guestRole';
      }
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "bodyFont"
  }, [_vm._v("Guest Role")])]), _c('v-col', {
    staticClass: "text-right"
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.selectedType === 'guestRole' ? 'success' : 'info'
    },
    domProps: {
      "textContent": _vm._s(_vm.selectedType === 'guestRole' ? 'mdi-check-circle' : 'mdi-circle-outline')
    }
  })], 1)], 1)], 1)], 1)], 1), _vm.selectedType === 'memberRole' ? [_c('v-radio-group', {
    attrs: {
      "mandatory": ""
    },
    model: {
      value: _vm.Roles,
      callback: function callback($$v) {
        _vm.Roles = $$v;
      },
      expression: "Roles"
    }
  }, [_vm._l(_vm.roles, function (role) {
    return [_vm.hideCustom(role) ? _c('v-radio', {
      key: role.id,
      attrs: {
        "id": role.name,
        "value": role.id
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function fn() {
          return [_c('span', [_c('span', {
            staticClass: "mr-2"
          }, [_vm._v(_vm._s(role.name))]), _c('VueTooltip', {
            attrs: {
              "text": role.description
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }) : _vm._e()];
  })], 2), _c('v-row', {
    attrs: {
      "id": "memberDescription"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Manage ")]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" View ")]), _vm.activeRole.permissions ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, _vm._l(Object.keys(_vm.activeRole.permissions), function (first, firstIndex) {
    var _vm$getDescriptions$f, _vm$getDescriptions$f2;
    return _c('v-row', {
      key: firstIndex,
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "text-capitalize py-0",
      attrs: {
        "cols": "6"
      }
    }, [_c('div', [_c('div', [_c('span', {
      staticClass: "mr-2"
    }, [_vm._v(" " + _vm._s(first) + " ")]), (_vm$getDescriptions$f = _vm.getDescriptions[first]) !== null && _vm$getDescriptions$f !== void 0 && _vm$getDescriptions$f.description ? _c('VueTooltip', {
      attrs: {
        "v-html": _vm.getDescriptions ? (_vm$getDescriptions$f2 = _vm.getDescriptions[first]) === null || _vm$getDescriptions$f2 === void 0 ? void 0 : _vm$getDescriptions$f2.description : ''
      }
    }) : _vm._e()], 1)])]), _vm.activeRole.permissions[first] ? _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "6"
      }
    }, [_c('v-row', [_vm._l(Object.keys(_vm.activeRole.permissions[first]).reverse(), function (second, secondIndex) {
      return [second === 'manage' ? _c('v-col', {
        key: secondIndex,
        staticClass: "text-right",
        attrs: {
          "cols": "6"
        }
      }, [_c('v-checkbox', {
        attrs: {
          "disabled": !_vm.activeRole.editable,
          "hide-details": ""
        },
        on: {
          "change": function change($event) {
            return _vm.setPermission(first, second);
          }
        },
        model: {
          value: _vm.activeRole.permissions[first][second],
          callback: function callback($$v) {
            _vm.$set(_vm.activeRole.permissions[first], second, $$v);
          },
          expression: "activeRole.permissions[first][second]"
        }
      })], 1) : _vm._e(), second === 'view' ? _c('v-col', {
        key: secondIndex,
        staticClass: "text-right",
        attrs: {
          "cols": "6"
        }
      }, [_c('v-checkbox', {
        attrs: {
          "disabled": !_vm.activeRole.editable,
          "readonly": _vm.activeRole.permissions[first]['manage'],
          "hide-details": ""
        },
        model: {
          value: _vm.activeRole.permissions[first][second],
          callback: function callback($$v) {
            _vm.$set(_vm.activeRole.permissions[first], second, $$v);
          },
          expression: "activeRole.permissions[first][second]"
        }
      })], 1) : _vm._e()];
    })], 2)], 1) : _vm._e()], 1);
  }), 1) : _vm._e()], 1)] : _vm._e(), _vm.selectedType === 'guestRole' ? [_c('v-row', {
    attrs: {
      "id": "guestDescription"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.guestRoleDescription)
    }
  })], 1)] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }