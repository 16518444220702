var render = function render(){
  var _vm$selectedData, _vm$selectedData2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('v-btn', {
    staticClass: "text-transform-none mr-2",
    class: _vm.$vuetify.breakpoint.smAndDown ? 'mt-3' : '',
    attrs: {
      "id": "goToSettingBtn",
      "disabled": !_vm.canManageApp,
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'app-settings-setting'
        });
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-link')
    }
  }), _vm._v(" Settings ")], 1), _c('v-btn', {
    staticClass: "text-transform-none",
    class: _vm.$vuetify.breakpoint.smAndDown ? 'mt-3' : '',
    attrs: {
      "id": "createNewLinkBtn",
      "disabled": !_vm.canManageApp,
      "loading": _vm.checkLoading,
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "depressed": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openSetting();
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-plus')
    }
  }), _vm._v(" New link ")], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('DatatableWrapper', {
    attrs: {
      "loading": _vm.shareLoading,
      "pagination": _vm.pagination,
      "on-change": _vm.onChange
    }
  }, [_vm.shareLoading ? _c('v-skeleton-loader', {
    staticClass: "transparent",
    attrs: {
      "type": "table-thead, table-tbody"
    }
  }) : _vm._e(), !_vm.shareLoading && _vm.canManageApp ? _c('v-data-table', {
    attrs: {
      "expanded": _vm.expanded,
      "headers": _vm.headers,
      "items": _vm.getShareableLinks || [],
      "disable-sort": "",
      "hide-default-footer": "",
      "mobile-breakpoint": "0"
    },
    on: {
      "click:row": _vm.expendRow
    },
    scopedSlots: _vm._u([{
      key: "item.android",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.android && !_vm.isEmpty(item.android) ? _c('div', [_c('release-chip', {
          attrs: {
            "release": item.android
          }
        })], 1) : _c('div', [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-icon', {
                attrs: {
                  "left": "",
                  "small": ""
                },
                domProps: {
                  "textContent": _vm._s('mdi-android')
                }
              }), _c('span', _vm._g({}, on), [_vm._v(" - ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" No release selected ")])])], 1)];
      }
    }, {
      key: "item.ios",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.ios.testflight ? _c('div', {
          staticStyle: {
            "display": "flex"
          }
        }, [_c('release-chip', {
          attrs: {
            "show-test-flight": "",
            "test-flight-link": item.ios.testflight
          }
        })], 1) : item.ios && !_vm.isEmpty(item.ios) ? _c('div', [_c('release-chip', {
          attrs: {
            "release": Object.assign({}, item.ios, {
              platform: 'ios'
            })
          }
        })], 1) : _c('div', [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on;
              return [_c('v-icon', {
                attrs: {
                  "left": "",
                  "small": ""
                },
                domProps: {
                  "textContent": _vm._s('mdi-apple')
                }
              }), _c('span', _vm._g({}, on), [_vm._v(" - ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" No release selected ")])])], 1)];
      }
    }, {
      key: "item.views",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-btn', {
          attrs: {
            "color": "primary",
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.openAnalyticsDrawer(item, 0);
            }
          }
        }, [_c('b', [_vm._v(" " + _vm._s(item.stats.views || "0"))])])];
      }
    }, {
      key: "item.install",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "primary--text cursor-pointer",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.openAnalyticsDrawer(item, 1);
            }
          }
        }, [_c('b', {
          class: _vm.getLimitColor(item.stats.installs, item.settings.installs)
        }, [_vm._v(" " + _vm._s(_vm.numberWithCommas(item.stats.installs || "0")) + " ")])])];
      }
    }, {
      key: "item.privacy",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref8) {
              var on = _ref8.on;
              return [_c('v-icon', _vm._g({
                attrs: {
                  "small": ""
                }
              }, on), [_vm._v(" " + _vm._s(_vm.get(item, "settings.password_protected", false) ? "mdi-lock-outline" : "mdi-earth") + " ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.get(item, "settings.password_protected", false) ? "Password protected" : "No password") + " ")])])];
      }
    }, {
      key: "item.expiry",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [item.expire_at ? _c('div', [_c('vue-hoverable-date', {
          attrs: {
            "date": item.expire_at
          }
        })], 1) : _c('div', [_vm._v("-")])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref11) {
              var on = _ref11.on;
              return [_c('span', _vm._g({}, on), [_c('v-icon', {
                attrs: {
                  "color": _vm.$options.STATUS[item.status].color
                },
                domProps: {
                  "textContent": _vm._s(_vm.$options.STATUS[item.status].icon)
                }
              })], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$options.STATUS[item.status].title) + " ")])]), [6].includes(item.status) ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          },
          on: {
            "click": _vm.openLimitReachedPopup
          }
        }, [_vm._v(" Upgrade ")]) : _vm._e()];
      }
    }, {
      key: "item.action",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('div', {
          staticClass: "center-el"
        }, [_c('v-btn', {
          attrs: {
            "icon": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "darkGrey"
          },
          domProps: {
            "textContent": _vm._s('mdi-chevron-down')
          }
        })], 1), _c('action-dropdown', {
          scopedSlots: _vm._u([{
            key: "list",
            fn: function fn() {
              return [_c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.openLink(item.uid);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                },
                domProps: {
                  "textContent": _vm._s('mdi-eye')
                }
              }), _vm._v(" Preview ")], 1), _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    _vm.copy(_vm.shareLink(item.uid));
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                },
                domProps: {
                  "textContent": _vm._s('mdi-content-copy')
                }
              }), _vm._v(" Copy link ")], 1), ![2, 3, 6].includes(item.status) ? _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.openEditDialog(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("edit")]), _vm._v(" edit ")], 1) : _vm._e(), [6].includes(item.status) ? _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    _vm.showStartTrialModal = true;
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-arrow-up-drop-circle")]), _vm._v(" Upgrade ")], 1) : _vm._e(), ![2, 6].includes(item.status) ? _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.openChangeStatus(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-switch")]), _vm._v(" Change status ")], 1) : _vm._e(), _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.openDeleteDialog(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "left": "",
                  "small": ""
                }
              }, [_vm._v("delete")]), _vm._v(" Delete ")], 1)];
            },
            proxy: true
          }], null, true)
        })], 1)];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref13) {
        var headers = _ref13.headers,
          item = _ref13.item;
        return [_c('td', {
          staticClass: "pa-0",
          attrs: {
            "colspan": headers === null || headers === void 0 ? void 0 : headers.length
          }
        }, [_c('v-card', {
          attrs: {
            "flat": "",
            "tile": ""
          }
        }, [_c('v-card-text', {
          staticClass: "pb-4"
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('b', [_vm._v("Android:")]), !_vm.isEmpty(item.android) ? _c('span', [_vm._v(" " + _vm._s(item.settings.latest_android ? "Self-update with the latest Android release" : "Specific Android release") + " ")]) : _c('span', {
          staticClass: "ml-2"
        }, [_vm._v(" No release ")])]), item.ios && !item.ios.testflight ? _c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('b', [_vm._v("iOS:")]), !_vm.isEmpty(item.ios) ? _c('span', [_vm._v(" " + _vm._s(item.settings.latest_ios ? "Self-update with the latest iOS release" : "Specific iOS release") + " ")]) : _c('span', {
          staticClass: "ml-2"
        }, [_vm._v("No release")])]) : _vm._e(), _c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('b', [_vm._v("Installs:")]), _c('span', [_vm._v(" " + _vm._s(_vm.get(item, "settings.installs", "0") == 0 ? "Unlimited" : _vm.get(item, "settings.installs", "∞")) + " ")]), !_vm.isEmpty(_vm.getTeamTrial) && _vm.get(item, 'settings.installs', null) ? _c('span', [_vm._v(" (Trial) ")]) : _vm._e(), _vm.isEmpty(_vm.getTeamTrial) && _vm.get(item, 'settings.installs', null) ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          },
          on: {
            "click": _vm.openLimitReachedPopup
          }
        }, [_vm._v(" Upgrade ")]) : _vm._e()], 1)], 1), item.ios && item.ios.testflight ? _c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b', [_vm._v("TestFlight:")]), _c('a', {
          attrs: {
            "href": item.ios.testflight,
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(item.ios.testflight) + " ")])])], 1) : _vm._e(), _c('v-row', [_c('v-col', {
          staticClass: "pb-0",
          attrs: {
            "cols": "4"
          }
        }, [_c('div', {
          staticClass: "mb-2"
        }, [_c('b', [_vm._v("Created:")]), _c('vue-hoverable-date', {
          attrs: {
            "date": item.created_at
          }
        })], 1)]), _c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('div', {
          staticClass: "mb-2"
        }, [_c('b', [_vm._v("Custom message:")]), _c('div', [_vm._v(" " + _vm._s(item.settings.custom_message || "-") + " ")])]), _c('div', {
          staticClass: "mb-2"
        }, [_c('b', [_vm._v("Internal notes:")]), _c('div', [_c('vue-readmore', {
          attrs: {
            "length": 150,
            "text": item.note || '-'
          }
        })], 1)])]), _c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('div', {
          staticClass: "mb-2"
        }, [_c('b', [_vm._v("Status:")]), _c('span', {
          class: _vm.$options.STATUS[item.status].color + '--text'
        }, [_vm._v(" " + _vm._s(_vm.$options.STATUS[item.status].text) + " ")]), [6].includes(item.status) ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          },
          on: {
            "click": _vm.openLimitReachedPopup
          }
        }, [_vm._v(" Upgrade ")]) : _vm._e()], 1), _c('div', {
          staticClass: "mb-2"
        }, [_c('b', [_vm._v(" Privacy: ")]), _c('span', {
          class: _vm.get(item, 'settings.password_protected', false) ? 'success--text' : 'primary--text'
        }, [_vm._v(" " + _vm._s(_vm.get(item, "settings.password_protected", false) ? "Private & Password protected" : "Private & Unlisted") + " ")])]), _c('div', {
          staticClass: "mb-2"
        }, [_c('b', [_vm._v("Instructions:")]), _c('div', [_c('vue-readmore', {
          attrs: {
            "length": 200,
            "text": item.instructions || '-'
          }
        })], 1)])])], 1)], 1)], 1)], 1)];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', {
          staticClass: "no-data-found",
          attrs: {
            "cols": "12"
          }
        }, [_c('no-data', {
          attrs: {
            "first-text": "No public links",
            "second-text": "<p>Use public links for both Android & iOS. Enhance with screenshots and details. For consistent feedback, invite testers as <em>guests</em>—they're unlimited and stay notified!</p>"
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 1933698641)
  }) : !_vm.canManageApp ? _c('no-data', {
    attrs: {
      "first-text": "No install links",
      "second-text": "You don't have the permission to view or manage the public share links"
    }
  }) : _vm._e()], 1), _c('Modal', {
    on: {
      "close": _vm.closeModal
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.updateShareStatusModal ? _c('update-share-link-status', {
          attrs: {
            "selected-detail": _vm.selectedData
          },
          on: {
            "close": _vm.closeModal,
            "success": _vm.updateShareList,
            "showUpgradeModal": function showUpgradeModal($event) {
              _vm.showStartTrialModal = true, _vm.closeModal;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.updateShareStatusModal,
      callback: function callback($$v) {
        _vm.updateShareStatusModal = $$v;
      },
      expression: "updateShareStatusModal"
    }
  }), _c('Modal', {
    on: {
      "close": _vm.closeModal
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', {
          staticClass: "font-size-h3 bold-text",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Delete custom share link ")]), _c('v-divider'), _c('v-card-text', [_c('v-col', {
          staticClass: "bodyFont",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Are you sure you want to delete this link? "), _c('v-alert', {
          staticClass: "mt-3 bodyFont",
          attrs: {
            "border": "left",
            "color": "info",
            "icon": "info",
            "outlined": ""
          }
        }, [_vm._v(" By deleting this URL will make it immediately unavailable for everyone "), _c('br'), _c('br'), _vm._v(" Try editing the settings of this link instead. ")])], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('div', [_vm._v("No undo for this action")]), _c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "disabled": _vm.busy,
            "color": "primary",
            "text": ""
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "loading": _vm.busy,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": _vm.deleteShareableLink
          }
        }, [_vm._v(" Yes ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openDeleteModal,
      callback: function callback($$v) {
        _vm.openDeleteModal = $$v;
      },
      expression: "openDeleteModal"
    }
  }), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.openShareLinkModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('share-link-modal', {
          attrs: {
            "share-id": _vm.shareId
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openShareLinkModal,
      callback: function callback($$v) {
        _vm.openShareLinkModal = $$v;
      },
      expression: "openShareLinkModal"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "hide-close": "",
      "width": "700"
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        var _vm$user;
        return [_c('video-modal', {
          attrs: {
            "video-class": "wistia_embed wistia_async_6k5wxen95k email=".concat((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.id),
            "first-message": "Share your application with unlimited external testers for both Android & iOS in a single link",
            "second-message": "In this video, you will learn more about creating and managing the page",
            "title": "Share with anyone"
          },
          on: {
            "close": _vm.setShareVideoPopup
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showShareVideo,
      callback: function callback($$v) {
        _vm.showShareVideo = $$v;
      },
      expression: "showShareVideo"
    }
  }), _c('SidePanel', {
    attrs: {
      "permanent": false,
      "temporary": "",
      "width": "1000px"
    },
    on: {
      "close": _vm.closeModal
    },
    model: {
      value: _vm.showAnalyticsDrawer,
      callback: function callback($$v) {
        _vm.showAnalyticsDrawer = $$v;
      },
      expression: "showAnalyticsDrawer"
    }
  }, [_vm.showAnalyticsDrawer ? _c('share-analytics', {
    attrs: {
      "from-specific-link": _vm.fromSpecificLink,
      "selected-tab": _vm.defaultSelectedTab,
      "share-id": (_vm$selectedData = _vm.selectedData) === null || _vm$selectedData === void 0 ? void 0 : _vm$selectedData.id,
      "share-uid": (_vm$selectedData2 = _vm.selectedData) === null || _vm$selectedData2 === void 0 ? void 0 : _vm$selectedData2.uid
    }
  }) : _vm._e()], 1), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.mdAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? '850' : ''
    },
    on: {
      "close": _vm.closeModal
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', {
          staticClass: "pa-0"
        }, [_vm.openGenerateLinkForm ? _c('share-link-stepper', {
          key: _vm.openGenerateLinkForm,
          attrs: {
            "action-data": _vm.selectedData
          },
          on: {
            "close": _vm.closeModal,
            "refresh": _vm.afterSuccess,
            "updated": _vm.afterUpdated
          }
        }) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openGenerateLinkForm,
      callback: function callback($$v) {
        _vm.openGenerateLinkForm = $$v;
      },
      expression: "openGenerateLinkForm"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.mdAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 880 : '',
      "no-py": ""
    },
    on: {
      "close": function close($event) {
        _vm.showStartTrialModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm.showStartTrialModal ? _c('start-trial-modal', {
          attrs: {
            "trigger-from": _vm.triggerFrom,
            "from-share-link": ""
          },
          on: {
            "close": function close($event) {
              _vm.showStartTrialModal = false;
            },
            "open-subscribe": function openSubscribe($event) {
              _vm.openSubscriptionModal = true;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showStartTrialModal,
      callback: function callback($$v) {
        _vm.showStartTrialModal = $$v;
      },
      expression: "showStartTrialModal"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }