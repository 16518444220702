var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "flat": ""
    }
  }, [_c('invites')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }