var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_vm.loading ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-skeleton-loader', {
    staticClass: "transparent",
    attrs: {
      "type": "table-thead, table-tbody"
    }
  })], 1) : _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.canManageApp ? _c('DatatableWrapper', {
    attrs: {
      "loading": _vm.installLoading,
      "pagination": _vm.pagination
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.installData,
      "loading": _vm.loading,
      "hide-default-footer": "",
      "mobile-breakpoint": "0"
    },
    on: {
      "click:row": function clickRow($event) {
        var _$event$release;
        return _vm.$router.push({
          name: 'release-install',
          params: {
            app_id: _vm.$route.params.app_id,
            release_id: $event === null || $event === void 0 ? void 0 : (_$event$release = $event.release) === null || _$event$release === void 0 ? void 0 : _$event$release.id
          }
        });
      }
    },
    scopedSlots: _vm._u([!_vm.showUpgradeOverlay ? {
      key: "item.user",
      fn: function fn(_ref) {
        var _item$user;
        var item = _ref.item;
        return [item !== null && item !== void 0 && (_item$user = item.user) !== null && _item$user !== void 0 && _item$user.name ? _c('div', [_c('user-avatar', {
          attrs: {
            "user-detail": item.user
          }
        })], 1) : _c('div', [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('span', _vm._g({}, on), [_c('i', [_vm._v(" Anonymous ")])])];
            }
          }], null, true)
        }, [_c('span', [_c('div', {
          staticClass: "mb-2"
        }, [_vm._v(" Anonymous users can be possible of the following scenarios: ")]), _c('ul', [_c('li', [_vm._v("Public app release download")]), _c('li', [_vm._v("Direct release file download")])])])])], 1)];
      }
    } : null, !_vm.showUpgradeOverlay ? {
      key: "item.device",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [!_vm.showUpgradeOverlay ? _c('user-agent-parser', {
          attrs: {
            "full": true,
            "default-device": item.device
          }
        }) : _vm._e()];
      }
    } : null, !_vm.showUpgradeOverlay ? {
      key: "item.country",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [!_vm.showUpgradeOverlay ? _c('country-city', {
          attrs: {
            "detail": item.location
          }
        }) : _vm._e()];
      }
    } : null, !_vm.showUpgradeOverlay ? {
      key: "item.release",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.release ? _c('div', [_c('release-chip', {
          attrs: {
            "release": item.release
          }
        })], 1) : !_vm.showUpgradeOverlay ? _c('div', [_vm._v("-")]) : _vm._e()];
      }
    } : null, !_vm.showUpgradeOverlay ? {
      key: "item.date",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.installed_at ? _c('vue-hoverable-date', {
          attrs: {
            "date": item.installed_at
          }
        }) : _vm._e()];
      }
    } : null, {
      key: "footer.page-text",
      fn: function fn() {
        return [_c('div', [!_vm.showUpgradeOverlay ? _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "darkGrey",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.getReleaseInstallList();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": _vm.$vuetify.theme.dark ? '' : 'grey',
            "left": ""
          },
          domProps: {
            "textContent": _vm._s('mdi-reload')
          }
        }), _vm._v(" Reload ")], 1) : _vm._e(), _vm._v(" Total: "), _c('b', [_vm._v(_vm._s(_vm.numberWithCommas(_vm.totalCount)))])], 1)];
      },
      proxy: true
    }], null, true)
  }, [_vm.showUpgradeOverlay ? _c('template', {
    slot: "item"
  }, [_c('tr', [_c('td', {
    attrs: {
      "colspan": _vm.headers.length
    }
  }, [_c('upgrade-table-overlay', {
    attrs: {
      "total-length": _vm.headers.length,
      "message": _vm.errorMessage
    }
  })], 1)])]) : _vm._e(), _c('template', {
    slot: "no-data"
  }, [_c('no-data', {
    attrs: {
      "image": "install_empty_state",
      "first-text": _vm.showUpgradeOverlay ? 'Feature not available' : 'No installs or downloads logged for this release',
      "second-text": _vm.showUpgradeOverlay ? _vm.errorMessage : 'Install logs can be useful to trace the devices that installed your release for debugging purposes'
    }
  }), _vm.showUpgradeOverlay ? _c('v-btn', {
    staticClass: "text-transform-none mb-10",
    attrs: {
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.showStartTrialModal = true;
      }
    }
  }, [_vm._v(" Upgrade Plan ")]) : _vm._e()], 1)], 2)], 1) : [_c('no-data', {
    attrs: {
      "image": "install_empty_state",
      "first-text": 'You don\'t have the permission to view or manage release installs'
    }
  })]], 2)], 1), _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('Modal', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 1100 : '',
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.openSubscriptionModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('plan-pricing', {
          attrs: {
            "from-modal": "",
            "from-overlay": "",
            "upgrade-plan-text": _vm.message
          },
          on: {
            "success": function success($event) {
              _vm.$emit('close'), _vm.openSubscriptionModal = false;
            },
            "close": function close($event) {
              _vm.openSubscriptionModal = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openSubscriptionModal,
      callback: function callback($$v) {
        _vm.openSubscriptionModal = $$v;
      },
      expression: "openSubscriptionModal"
    }
  }), _c('Modal', {
    attrs: {
      "no-py": "",
      "fullscreen": _vm.$vuetify.breakpoint.mdAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 880 : ''
    },
    on: {
      "close": function close($event) {
        _vm.showStartTrialModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm.showStartTrialModal ? _c('start-trial-modal', {
          attrs: {
            "trigger-from": "exportInstalls"
          },
          on: {
            "close": function close($event) {
              _vm.showStartTrialModal = false;
            },
            "open-subscribe": function openSubscribe($event) {
              _vm.openSubscriptionModal = true;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showStartTrialModal,
      callback: function callback($$v) {
        _vm.showStartTrialModal = $$v;
      },
      expression: "showStartTrialModal"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }