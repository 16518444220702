<template>
  <div>
    <v-row>
      <v-col cols="12" class="text-right">
        <div>
          <v-btn
            id="goToSettingBtn"
            :class="$vuetify.breakpoint.smAndDown ? 'mt-3' : ''"
            :disabled="!canManageApp"
            class="text-transform-none mr-2"
            color="primary"
            outlined
            @click="$router.push({ name: 'app-settings-setting' })"
          >
            <v-icon left v-text="'mdi-link'"></v-icon>
            Settings
          </v-btn>
          <v-btn
            id="createNewLinkBtn"
            :class="$vuetify.breakpoint.smAndDown ? 'mt-3' : ''"
            :disabled="!canManageApp"
            :loading="checkLoading"
            :outlined="$vuetify.theme.dark"
            class="text-transform-none"
            color="primary"
            depressed
            @click="openSetting()"
          >
            <v-icon v-text="'mdi-plus'"></v-icon>
            New link
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12">
        <DatatableWrapper
          :loading="shareLoading"
          :pagination="pagination"
          :on-change="onChange"
        >
          <v-skeleton-loader
            v-if="shareLoading"
            class="transparent"
            type="table-thead, table-tbody"
          />
          <v-data-table
            v-if="!shareLoading && canManageApp"
            :expanded="expanded"
            :headers="headers"
            :items="getShareableLinks || []"
            disable-sort
            hide-default-footer
            mobile-breakpoint="0"
            @click:row="expendRow"
          >
            <template #item.android="{ item }">
              <div v-if="item.android && !isEmpty(item.android)">
                <release-chip :release="item.android"></release-chip>
              </div>
              <!-- No release selected -->
              <div v-else>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon left small v-text="'mdi-android'"></v-icon>
                    <span v-on="on"> - </span>
                  </template>
                  <span> No release selected </span>
                </v-tooltip>
              </div>
            </template>

            <template #item.ios="{ item }">
              <div v-if="item.ios.testflight" style="display: flex">
                <release-chip
                  show-test-flight
                  :test-flight-link="item.ios.testflight"
                ></release-chip>
              </div>
              <div v-else-if="item.ios && !isEmpty(item.ios)">
                <release-chip
                  :release="{ ...item.ios, platform: 'ios' }"
                ></release-chip>
              </div>
              <!-- No release selected -->
              <div v-else>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon left small v-text="'mdi-apple'"></v-icon>
                    <span v-on="on"> - </span>
                  </template>
                  <span> No release selected </span>
                </v-tooltip>
              </div>
            </template>

            <template #item.views="{ item }">
              <v-btn
                color="primary"
                icon
                @click.stop="openAnalyticsDrawer(item, 0)"
              >
                <b> {{ item.stats.views || "0" }}</b>
              </v-btn>
            </template>

            <template #item.install="{ item }">
              <div
                class="primary--text cursor-pointer"
                @click.stop="openAnalyticsDrawer(item, 1)"
              >
                <b
                  :class="
                    getLimitColor(item.stats.installs, item.settings.installs)
                  "
                >
                  {{ numberWithCommas(item.stats.installs || "0") }}
                </b>
              </div>
            </template>

            <template #item.privacy="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon small v-on="on">
                    {{
                      get(item, "settings.password_protected", false)
                        ? "mdi-lock-outline"
                        : "mdi-earth"
                    }}
                  </v-icon>
                </template>
                <span>
                  {{
                    get(item, "settings.password_protected", false)
                      ? "Password protected"
                      : "No password"
                  }}
                </span>
              </v-tooltip>
            </template>

            <!--                <template #item.share_link="{ item }">-->
            <!--                  <v-menu>-->
            <!--                    <template v-slot:activator="{ on }">-->
            <!--                      <v-btn color="primary" icon v-on="on">-->
            <!--                        <v-icon-->
            <!--                          color="primary"-->
            <!--                          small-->
            <!--                          v-text="'mdi-share'"-->
            <!--                        ></v-icon>-->
            <!--                      </v-btn>-->
            <!--                    </template>-->
            <!--                    <v-list dense>-->
            <!--                      <v-list-item @click="openLink(item.uid)">-->
            <!--                        <v-icon small v-text="'mdi-eye'"></v-icon>-->
            <!--                        View-->
            <!--                      </v-list-item>-->
            <!--                      <v-list-item @click="copy(shareLink(item.uid))">-->
            <!--                        <v-icon small v-text="'mdi-content-copy'"></v-icon>-->
            <!--                        Copy link-->
            <!--                      </v-list-item>-->
            <!--                      <v-list-item @click="shareInSocialMedia('slack')">-->
            <!--                        <v-icon small v-text="'mdi-slack'"></v-icon>-->
            <!--                        Share on TestApp.io Slack-->
            <!--                      </v-list-item>-->
            <!--                      <v-list-item-->
            <!--                        @click="-->
            <!--                          shareInSocialMedia('twitter', shareLink(item.uid))-->
            <!--                        "-->
            <!--                      >-->
            <!--                        <v-icon small v-text="'mdi-twitter'"></v-icon>-->
            <!--                        Share on Twitter-->
            <!--                      </v-list-item>-->
            <!--                      <v-list-item-->
            <!--                        @click="-->
            <!--                          shareInSocialMedia('linkedin', shareLink(item.uid))-->
            <!--                        "-->
            <!--                      >-->
            <!--                        <v-icon small v-text="'mdi-linkedin'"></v-icon>-->
            <!--                        Share on LinkedIn-->
            <!--                      </v-list-item>-->
            <!--                      <v-list-item-->
            <!--                        @click="-->
            <!--                          shareInSocialMedia('facebook', shareLink(item.uid))-->
            <!--                        "-->
            <!--                      >-->
            <!--                        <v-icon small v-text="'mdi-facebook'"></v-icon>-->
            <!--                        Share on Facebook-->
            <!--                      </v-list-item>-->
            <!--                      <v-list-item-->
            <!--                        @click="-->
            <!--                          shareInSocialMedia('email', shareLink(item.uid))-->
            <!--                        "-->
            <!--                      >-->
            <!--                        <v-icon small v-text="'mdi-email'"></v-icon>-->
            <!--                        Share via Email-->
            <!--                      </v-list-item>-->
            <!--                    </v-list>-->
            <!--                  </v-menu>-->
            <!--                </template>-->

            <template #item.expiry="{ item }">
              <div v-if="item.expire_at">
                <vue-hoverable-date :date="item.expire_at"></vue-hoverable-date>
              </div>
              <div v-else>-</div>
            </template>

            <template #item.status="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <span v-on="on">
                    <v-icon
                      :color="$options.STATUS[item.status].color"
                      v-text="$options.STATUS[item.status].icon"
                    ></v-icon>
                  </span>
                </template>
                <span>
                  {{ $options.STATUS[item.status].title }}
                </span>
              </v-tooltip>
              <v-chip
                v-if="[6].includes(item.status)"
                label
                small
                @click="openLimitReachedPopup"
              >
                Upgrade
              </v-chip>
            </template>

            <template #item.action="{ item }">
              <div class="center-el">
                <v-btn icon>
                  <v-icon color="darkGrey" v-text="'mdi-chevron-down'"></v-icon>
                </v-btn>
                <action-dropdown>
                  <template #list>
                    <v-list-item @click="openLink(item.uid)">
                      <v-icon small v-text="'mdi-eye'"></v-icon>
                      Preview
                    </v-list-item>
                    <v-list-item @click="copy(shareLink(item.uid))">
                      <v-icon small v-text="'mdi-content-copy'"></v-icon>
                      Copy link
                    </v-list-item>
                    <v-list-item
                      v-if="![2, 3, 6].includes(item.status)"
                      @click="openEditDialog(item)"
                    >
                      <v-icon small>edit</v-icon>
                      edit
                    </v-list-item>
                    <v-list-item
                      v-if="[6].includes(item.status)"
                      @click="showStartTrialModal = true"
                    >
                      <v-icon small>mdi-arrow-up-drop-circle</v-icon>
                      Upgrade
                    </v-list-item>
                    <v-list-item
                      v-if="![2, 6].includes(item.status)"
                      @click="openChangeStatus(item)"
                    >
                      <v-icon small>mdi-switch</v-icon>
                      Change status
                    </v-list-item>
                    <v-list-item @click="openDeleteDialog(item)">
                      <v-icon left small>delete</v-icon>
                      Delete
                    </v-list-item>
                  </template>
                </action-dropdown>
              </div>
            </template>

            <template #expanded-item="{ headers, item }">
              <td :colspan="headers?.length" class="pa-0">
                <v-card flat tile>
                  <v-card-text class="pb-4">
                    <v-row>
                      <v-col cols="4">
                        <b>Android:</b>
                        <span v-if="!isEmpty(item.android)">
                          {{
                            item.settings.latest_android
                              ? "Self-update with the latest Android release"
                              : "Specific Android release"
                          }}
                        </span>
                        <span v-else class="ml-2"> No release </span>
                      </v-col>
                      <v-col v-if="item.ios && !item.ios.testflight" cols="4">
                        <b>iOS:</b>
                        <span v-if="!isEmpty(item.ios)">
                          {{
                            item.settings.latest_ios
                              ? "Self-update with the latest iOS release"
                              : "Specific iOS release"
                          }}
                        </span>
                        <span v-else class="ml-2">No release</span>
                      </v-col>
                      <v-col cols="4">
                        <b>Installs:</b>
                        <span>
                          {{
                            get(item, "settings.installs", "0") == 0
                              ? "Unlimited"
                              : get(item, "settings.installs", "∞")
                          }}
                        </span>
                        <span
                          v-if="
                            !isEmpty(getTeamTrial) &&
                            get(item, 'settings.installs', null)
                          "
                        >
                          (Trial)
                        </span>
                        <v-chip
                          v-if="
                            isEmpty(getTeamTrial) &&
                            get(item, 'settings.installs', null)
                          "
                          label
                          small
                          @click="openLimitReachedPopup"
                        >
                          Upgrade
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row v-if="item.ios && item.ios.testflight">
                      <v-col cols="12">
                        <b>TestFlight:</b>
                        <a :href="item.ios.testflight" target="_blank">
                          {{ item.ios.testflight }}
                        </a>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="pb-0" cols="4">
                        <div class="mb-2">
                          <b>Created:</b>
                          <vue-hoverable-date
                            :date="item.created_at"
                          ></vue-hoverable-date>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="mb-2">
                          <b>Custom message:</b>
                          <div>
                            {{ item.settings.custom_message || "-" }}
                          </div>
                        </div>
                        <div class="mb-2">
                          <b>Internal notes:</b>
                          <div>
                            <vue-readmore
                              :length="150"
                              :text="item.note || '-'"
                            ></vue-readmore>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="mb-2">
                          <b>Status:</b>
                          <span
                            :class="
                              $options.STATUS[item.status].color + '--text'
                            "
                          >
                            {{ $options.STATUS[item.status].text }}
                          </span>
                          <v-chip
                            v-if="[6].includes(item.status)"
                            label
                            small
                            @click="openLimitReachedPopup"
                          >
                            Upgrade
                          </v-chip>
                        </div>
                        <div class="mb-2">
                          <b> Privacy: </b>
                          <span
                            :class="
                              get(item, 'settings.password_protected', false)
                                ? 'success--text'
                                : 'primary--text'
                            "
                          >
                            {{
                              get(item, "settings.password_protected", false)
                                ? "Private & Password protected"
                                : "Private & Unlisted"
                            }}
                          </span>
                        </div>
                        <div class="mb-2">
                          <b>Instructions:</b>
                          <div>
                            <vue-readmore
                              :length="200"
                              :text="item.instructions || '-'"
                            ></vue-readmore>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </td>
            </template>

            <template #no-data>
              <v-row>
                <v-col class="no-data-found" cols="12">
                  <no-data
                    first-text="No public links"
                    second-text="<p>Use public links for both Android & iOS. Enhance with screenshots and details. For consistent feedback, invite testers as <em>guests</em>—they're unlimited and stay notified!</p>"
                  ></no-data>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
          <no-data
            v-else-if="!canManageApp"
            first-text="No install links"
            second-text="You don't have the permission to view or manage the public share links"
          ></no-data>
        </DatatableWrapper>

        <Modal v-model="updateShareStatusModal" @close="closeModal">
          <template #card>
            <update-share-link-status
              v-if="updateShareStatusModal"
              :selected-detail="selectedData"
              @close="closeModal"
              @success="updateShareList"
              @showUpgradeModal="(showStartTrialModal = true), closeModal"
            ></update-share-link-status>
          </template>
        </Modal>
        <Modal v-model="openDeleteModal" @close="closeModal">
          <template #card>
            <v-card>
              <v-card-title class="font-size-h3 bold-text" cols="12">
                Delete custom share link
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-col class="bodyFont" cols="12">
                  Are you sure you want to delete this link?

                  <v-alert
                    border="left"
                    class="mt-3 bodyFont"
                    color="info"
                    icon="info"
                    outlined
                  >
                    By deleting this URL will make it immediately unavailable
                    for everyone
                    <br /><br />
                    Try editing the settings of this link instead.
                  </v-alert>
                </v-col>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <div>No undo for this action</div>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="busy"
                  class="text-transform-none"
                  color="primary"
                  text
                  @click="closeModal"
                >
                  Cancel
                </v-btn>
                <v-btn
                  :loading="busy"
                  :outlined="$vuetify.theme.dark"
                  class="text-transform-none"
                  color="primary"
                  depressed
                  @click="deleteShareableLink"
                >
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </Modal>
        <Modal v-model="openShareLinkModal" @close="openShareLinkModal = false">
          <template #message>
            <share-link-modal :share-id="shareId"></share-link-modal>
          </template>
        </Modal>
        <Modal
          v-model="showShareVideo"
          :fullscreen="$vuetify.breakpoint.smAndDown"
          hide-close
          width="700"
        >
          <template #card>
            <video-modal
              :video-class="`wistia_embed wistia_async_6k5wxen95k email=${user?.id}`"
              first-message="Share your application with unlimited external testers for both Android & iOS in a single link"
              second-message="In this video, you will learn more about creating and managing the page"
              title="Share with anyone"
              @close="setShareVideoPopup"
            ></video-modal>
          </template>
        </Modal>
        <SidePanel
          v-model="showAnalyticsDrawer"
          :permanent="false"
          temporary
          width="1000px"
          @close="closeModal"
        >
          <share-analytics
            v-if="showAnalyticsDrawer"
            :from-specific-link="fromSpecificLink"
            :selected-tab="defaultSelectedTab"
            :share-id="selectedData?.id"
            :share-uid="selectedData?.uid"
          ></share-analytics>
        </SidePanel>
        <Modal
          v-model="openGenerateLinkForm"
          :fullscreen="$vuetify.breakpoint.mdAndDown"
          :width="$vuetify.breakpoint.mdAndUp ? '850' : ''"
          @close="closeModal"
        >
          <template #card>
            <v-card>
              <v-card-title>
                <v-spacer />
                <v-btn @click="closeModal" icon>
                  <v-icon>close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="pa-0">
                <share-link-stepper
                  v-if="openGenerateLinkForm"
                  :key="openGenerateLinkForm"
                  :action-data="selectedData"
                  @close="closeModal"
                  @refresh="afterSuccess"
                  @updated="afterUpdated"
                ></share-link-stepper>
              </v-card-text>
            </v-card>
          </template>
        </Modal>
        <Modal
          v-model="showStartTrialModal"
          :fullscreen="$vuetify.breakpoint.mdAndDown"
          :width="$vuetify.breakpoint.mdAndUp ? 880 : ''"
          no-py
          @close="showStartTrialModal = false"
        >
          <template #message>
            <start-trial-modal
              v-if="showStartTrialModal"
              :trigger-from="triggerFrom"
              from-share-link
              @close="showStartTrialModal = false"
              @open-subscribe="openSubscriptionModal = true"
            ></start-trial-modal>
          </template>
        </Modal>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.Invited {
  color: #fa6400;
}

.Suspended {
  color: #e02020;
}
</style>

<script>
import {
  formatBytes,
  get,
  isEmpty,
  numberWithCommas,
} from "@/core/services/helper.service";
import { mapGetters, mapMutations, mapState } from "vuex";
import {
  DELETE_SHAREABLE_LINK,
  UPDATE_APP_PUBLIC_STATUS,
} from "@/store/releases/releases.module";
import ShareLinkModal from "@/view/components/Releases/ShareLinkModal";
import ShareLinkStepper from "@/view/components/Releases/ShareLinkStepper";
import VueReadmore from "@/view/components/Common/VueReadmore";
import ShareAnalytics from "@/view/components/Releases/ShareAnalytics";
import UpdateShareLinkStatus from "@/view/components/App/UpdateShareLinkStatus";
import StorageService from "@/core/services/localstorage.service";
import VideoModal from "@/view/components/Common/VideoModal";
import StartTrialModal from "@/view/components/Common/StartTrialModal";
import { segmentEvents } from "@/consts/segmentEventConst";
import DatatableWrapper from "@/view/components/App/DatatableWrapper";

export default {
  name: "app-share-link",
  components: {
    DatatableWrapper,
    StartTrialModal,
    VideoModal,
    UpdateShareLinkStatus,
    ShareAnalytics,
    VueReadmore,
    ShareLinkStepper,
    ShareLinkModal,
  },
  PRIVACY_STATUS: {
    0: {
      title: "Public & listed",
      color: "primary",
      text: "mdi-earth",
    },
    1: {
      title: "Private & Unlisted",
      color: "primary",
      text: "mdi-lock-outline",
    },
    2: {
      title: "Private & Password protected",
      color: "success",
      text: "mdi-lock-outline",
    },
  },
  STATUS: {
    1: {
      title: "Enabled",
      color: "success",
      icon: "mdi-circle",
      text: "Enabled",
    },
    3: {
      title: "Disabled ",
      color: "grey",
      icon: "mdi-circle-outline",
      text: "Disabled",
    },
    2: {
      title: "Expired",
      color: "red",
      icon: "mdi-circle-outline",
      text: "Expired",
    },
    4: {
      title: "Custom ",
      color: "purple",
      icon: "mdi-file-outline",
      text: "Custom Link",
    },
    6: {
      title: "Limit reached",
      color: "error",
      text: "Limit reached",
    },
  },
  data() {
    return {
      get,
      isEmpty,
      formatBytes,
      numberWithCommas,
      shareLoading: false,
      shareLinkData: [],
      totalShareLinkCount: 0,
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      release: "android",
      readMore: false,
      checkLoading: false,
      triggerFrom: "shareLinkLimit",
      fromSpecificLink: false,
      showShareVideo: false,
      updateShareStatusModal: false,
      previewItems: [
        { title: "Maintenance mode", value: "maintenance" },
        { title: "Coming soon mode", value: "coming_soon" },
        { title: "Not available", value: "not_available" },
      ],
      preview: false,
      defaultSelectedTab: 0,
      public_access: false,
      page: 1,
      appId: "",
      shareId: "",
      openShareLinkModal: false,
      openGenerateLinkForm: false,
      errorMessage: null,
      selectedId: "",
      shareableLinks: [],
      loading: false,
      busy: false,
      headers: [
        {
          text: "Android",
          align: "start",
          width: "200",
          sortable: false,
          value: "android",
        },
        {
          text: "iOS",
          align: "start",
          width: "200",
          sortable: false,
          value: "ios",
        },
        {
          text: "Views",
          align: "start",
          sortable: false,
          width: "50",
          value: "views",
        },
        {
          text: "Installs",
          align: "start",
          sortable: false,
          width: "50",
          value: "install",
        },
        {
          text: "Privacy",
          align: "start",
          sortable: false,
          width: "50",
          value: "privacy",
        },
        // {
        //   text: "Share",
        //   sortable: false,
        //   width: "50",
        //   value: "share_link",
        // },
        {
          text: "Expire",
          align: "start",
          sortable: false,
          width: "120",
          value: "expiry",
        },
        {
          text: "Status",
          align: "start",
          width: 120,
          sortable: false,
          value: "status",
        },
        // {
        //   text: "Status",
        //   align: "end",
        //   sortable: false,
        //   width: "10%",
        //   value: "status",
        // },
        {
          text: "",
          align: "end",
          sortable: false,
          width: "10",
          value: "action",
        },
      ],
      expanded: [],
      openDeleteModal: false,
      showAnalyticsDrawer: false,
      showStartTrialModal: false,
      selectedData: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters({
      getShareableLinks: "getShareableLinks",
      appInfo: "getAppInfo",
      getTeamTrial: "getTeamTrial",
      getTeamPlanLimits: "getTeamPlanLimits",
      getTotalShareableLinksCount: "getTotalShareableLinksCount",
      getTeamPlanUsage: "getTeamPlanUsage",
      getTotalShareableLinks: "getTotalShareableLinks",
    }),
    lastId() {
      return this.getShareableLinks[this.getShareableLinks?.length - 1]?.id;
    },
  },
  watch: {
    "pagination.page"(val, oldVal) {
      this.pagination.page = val;
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.getTotalShareableLinks?.length > oldVal * 10) {
          this.setPreviousShareLinkData(
            this.getTotalShareableLinks.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.getShareableLinks?.length
            ? this.getShareableLinks[this.getShareableLinks?.length - 1].id
            : "";
          this.getAllShareLinks({ page: val, lastID: lastId });
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousShareLinkData(
          this.getTotalShareableLinks.slice(offset, limit)
        );
      }
    },
    getTotalShareableLinksCount(value) {
      this.pagination.totalItems = value;
    },
  },
  created() {
    this.appId = this.$route.params.app_id;
    if (this.canManageApp) {
      this.getAllShareLinks();
    }
    this.viewShareVideo();
    this.public_access = this.appInfo.public;
  },
  methods: {
    ...mapMutations({
      removeShareLinkDeletedData: "removeShareLinkDeletedData",
      updateAppShareStatus: "updateAppShareStatus",
      setShareableLink: "setShareableLink",
      setPreviousShareLinkData: "setPreviousShareLinkData",
      setShareableLinkCount: "setShareableLinkCount",
      setShareableLinkPaginatedData: "setShareableLinkPaginatedData",
    }),
    onChange(value) {
      this.pagination.page = value;
    },
    getAllShareLinks({ page = this.pagination.page, lastID = "" } = {}) {
      if (this.shareLoading) return;
      this.shareLoading = true;
      const params = {
        page,
        lastID,
        releaseID: this.releaseId,
      };
      this.$coreApi
        .get(
          `${this.apiRoutes.core.app.getShareLinkList(
            this.$route.params.app_id
          )}?${this.getQuery(params)}`
        )
        .then(({ data }) => {
          if (lastID) {
            this.setShareableLinkPaginatedData(data.data ?? []);
          } else {
            this.setShareableLink(data.data ?? []);
            this.setShareableLinkCount(data.meta.count);
          }
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        })
        .finally(() => {
          this.shareLoading = false;
        });
    },
    updateShareList(data) {
      this.closeModal();
      this.shareLinkData = this.shareLinkData.map((link) => {
        if (link.id === data.id) {
          link.status = data.status;
          link.settings.custom_message = data.message;
        }
        return link;
      });
    },
    closeModal() {
      this.selectedData = {};
      (this.updateShareStatusModal = false), (this.openDeleteModal = false);
      this.openGenerateLinkForm = false;
      this.showAnalyticsDrawer = false;
    },
    getShareURL(type, url) {
      const text = `Please test my ${this.appInfo.name} app and provide your feedback!`;
      const hashtags = "\n\n#testmyapp #betatesting #testappio\n\n";

      const urlMap = {
        linkedin: `https://www.linkedin.com/shareArticle?mini=false&url=${url}`,
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}`,
        twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          url
        )}&text=${encodeURIComponent(text)}${encodeURIComponent(hashtags)}`,
        email: `mailto:?&subject=&cc=&bcc=&body=${encodeURIComponent(
          "Hello \n\n" + text
        )}${encodeURIComponent("\n\n" + url)}`,
        slack: "https://testappio.slack.com/archives/C02PHNXRE92",
      };

      return urlMap[type];
    },
    openLimitReachedPopup() {
      this.triggerFrom = "shareLinkLimit";
      this.showStartTrialModal = true;
    },
    shareInSocialMedia(type, url) {
      const shareURL = this.getShareURL(type, url);
      if (shareURL) {
        window.open(shareURL);
      } else {
        //console.warn(`No sharing URL found for type: ${type}`);
      }
    },
    openAnalyticsDrawer(item, tab) {
      this.selectedData = item;
      this.defaultSelectedTab = tab;
      this.fromSpecificLink = true;
      this.showAnalyticsDrawer = true;
    },
    openAnalyticsDrawerForAllLink(tab) {
      this.defaultSelectedTab = tab;
      this.fromSpecificLink = false;
      this.showAnalyticsDrawer = true;
    },
    setShareVideoPopup() {
      this.showShareVideo = false;
      StorageService.setItem("video", {
        release: StorageService.getItem("video")
          ? StorageService.getItem("video").release || false
          : false,
        welcome: StorageService.getItem("video")
          ? StorageService.getItem("video").welcome || false
          : false,
        share: true,
      });
    },
    viewShareVideo() {
      const video = StorageService.getItem("video");
      if (!video || !video.share) {
        this.showShareVideo = true;
      } else if (video && video.share === "false") {
        this.showShareVideo = true;
      }
    },
    shareLink(uid) {
      return `${location.origin}/apps/install/${uid}`;
    },
    openSetting() {
      this.openGenerateLinkForm = true;
    },
    expendRow(item) {
      if (!item.value) {
        this.expanded = item === this.expanded[0] ? [] : [item];
      }
    },
    afterSuccess(detail) {
      this.shareId = detail.uid;
      this.openShareLinkModal = true;
      this.getAllShareLinks();
    },
    afterUpdated() {
      this.selectedData = {};
      this.openGenerateLinkForm = false;
      this.getAllShareLinks();
    },
    updatePublicAccess() {
      this.$store
        .dispatch(UPDATE_APP_PUBLIC_STATUS, {
          app_id: this.$route.params.app_id,
          enabled: this.public_access,
        })
        .then(() => {
          this.updateAppShareStatus(this.public_access);
          // this.notifyUserMessage(response.message);
        })
        .catch((err) => {
          this.public_access = false;
          this.notifyErrorMessage(err.message);
        });
    },
    openEditDialog(detail) {
      this.selectedData = detail;
      this.openGenerateLinkForm = true;
    },
    openChangeStatus(detail) {
      if (!this.getTeamPlanUsage.public_page_status) {
        this.logEvent(segmentEvents.INSTALL_PAGE_LOCKED);
        this.triggerFrom = "publicPageStatus";
        this.showStartTrialModal = true;
        return;
      }
      this.selectedData = detail;
      this.updateShareStatusModal = true;
    },
    openDeleteDialog(detail) {
      this.selectedData = detail;
      this.openDeleteModal = true;
    },
    openLink(uid) {
      window.open(`${location.origin}/apps/install/${uid}`, "_blank");
    },
    getLimitColor(install, limit) {
      // const limit = get(this.item, "settings.installs", 0);
      if (limit) {
        const percentage = (parseInt(install) / parseInt(limit)) * 100;
        if (parseInt(percentage) > 50 && parseInt(percentage) < 80) {
          return "orange--text";
        }
        if (parseInt(percentage) >= 80) {
          return "red--text";
        }
      }
      return "primary--text";
    },
    deleteShareableLink() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(DELETE_SHAREABLE_LINK, {
          app_id: this.$route.params.app_id,
          link_id: this.selectedData?.id,
        })
        .then((response) => {
          this.busy = false;
          this.openDeleteModal = false;
          this.removeShareLinkDeletedData({ id: this.selectedData?.id });
          this.$nextTick(() => {
            this.selectedData = {};
          });
          this.notifyUserMessage({ message: response.message });
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
  },
};
</script>

<style scoped>
.publicAccessSwitch {
  position: absolute;
  right: 12px;
}
</style>
