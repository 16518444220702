<template>
  <v-menu v-model="menu" bottom left offset-y transition="slide-x-transition">
    <template v-slot:activator="{ on }">
      <span v-if="displayText" class="pointer" v-on="on">
        {{ displayText || "displayText" }}
      </span>
      <v-btn
        v-else-if="button"
        :class="{ 'px-8 py-8': largeBtn }"
        :outlined="$vuetify.theme.dark"
        class="text-transform-none"
        color="primary"
        v-on="on"
      >
        <v-icon :class="{ 'mr-5': largeBtn }" :large="largeBtn" left>
          {{ icon || "insert_emoticon" }}
        </v-icon>
        {{ button }}
      </v-btn>
      <v-btn
        v-else
        id="AppActionsDropdown"
        :absolute="absolute"
        :right="absolute"
        :top="absolute"
        icon
        v-on="on"
      >
        <v-icon :color="color ? color : 'primary'">mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>
    <v-list class="menu-list" dense>
      <v-list-item-group v-model="menu" class="text-capitalize">
        <slot name="list"></slot>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "action-dropdown",
  props: [
    "defaultOpen",
    "onEdit",
    "onLeave",
    "color",
    "button",
    "icon",
    "isGetStarted",
    "absolute",
    "largeBtn",
    "displayText",
  ],
  watch: {
    defaultOpen(val) {
      this.menu = val;
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  created() {
    this.menu = this.defaultOpen || false;
  },
  methods: {},
};
</script>
<style lang="scss">
@import "@/assets/sass/variables";

.v-menu__content {
  .menu-list {
    .v-list-item {
      // adding the border to the drpdown's listes
      border-bottom: 1px solid rgba(black, 0.1);
    }
  }

  .v-list {
    .v-list-item {
      color: $text-color !important;
      display: flex;
      min-width: 130px;
      opacity: 0.8;
      padding-left: 11px;
      padding-right: 20px;

      &:last-child {
        border-bottom: none;
      }

      &.active {
        background: rgba($blue, 0.25);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.v-menu__content {
  // box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5) !important;
}

.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    outline: none;
    padding: 0;

    .b-dropdown-text {
      padding: 0;
    }
  }
}

// romoving the padding from the dropdown
.v-list {
  font-size: 12px !important;
  padding: 0;

  .v-list-item {
  }
}
</style>
