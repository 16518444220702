<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card flat outlined class="pa-3">
          <v-row align="center">
            <v-col cols="12">
              <div class="subListFont">
                Schedule your app releases for automatic archiving after a set
                period. This ensures your app remain tidy, organized, and
                optimizes storage space.
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-title>
            <div>
              Auto Archive
              <v-btn @click="showAutoArchiveLearnMore = true" icon>
                <v-icon v-text="'mdi-help-circle-outline'"></v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="bodyFont" cols="12">
                <v-row align="center">
                  <v-col cols="auto">
                    <p>
                      Team auto-archive is set to:
                      <b>{{ getTeamAutoArchive() }}</b
                      >.
                    </p>
                  </v-col>
                  <v-col v-if="canManageTeam" class="mt-n4" cols="auto">
                    <v-btn icon @click="openSettingForm('team')">
                      <v-icon v-text="'mdi-pencil'"></v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="mt-n4" cols="auto">
                    <v-chip x-small color="success" label outlined>NEW</v-chip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row align="center">
                  <v-col class="bodyFont" cols="auto">
                    <p>
                      This app auto-archive is set to:
                      <b>{{
                        getTeamPlanUsage.auto_archive_release
                          ? getAutoArchiveValue()
                          : getTeamAutoArchive()
                      }}</b
                      >.
                    </p>
                  </v-col>
                  <v-col v-if="canManageRelease" class="mt-n4" cols="auto">
                    <v-btn icon @click="openSettingForm('app')">
                      <v-icon v-text="'mdi-pencil'"></v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <Modal
          v-model="showStartTrialModal"
          @close="showStartTrialModal = false"
          no-py
          :fullscreen="$vuetify.breakpoint.mdAndDown"
          :width="$vuetify.breakpoint.mdAndUp ? 880 : ''"
        >
          <template #message>
            <start-trial-modal
              v-if="showStartTrialModal"
              trigger-from="autoArchiveRelease"
              :tracker-event="{
                app_id: $route.params.app_id,
                action: 'Auto Archive Release',
              }"
              @close="showStartTrialModal = false"
              @open-subscribe="openSubscriptionModal = true"
            ></start-trial-modal>
          </template>
        </Modal>
        <Modal
          :width="$vuetify.breakpoint.mdAndUp ? 1100 : ''"
          :fullscreen="$vuetify.breakpoint.smAndDown"
          @close="openSubscriptionModal = false"
          v-model="openSubscriptionModal"
        >
          <template #message>
            <plan-pricing
              from-modal
              from-upgrade-component
              :upgrade-plan-text="'This feature is only available for Starter and Pro plans.'"
              @success="$emit('close'), (openSubscriptionModal = false)"
              @close="openSubscriptionModal = false"
            ></plan-pricing>
          </template>
        </Modal>
        <Modal v-model="showSettingForm">
          <template #card>
            <settings-form
              v-if="showSettingForm"
              @close="showSettingForm = false"
              :setting-name="settingName"
              @show-trail="
                (showSettingForm = false), (showStartTrialModal = true)
              "
              @open-subscription="
                (showSettingForm = false), (openSubscriptionModal = true)
              "
            ></settings-form>
          </template>
        </Modal>
        <Modal closeable v-model="showAutoArchiveLearnMore">
          <template #card>
            <auto-archive-information></auto-archive-information>
          </template>
        </Modal>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import StartTrialModal from "@/view/components/Common/StartTrialModal";
import PlanPricing from "@/view/components/Team/PlanPricing";
import AutoArchiveInformation from "@/view/components/Releases/AutoArchiveInformation";
import SettingsForm from "@/view/components/Releases/SettingsForm.vue";

export default {
  components: {
    PlanPricing,
    StartTrialModal,
    AutoArchiveInformation,
    SettingsForm,
  },
  data() {
    return {
      selectedDay: 0,
      selectedTime: "Days",
      showSettingForm: false,
      settingName: "app",
      showAutoArchiveLearnMore: false,
      noValue: false,
      busy: false,
      openSubscriptionModal: false,
      showStartTrialModal: false,
    };
  },
  computed: {
    ...mapGetters({
      getTeamPlanUsage: "getTeamPlanUsage",
      appInfo: "getAppInfo",
    }),
  },

  methods: {
    ...mapMutations({
      updateAppAutoArchiveSettings: "updateAppAutoArchiveSettings",
    }),
    openSettingForm(settingName) {
      this.settingName = settingName;
      this.showSettingForm = true;
    },

    getAutoArchiveValue() {
      const value = this.appInfo?.settings?.auto_archive_release
        ? this.appInfo?.settings?.auto_archive_release / 86400
        : 0;
      if (value > 30 && value % 30 === 0) {
        return value / 30 + " Months";
      } else if (value) {
        return value + " Days";
      } else {
        return "Never";
      }
    },
    getTeamAutoArchive() {
      const value = this.getTeamPlanUsage?.release_expire
        ? this.getTeamPlanUsage?.release_expire / 86400
        : 0;
      if (value > 30 && value % 30 === 0) {
        return value / 30 + " Months";
      } else if (value) {
        return value + " Days";
      } else {
        return "Never";
      }
    },
  },
};
</script>
