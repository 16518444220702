var render = function render(){
  var _vm$appInfo, _vm$appInfo2, _vm$appInfo2$user, _vm$appInfo3, _vm$appInfo3$settings, _vm$appInfo4, _vm$appInfo4$settings;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.getInfoErrorMessage ? [_c('no-data', {
    attrs: {
      "first-text": _vm.getInfoErrorMessage,
      "with-button": "",
      "btn-text": "Go back"
    }
  })] : [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "outlined": "",
      "flat": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('app-image-edit', {
    attrs: {
      "app": _vm.appInfo,
      "height": 80,
      "width": 80
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "titleFont"
  }, [_vm._v(" " + _vm._s(_vm.appInfo.name) + " ")]), _c('div', {
    staticClass: "cursor-pointer",
    attrs: {
      "id": "edit-app"
    },
    on: {
      "click": function click($event) {
        return _vm.copy(_vm.appInfo.id);
      }
    }
  }, [_c('span', {
    staticClass: "mr-1 primary--text"
  }, [_vm._v(_vm._s(_vm.appInfo.id))]), _c('vue-tooltip', {
    attrs: {
      "v-html": 'App ID can be used in TA-CLI',
      "color": "primary"
    }
  })], 1), _c('div', [(_vm$appInfo = _vm.appInfo) !== null && _vm$appInfo !== void 0 && _vm$appInfo.created_at ? _c('vue-hoverable-date', {
    attrs: {
      "date": _vm.appInfo.created_at
    }
  }) : _vm._e(), _vm._v(" by " + _vm._s((_vm$appInfo2 = _vm.appInfo) === null || _vm$appInfo2 === void 0 ? void 0 : (_vm$appInfo2$user = _vm$appInfo2.user) === null || _vm$appInfo2$user === void 0 ? void 0 : _vm$appInfo2$user.name) + " ")], 1)]), _c('v-col', [_c('div', {
    staticClass: "top float-right"
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({}, on), [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.onLeaveClick
          }
        }, [_c('v-icon', [_vm._v("logout")])], 1)], 1)];
      }
    }])
  }, [_c('span', [_vm._v(" Leave App ")])]), _vm.canManageApp ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('span', _vm._g({}, on), [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.onDeleteClick
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "error"
          }
        }, [_vm._v("mdi-delete")])], 1)], 1)];
      }
    }], null, false, 2663545416)
  }, [_c('span', [_vm._v(" Delete App ")])]) : _vm._e()], 1)])], 1)], 1)], 1), _vm.canManageApp ? [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-tabs', {
    model: {
      value: _vm.selectedOption,
      callback: function callback($$v) {
        _vm.selectedOption = $$v;
      },
      expression: "selectedOption"
    }
  }, _vm._l(_vm.optionList, function (value, key) {
    return _c('tab', {
      key: key,
      attrs: {
        "label": value.title
      },
      on: {
        "click": function click($event) {
          return _vm.setAction(value.value);
        }
      }
    });
  }), 1)], 1), _vm.selectedOption === 0 ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "my-5",
    attrs: {
      "outlined": "",
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "id": "packageId",
      "disabled": "",
      "filled": "",
      "hide-details": "",
      "label": "Package",
      "persistent-hint": ""
    },
    model: {
      value: _vm.appInfo.package,
      callback: function callback($$v) {
        _vm.$set(_vm.appInfo, "package", $$v);
      },
      expression: "appInfo.package"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-dialog', {
    attrs: {
      "width": "500"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.packageHelpDialog = true;
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-help-circle-outline")])], 1)];
      }
    }], null, false, 3686958182),
    model: {
      value: _vm.packageHelpDialog,
      callback: function callback($$v) {
        _vm.packageHelpDialog = $$v;
      },
      expression: "packageHelpDialog"
    }
  }, [_c('v-card', [_c('v-card-text', {
    staticClass: "font-14"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" This Package or Bundle ID is a unique identifier for your app and is connected to its distribution, releases, SDK, and sessions. It ensures consistency across both Android and iOS platforms. ")]), _c('ul', [_c('li', {
    staticClass: "mb-2"
  }, [_vm._v(" Distribution & Releases: Every app must have one and the same Package or Bundle ID for creating a release. This ID is central to aligning your app's project throughout its lifecycle. ")]), _c('li', {
    staticClass: "mb-2"
  }, [_vm._v(" SDK Connection: The ID is vital for linking the SDK to the correct app or project, enabling seamless integration and functionality. ")]), _c('li', {
    staticClass: "mb-2"
  }, [_vm._v(" Read-Only: Once set, this ID cannot be changed. If you need to alter the Package or Bundle ID, a new app or project must be created. ")])])]), _c('v-card-subtitle', [_c('flex-row', [_c('v-btn', {
    staticClass: "mx-auto",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.packageHelpDialog = false;
      }
    }
  }, [_vm._v("Ok ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [!((_vm$appInfo3 = _vm.appInfo) !== null && _vm$appInfo3 !== void 0 && (_vm$appInfo3$settings = _vm$appInfo3.settings) !== null && _vm$appInfo3$settings !== void 0 && _vm$appInfo3$settings.lock_package) ? _c('v-row', {
    staticClass: "d-flex align-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "input-value": _vm.tempLockPackage,
      "label": "Lock Package",
      "disabled": _vm.loadingPackage
    },
    on: {
      "change": _vm.enableLockPackage
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on;
        return [_c('v-icon', _vm._g({
          staticClass: "ml-2",
          attrs: {
            "small": ""
          }
        }, on), [_vm._v(" mdi-help-circle-outline ")])];
      }
    }], null, false, 2019398599)
  }, [_c('span', [_vm._v(" Locking the package ensures that releases and sessions are tied together, providing better tracking and analysis. ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.loadingPackage ? _c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary",
      "size": "24"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), !((_vm$appInfo4 = _vm.appInfo) !== null && _vm$appInfo4 !== void 0 && (_vm$appInfo4$settings = _vm$appInfo4.settings) !== null && _vm$appInfo4$settings !== void 0 && _vm$appInfo4$settings.lock_unique_releases) ? _c('v-row', {
    staticClass: "d-flex align-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "input-value": _vm.tempLockUniqueReleases,
      "label": "Unique Releases",
      "disabled": _vm.loadingReleaseIncrement
    },
    on: {
      "change": _vm.enableLockUniqueReleases
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref5) {
        var on = _ref5.on;
        return [_c('v-icon', _vm._g({
          staticClass: "ml-2",
          attrs: {
            "small": ""
          }
        }, on), [_vm._v(" mdi-help-circle-outline ")])];
      }
    }], null, false, 2019398599)
  }, [_c('span', [_vm._v(" Locking unique releases allows for precise error tracking by mapping errors directly to specific release versions. ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.loadingReleaseIncrement ? _c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary",
      "size": "24"
    }
  }) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_vm.selectedOption === 0 ? _c('app-information') : _vm._e(), _vm.selectedOption === 1 ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('app-members'), _c('members-and-invites')], 1)], 1) : _vm._e(), _vm.selectedOption === 2 ? _c('div', {
    attrs: {
      "outlined": ""
    }
  }, [!_vm.canManageApp ? _c('no-data', {
    attrs: {
      "first-text": "No app invites",
      "permission-issues": "",
      "second-text": "You don't have the permission to view or manage the app invites"
    }
  }) : _c('invites-list', {
    staticClass: "mx-3",
    attrs: {
      "from-app-page": "",
      "from-dashboard": ""
    }
  })], 1) : _vm._e(), _vm.selectedOption === 3 ? _c('release-settings') : _vm._e(), _vm.selectedOption === 4 ? _c('share-link') : _vm._e(), _vm.selectedOption === 5 ? _c('setting-form') : _vm._e()], 1)] : _vm._e(), _c('Modal', {
    attrs: {
      "hideOverlay": ""
    },
    on: {
      "close": function close($event) {
        _vm.viewEditApp = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('EditAppPanel', {
          attrs: {
            "app": _vm.appInfo
          },
          on: {
            "close": function close($event) {
              _vm.viewEditApp = false;
            },
            "success": function success($event) {
              return _vm.$emit('success', $event);
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.viewEditApp,
      callback: function callback($$v) {
        _vm.viewEditApp = $$v;
      },
      expression: "viewEditApp"
    }
  }), _c('Modal', {
    attrs: {
      "no-py": ""
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Delete app")]), _c('v-divider'), _c('v-card-text', {
          staticClass: "my-4 message"
        }, [_c('p', [_vm._v("Are you sure you want to delete this app?")]), _c('v-alert', {
          staticClass: "mt-3",
          attrs: {
            "border": "left",
            "color": "info",
            "icon": "info",
            "outlined": ""
          }
        }, [_vm._v(" You will lose the access to any information regarding this app. "), _vm.readMore ? _c('div', [_c('br'), _vm._v(" This app will be deleted along with everything attached to it such as:"), _c('br'), _c('br'), _c('ul', [_c('li', [_vm._v("Chat")]), _c('li', [_vm._v("Timeline")]), _c('li', [_vm._v("Analytics")]), _c('li', [_vm._v("App Tokens")]), _c('li', [_vm._v("Integrations")]), _c('li', [_vm._v("Releases & Comments")]), _c('li', [_vm._v("Sessions & Feedback")]), _c('li', [_vm._v("Public install links")]), _c('li', [_vm._v("Notifications related this app")])]), _c('br')]) : _vm._e(), !_vm.readMore ? _c('b', {
          staticClass: "pointer",
          on: {
            "click": function click($event) {
              _vm.readMore = !_vm.readMore;
            }
          }
        }, [_vm._v(" " + _vm._s("Read more...") + " ")]) : _vm._e(), _c('br')])], 1), _c('v-divider'), _c('v-card-actions', {
          staticClass: "py-3"
        }, [_c('div', [_vm._v("No undo for this action")]), _c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "id": "leaveAppCancelBtn",
            "color": "primary",
            "text": ""
          },
          on: {
            "click": _vm.onCancel
          }
        }, [_vm._v("No ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "id": "leaveAppConfirmBtn",
            "loading": _vm.loading,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary"
          },
          on: {
            "click": _vm.deleteAppConfirm
          }
        }, [_vm._v("Yes ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.deleteModal,
      callback: function callback($$v) {
        _vm.deleteModal = $$v;
      },
      expression: "deleteModal"
    }
  }), _c('Modal', {
    attrs: {
      "no-py": ""
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Leave app")]), _c('v-divider'), _c('v-card-text', {
          staticClass: "my-4 message"
        }, [_c('p', [_vm._v("Are you sure you want to leave this app?")]), _c('v-alert', {
          staticClass: "mt-3",
          attrs: {
            "border": "left",
            "color": "info",
            "icon": "info",
            "outlined": ""
          }
        }, [_vm._v(" You will lose the access to any information regarding this app. "), _vm.readMore ? _c('div', [_c('br'), _vm._v(" If you are the only one in the app, the app will be deleted along with everything attached to it such as:"), _c('br'), _c('br'), _c('ul', [_c('li', [_vm._v("Releases")]), _c('li', [_vm._v("Timeline")]), _c('li', [_vm._v("Notifications")])]), _c('br'), _vm._v(" Otherwise, your data such as releases will remain the same in the app but you will not have the access to them. However, you can still be added " + _vm._s(_vm.canManageTeam ? "or rejoin" : "to") + " the app again at any time if it's still exits. ")]) : _vm._e(), !_vm.readMore ? _c('b', {
          staticClass: "pointer",
          on: {
            "click": function click($event) {
              _vm.readMore = !_vm.readMore;
            }
          }
        }, [_vm._v(" " + _vm._s("Read more...") + " ")]) : _vm._e(), _c('br')])], 1), _c('v-divider'), _c('v-card-actions', {
          staticClass: "py-3"
        }, [_c('div', [_vm._v("No undo for this action")]), _c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "id": "leaveAppCancelBtn",
            "color": "primary",
            "text": ""
          },
          on: {
            "click": _vm.onCancelApp
          }
        }, [_vm._v("No ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "id": "leaveAppConfirmBtn",
            "loading": _vm.loadingConfirm,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary"
          },
          on: {
            "click": _vm.onConfirmApp
          }
        }, [_vm._v("Yes ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.modal,
      callback: function callback($$v) {
        _vm.modal = $$v;
      },
      expression: "modal"
    }
  })], 2)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }