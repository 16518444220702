var render = function render(){
  var _vm$info;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.canManageApp ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('no-data', {
    attrs: {
      "first-text": 'You don\'t have the permission to view or manage the public page'
    }
  })], 1)], 1) : _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-col', {
    staticClass: "text-center px-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('vue-inline-alert', {
    attrs: {
      "message": "The details provided here will be applied to all your install links.\n          Everything on this page will be showcased publicly on the app page\n          for external users.",
      "message-type": "info",
      "outlined": "",
      "no-close-btn": ""
    }
  })], 1), !_vm.getAppInfoLoading ? _c('v-card', {
    staticClass: "pb-10 pr-10",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('div', [_c('div', {
    staticClass: "titleFont"
  }, [_vm._v("App Information")]), _c('div', {
    staticClass: "font-weight-regular smallFont"
  }, [_vm._v(" Upload screenshots and complete your app details to craft a captivating page for your external testers. ")])]), _c('v-spacer'), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({}, on), [_c('v-switch', {
          attrs: {
            "hide-details": "",
            "label": "Public Access"
          },
          on: {
            "click": _vm.updatePublicAccess
          },
          model: {
            value: _vm.public_access,
            callback: function callback($$v) {
              _vm.public_access = $$v;
            },
            expression: "public_access"
          }
        })], 1)];
      }
    }], null, false, 3335035010)
  }, [_c('span', [_vm._v(" Enable or disable public access for all public links in this app ")])])], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'categories_validation',
      expression: "'categories_validation'"
    }],
    attrs: {
      "items": _vm.getCategoriesList,
      "chips": "",
      "counter": "3",
      "filled": "",
      "flat": "",
      "hint": "",
      "item-text": "name",
      "item-value": "id",
      "label": "App categories",
      "multiple": "",
      "persistent-hint": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(data) {
        return [_c('v-chip', _vm._b({
          attrs: {
            "input-value": data.selected,
            "close": ""
          },
          on: {
            "click": data.select,
            "click:close": function clickClose($event) {
              return _vm.remove(data.item);
            }
          }
        }, 'v-chip', data.attrs, false), [_vm._v(" " + _vm._s(data.item.name) + " ")])];
      }
    }], null, false, 3081908909),
    model: {
      value: _vm.form.categories,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "categories", $$v);
      },
      expression: "form.categories"
    }
  }, 'v-autocomplete', _vm.veeValidate('category', ''), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:600',
      expression: "'max:600'"
    }],
    attrs: {
      "id": "settingsDescription",
      "auto-grow": "",
      "counter": "600",
      "filled": "",
      "hint": "This will be displayed in the public install page",
      "label": "Description",
      "placeholder": "Description about this app",
      "rows": "4"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  }, 'v-textarea', _vm.veeValidate('Description', ''), false))], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:600',
      expression: "'max:600'"
    }],
    attrs: {
      "id": "settingGlobalInstructions",
      "auto-grow": "",
      "counter": "600",
      "filled": "",
      "hint": "This will be displayed in the public install page. Ex: Demo account, environment configuration, tips,...",
      "label": "Global install instruction",
      "placeholder": "Any special instructions for the testers to start testing this app?",
      "rows": "4"
    },
    model: {
      value: _vm.form.settings.global_instructions,
      callback: function callback($$v) {
        _vm.$set(_vm.form.settings, "global_instructions", $$v);
      },
      expression: "form.settings.global_instructions"
    }
  }, 'v-textarea', _vm.veeValidate('Instruction', ''), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v(" App store links (Optional) "), _c('VueTooltip', {
    attrs: {
      "v-html": 'App store links will be displayed in the the public install page',
      "color": "primary",
      "icon-color": "grey",
      "text-class": "white--text"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'url',
      expression: "'url'"
    }],
    staticClass: "mt-n5",
    attrs: {
      "id": "androidStoreLink",
      "filled": "",
      "hint": "Google Play store, Huawei AppGallery, Samsung Galaxy Store,...",
      "label": "Android app store",
      "placeholder": "https://play.google.com/store/apps/details?id=testapp.io"
    },
    model: {
      value: _vm.form.settings.store_links.android,
      callback: function callback($$v) {
        _vm.$set(_vm.form.settings.store_links, "android", $$v);
      },
      expression: "form.settings.store_links.android"
    }
  }, 'v-text-field', _vm.veeValidate('link', ''), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'url',
      expression: "'url'"
    }],
    staticClass: "mt-n5",
    attrs: {
      "id": "appStoreLink",
      "filled": "",
      "hint": "The URL for the live app in Apple app store",
      "label": "Apple app store",
      "placeholder": "https://apps.apple.com/us/app/testapp-io/id1518972541"
    },
    model: {
      value: _vm.form.settings.store_links.ios,
      callback: function callback($$v) {
        _vm.$set(_vm.form.settings.store_links, "ios", $$v);
      },
      expression: "form.settings.store_links.ios"
    }
  }, 'v-text-field', _vm.veeValidate('Link', ''), false))], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('screen-shot-upload', {
    attrs: {
      "screenshot-list": (_vm$info = _vm.info) === null || _vm$info === void 0 ? void 0 : _vm$info.screenshots
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-title', [_c('div', [_c('div', {
    staticClass: "titleFont"
  }, [_vm._v("Developer Information")])])]), _c('v-divider'), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|min:3|max:80',
      expression: "'required|min:3|max:80'"
    }],
    attrs: {
      "id": "developerName",
      "filled": "",
      "hint": "This name will be displayed in the the public install page",
      "label": "Developer Name",
      "placeholder": "Company name or your name"
    },
    model: {
      value: _vm.form.settings.developer_contact.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form.settings.developer_contact, "name", $$v);
      },
      expression: "form.settings.developer_contact.name"
    }
  }, 'v-text-field', _vm.veeValidate('Developer Name', ''), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|email',
      expression: "'required|email'"
    }],
    attrs: {
      "id": "settingCompanyEmail",
      "filled": "",
      "hint": "This email will be displayed in the the public install page",
      "label": "Support Email",
      "placeholder": "Company email or your email"
    },
    model: {
      value: _vm.form.settings.developer_contact.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form.settings.developer_contact, "email", $$v);
      },
      expression: "form.settings.developer_contact.email"
    }
  }, 'v-text-field', _vm.veeValidate('Email', ''), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'url',
      expression: "'url'"
    }],
    attrs: {
      "id": "settingPrivacyPolicy",
      "filled": "",
      "hint": "Optional - Ex: https://company.com/privacy-policy",
      "label": "Privacy Policy",
      "placeholder": "Privacy Policy URL for this app"
    },
    model: {
      value: _vm.form.settings.developer_contact.privacy,
      callback: function callback($$v) {
        _vm.$set(_vm.form.settings.developer_contact, "privacy", $$v);
      },
      expression: "form.settings.developer_contact.privacy"
    }
  }, 'v-text-field', _vm.veeValidate('Privacy', ''), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'url',
      expression: "'url'"
    }],
    attrs: {
      "id": "settingTerms&Conditions",
      "filled": "",
      "hint": "Optional - Ex: https://company.com/terms-conditions",
      "label": "Terms & Conditions",
      "placeholder": "Terms & Conditions URL for this app"
    },
    model: {
      value: _vm.form.settings.developer_contact.terms,
      callback: function callback($$v) {
        _vm.$set(_vm.form.settings.developer_contact, "terms", $$v);
      },
      expression: "form.settings.developer_contact.terms"
    }
  }, 'v-text-field', _vm.veeValidate('Terms and condition', ''), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'url',
      expression: "'url'"
    }],
    attrs: {
      "id": "settingsWebsite",
      "filled": "",
      "hint": "Optional - Ex: https://testapp.io",
      "label": "Website",
      "placeholder": "App or company website (Ex: https://testapp.io)"
    },
    model: {
      value: _vm.form.settings.developer_contact.website,
      callback: function callback($$v) {
        _vm.$set(_vm.form.settings.developer_contact, "website", $$v);
      },
      expression: "form.settings.developer_contact.website"
    }
  }, 'v-text-field', _vm.veeValidate('Website', ''), false))], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "id": "settingUpdateBtn",
      "disabled": !_vm.canManageApp || _vm.errors.any() || _vm.busy,
      "loading": _vm.busy,
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "depressed": "",
      "large": ""
    },
    on: {
      "click": _vm.updateSettings
    }
  }, [_vm._v("Save ")])], 1)], 1) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "form"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }