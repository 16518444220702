var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "white-space": "pre-line"
    }
  }, [_vm.readMoreDesc ? _c('div', [_vm._v(" " + _vm._s(_vm.text) + " "), !_vm.controlOutSide && !_vm.small ? _c('div', {
    staticClass: "primary--text body-1 cursor-pointer",
    on: {
      "click": function click($event) {
        _vm.readMoreDesc = !_vm.readMoreDesc;
      }
    }
  }, [_vm._v(" " + _vm._s("SHOW LESS") + " ")]) : _vm._e()]) : _vm._e(), !_vm.readMoreDesc ? _c('div', [_vm._v(" " + _vm._s(_vm.text ? _vm.text.substring(0, _vm.length) : "") + " "), _vm.text.length > _vm.length ? _c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.text ? _vm.text.substring(_vm.length, _vm.length) : "") + " ")]) : _vm._e(), _vm.text && _vm.text.length > _vm.length && !_vm.controlOutSide && !_vm.small ? _c('span', {
    staticClass: "primary--text body-1 text-center cursor-pointer",
    on: {
      "click": function click($event) {
        _vm.readMoreDesc = !_vm.readMoreDesc;
      }
    }
  }, [_vm._v(_vm._s("SHOW MORE"))]) : _vm._e(), _vm.text && _vm.text.length > _vm.length && !_vm.controlOutSide && _vm.small ? _c('span', {
    staticClass: "primary--text bold-text text-left cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.$emit('readMore');
      }
    }
  }, [_vm._v(_vm._s("..."))]) : _vm._e()]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }