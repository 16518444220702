<template>
  <div>
    <!--  -->
    <invite-members-loader v-if="noRolesData" roles-only />
    <div v-else>
      <v-row>
        <v-col cols="6">
          <v-tooltip v-if="disableMemberRole" bottom>
            <template v-slot:activator="{ on }">
              <v-card v-on="on" class="pa-2" outlined height="100%">
                <v-row>
                  <v-col cols="auto">
                    <div class="bodyFont">Members Role</div>
                  </v-col>
                  <v-col class="text-right">
                    <v-icon
                      :color="
                        selectedType === 'memberRole' ? 'success' : 'info'
                      "
                    >
                      mdi-circle-outline
                    </v-icon>
                  </v-col>
                  <!-- <v-col class="pt-0" cols="12">
                    <div>
                      <v-icon size="20" left v-text="'mdi-information-outline'"></v-icon>
                      Can't change guest role to member role
                    </div>
                  </v-col> -->
                </v-row>
              </v-card>
            </template>
            <span>
              You cannot switch to this role. If you wish to change a user's
              role to a member, <br />
              you must first remove the guest and then invite them again with a
              member role.
            </span>
          </v-tooltip>
          <v-card
            v-else
            class="pa-2"
            @click="selectedType = 'memberRole'"
            outlined
            id="memberRoleTab"
            height="100%"
          >
            <v-row>
              <v-col cols="auto">
                <div class="bodyFont">Members Role</div>
              </v-col>
              <v-col class="text-right">
                <v-icon
                  :color="selectedType === 'memberRole' ? 'success' : 'info'"
                  v-text="
                    selectedType === 'memberRole'
                      ? 'mdi-check-circle'
                      : 'mdi-circle-outline'
                  "
                >
                </v-icon>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-tooltip v-if="disableGuestRole" bottom>
            <template v-slot:activator="{ on }">
              <v-card
                class="pa-2 cursor-pointer"
                v-on="on"
                outlined
                height="100%"
              >
                <v-row>
                  <v-col cols="auto">
                    <div class="bodyFont">Guest Role</div>
                  </v-col>
                  <v-col class="text-right">
                    <v-icon
                      :color="selectedType === 'guestRole' ? 'success' : 'info'"
                      v-text="
                        selectedType === 'guestRole'
                          ? 'mdi-check-circle'
                          : 'mdi-circle-outline'
                      "
                    >
                    </v-icon>
                  </v-col>
                </v-row>
              </v-card>
            </template>
            <span>
              You cannot switch to this role. If you wish to change a user's
              role to a guest,
              <br />
              you must first remove the member and then invite them again with a
              guest role.
            </span>
          </v-tooltip>
          <v-card
            v-else
            class="pa-2"
            id="guestRoleTab"
            @click="selectedType = 'guestRole'"
            outlined
            height="100%"
          >
            <v-row>
              <v-col cols="auto">
                <div class="bodyFont">Guest Role</div>
              </v-col>
              <v-col class="text-right">
                <v-icon
                  :color="selectedType === 'guestRole' ? 'success' : 'info'"
                  v-text="
                    selectedType === 'guestRole'
                      ? 'mdi-check-circle'
                      : 'mdi-circle-outline'
                  "
                >
                </v-icon>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <template v-if="selectedType === 'memberRole'">
        <v-radio-group mandatory v-model="Roles">
          <template v-for="role in roles">
            <v-radio
              v-if="hideCustom(role)"
              :id="role.name"
              :key="role.id"
              :value="role.id"
            >
              <template v-slot:label>
                <span>
                  <span class="mr-2">{{ role.name }}</span>
                  <VueTooltip :text="role.description" />
                </span>
              </template>
            </v-radio>
          </template>
        </v-radio-group>
        <v-row id="memberDescription">
          <v-col cols="4"> </v-col>
          <v-col class="text-right" cols="4"> Manage </v-col>
          <v-col class="text-center" cols="4"> View </v-col>
          <v-col v-if="activeRole.permissions" cols="12">
            <v-row
              v-for="(first, firstIndex) in Object.keys(activeRole.permissions)"
              :key="firstIndex"
              align="center"
            >
              <v-col cols="6" class="text-capitalize py-0">
                <div>
                  <div>
                    <span class="mr-2">
                      {{ first }}
                    </span>
                    <VueTooltip
                      v-if="getDescriptions[first]?.description"
                      :v-html="
                        getDescriptions
                          ? getDescriptions[first]?.description
                          : ''
                      "
                    />
                  </div>
                </div>
              </v-col>
              <v-col v-if="activeRole.permissions[first]" class="py-0" cols="6">
                <v-row>
                  <template
                    v-for="(second, secondIndex) in Object.keys(
                      activeRole.permissions[first]
                    ).reverse()"
                  >
                    <v-col
                      v-if="second === 'manage'"
                      class="text-right"
                      :key="secondIndex"
                      cols="6"
                    >
                      <v-checkbox
                        :disabled="!activeRole.editable"
                        hide-details
                        @change="setPermission(first, second)"
                        v-model="activeRole.permissions[first][second]"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      v-if="second === 'view'"
                      class="text-right"
                      :key="secondIndex"
                      cols="6"
                    >
                      <v-checkbox
                        :disabled="!activeRole.editable"
                        :readonly="activeRole.permissions[first]['manage']"
                        hide-details
                        v-model="activeRole.permissions[first][second]"
                      ></v-checkbox>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-if="selectedType === 'guestRole'">
        <v-row id="guestDescription">
          <v-col cols="12" v-html="guestRoleDescription"></v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isEmpty } from "@/core/services/helper.service";
import InviteMembersLoader from "@/view/components/Common/loaders/InviteMembers.vue";
import { GET_ROLES } from "@/store/team/team.module";

export default {
  props: {
    updateRoles: {
      type: [String, Object],
      default: "",
    },
    showTour: {
      type: Boolean,
      default: false,
    },
    fromMemberPage: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  components: {
    InviteMembersLoader,
  },
  data() {
    return {
      Roles: null,
      selectedType: "memberRole",
      allPermissions: {},
      tourOptions: {
        overlay: {
          background: "grey",
          opacity: 0.1,
        },
        highlight: true,

        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Skip tour",
          buttonPrevious: "Previous",
          buttonNext: "Next",
          buttonStop: "Create Invite",
        },
      },
      activeRole: {},
    };
  },
  methods: {
    getActiveRole() {
      if (this.Roles) {
        return { ...this.roles.find((v) => v.id == this.Roles) };
      } else {
        return { ...this.roles[0] };
      }
    },
    async stopTour() {
      await this.$router.push({
        query: {},
      });
      this.$emit("create-invite");
    },
    hideCustom(role) {
      if (this.updateRoles && this.updateRoles.name === "Guest") {
        return true;
      } else {
        return role.name !== "Guest";
      }
    },
    setPermission(first, second) {
      if (second === "manage") {
        if (this.activeRole.permissions[first].manage === true) {
          if (
            Object.keys(this.activeRole.permissions[first]).includes("view")
          ) {
            this.activeRole.permissions[first].view = true;
          }
        } else {
          if (
            Object.keys(this.activeRole.permissions[first]).includes("view")
          ) {
            this.activeRole.permissions[first]["view"] = false;
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      roles: "getRoles",
    }),
    tourSteps() {
      return this.$route.query.tour === "guest"
        ? this.guestStep
        : this.memberStep;
    },
    noRolesData() {
      return isEmpty(this.roles);
    },
    disableMemberRole() {
      return this.fromMemberPage && this.updateRoles.id === "e5n28GYL1J";
    },
    disableGuestRole() {
      return this.fromMemberPage && this.updateRoles.id !== "e5n28GYL1J";
    },
    guestRoleDescription() {
      return this.roles.find((role) => role.id === "e5n28GYL1J").description;
    },
    getDescriptions() {
      return (
        this.roles &&
        this.roles.find((role) => role.id === this.activeRole.id).permissions
      );
    },
  },
  watch: {
    selectedType(val) {
      if (val === "guestRole") {
        let role = this.roles?.find((role) => role.id === "e5n28GYL1J");
        this.Roles = role?.id;
      }
    },
    activeRole: function (v) {
      this.allPermissions = v.permissions;
      this.$emit("updateRoles", v.permissions);
      try {
        this.$emit("updateTheRole", v.id);
      } catch (error) {
        this.logError(error);
      }
    },
    Roles: function (v) {
      this.$emit("updateMainRole", v);
      this.activeRole = this.getActiveRole();
      const roles = this.roles.find((x) => x.name === "Custom");
      try {
        if (
          roles &&
          v === roles.id &&
          this.updateRoles &&
          this.updateRoles.name === "Custom"
        ) {
          this.activeRole.permissions = { ...this.permission };
        }
      } catch (error) {
        this.logError(error);
      }
    },
    updateRoles() {
      if (this.updateRoles) {
        this.Roles = this.updateRoles.id;
      }
      if (this.permission) {
        this.activeRole.permissions = { ...this.permission };
      }
    },
  },
  async created() {
    if (isEmpty(this.roles)) {
      this.$store
        .dispatch(GET_ROLES)
        .then(() => {
          this.activeRole = this.getActiveRole();
          if (this.updateRoles) {
            this.Roles = this.updateRoles.id;
            if (this.updateRoles.name === "Custom") {
              this.activeRole.permissions = { ...this.permission };
            }
          }
        })
        .catch((err) => {
          this.notifyErrorMessage({ message: err.message });
        });
    } else {
      this.activeRole = this.getActiveRole();
    }
  },
  mounted() {
    if (this.fromMemberPage && this.updateRoles.id === "e5n28GYL1J") {
      this.selectedType = "guestRole";
    }
    if (this.updateRoles.id === "e5n28GYL1J") {
      this.selectedType = "guestRole";
    }
    if (this.updateRoles) {
      this.Roles = this.updateRoles.id;
      if (this.updateRoles.name === "Custom") {
        this.activeRole.permissions = { ...this.permission };
      }
    }
  },
};
</script>

<style lang="scss" scoped>
* {
  text-align: left;
}
</style>
