<template>
    <div>
        <div v-if="detail.country">
            <v-row>
                <v-col class="pa-0" cols="auto">
                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <span v-on="on">
                                <Flag v-if="detail.country_code" :code="detail.country_code"></Flag>
                            </span>
                        </template>
                        <span>{{ detail.country }}</span>
                    </v-tooltip>
                </v-col>
                <v-col class="pr-0 pt-0 pb-0 pl-1" cols="auto">
                    <span v-if="detail.city">
                        <MaxText :text="detail.city" max="20" />
                    </span>
                </v-col>
            </v-row>
        </div>
        <div v-else>-</div>
    </div>
</template>

<script>
export default {
    props: {
        detail: {
            type: Object,
            required: true,
        },
    },
};
</script>
