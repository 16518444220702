var render = function render(){
  var _vm$info, _vm$getAppInfo;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.getInactiveUserMessage && !_vm.getIsUserDisabled ? _c('v-menu', {
    attrs: {
      "bottom": "",
      "left": "",
      "offset-y": "",
      "close-on-click": false,
      "transition": "slide-x-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          ref: "invite-btn",
          staticClass: "text-transform-none",
          attrs: {
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary",
            "id": "inviteBtn"
          }
        }, on), [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" person_add_alt_1 ")]), _vm._v(" Invite Member ")], 1)];
      }
    }], null, false, 1338605932),
    model: {
      value: _vm.menu,
      callback: function callback($$v) {
        _vm.menu = $$v;
      },
      expression: "menu"
    }
  }, [_c('v-list', [_c('v-list-item', {
    attrs: {
      "id": "inviteByEmailBtn"
    },
    on: {
      "click": _vm.openEmailDrawer
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-email-outline")]), _vm._v(" By Email ")], 1), _c('v-list-item', {
    attrs: {
      "id": "inviteByUrlBtn"
    },
    on: {
      "click": _vm.openURLDrawer
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-link")]), _vm._v(" By URL ")], 1)], 1)], 1) : _vm._e(), _c('SidePanel', {
    attrs: {
      "hide-overlay": _vm.hideOverlay,
      "loading": _vm.inviteLoader
    },
    model: {
      value: _vm.inviteDrawer,
      callback: function callback($$v) {
        _vm.inviteDrawer = $$v;
      },
      expression: "inviteDrawer"
    }
  }, [_c('div', {
    staticClass: "invite-drawer"
  }, [_c('div', {
    staticClass: "text-center mb-13 display-1 bold-text"
  }, [_vm._v(" " + _vm._s(_vm.activeDrawer == "byEmail" ? "Invite by Email" : "Invite by URL") + " ")]), _c('h2', {
    staticClass: "mt-12 text-center"
  }, [_vm._v(_vm._s((_vm$info = _vm.info) === null || _vm$info === void 0 ? void 0 : _vm$info.name))]), _vm.loading ? _c('invite-members-loader') : _vm._e(), _c('div', [_vm.inviteURL ? _c('div', [_c('v-text-field', {
    attrs: {
      "id": "inviteUrlField",
      "disabled": "",
      "filled": "",
      "label": "Invite URL",
      "messages": "copy it, and send it to your testers",
      "placeholder": "invite's URL field"
    },
    model: {
      value: _vm.inviteURL,
      callback: function callback($$v) {
        _vm.inviteURL = $$v;
      },
      expression: "inviteURL"
    }
  }), _c('v-btn', {
    staticClass: "mx-auto mt-6",
    attrs: {
      "id": "copy-invite-url",
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": function click($event) {
        return _vm.copy(_vm.inviteURL);
      }
    }
  }, [_vm._v(" copy ")])], 1) : _c('div', [_vm.activeDrawer == 'byEmail' ? _c('div', {
    staticClass: "mt-4"
  }, [_c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "wrap": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('AutoComplete', {
    attrs: {
      "id": "inviteEmailField",
      "email": true,
      "label": "Emails",
      "placeholder": "Enter or paste emails here..."
    },
    on: {
      "update": _vm.setEmails
    }
  })], 1)], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "mt-4"
  }, [_c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "wrap": ""
    }
  }, [_c('v-col', {
    staticClass: "text-left",
    attrs: {
      "cols": "12"
    }
  }, [!_vm.$route.params.app_id ? _c('AppAutoComplete', {
    ref: "app-autocomplete",
    staticClass: "mb-4",
    attrs: {
      "id": "inviteAppAutocomplete",
      "multiple": ""
    },
    model: {
      value: _vm.selectedApps,
      callback: function callback($$v) {
        _vm.selectedApps = $$v;
      },
      expression: "selectedApps"
    }
  }) : _c('div', {
    staticClass: "semi-bold"
  }, [_vm._v(" App Name: " + _vm._s((_vm$getAppInfo = _vm.getAppInfo) === null || _vm$getAppInfo === void 0 ? void 0 : _vm$getAppInfo.name) + " ")])], 1)], 1)], 1), _c('div', {
    staticClass: "mt-4"
  }, [[_c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:200',
      expression: "'max:200'"
    }],
    staticClass: "mt-8",
    attrs: {
      "id": "inviteMessageField",
      "auto-grow": "",
      "counter": "200",
      "filled": "",
      "label": "Message",
      "placeholder": "Your message for this invite... (Optional)"
    },
    model: {
      value: _vm.inviteMessage,
      callback: function callback($$v) {
        _vm.inviteMessage = $$v;
      },
      expression: "inviteMessage"
    }
  }, 'v-textarea', _vm.veeValidate('Message', 'Message'), false))]], 2), _vm.inviteDrawer ? _c('Roles', {
    ref: "theRoleComponent",
    attrs: {
      "show-tour": _vm.showTour
    },
    on: {
      "updateRoles": _vm.saveRoles,
      "updateTheRole": _vm.saveTheRole,
      "create-invite": _vm.getAll
    }
  }) : _vm._e(), _c('div', {
    staticClass: "mt-8"
  }, [_c('v-divider'), _c('v-checkbox', {
    staticClass: "mt-6",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', [_vm._v(" Notify me by email when accepting "), _c('vue-tooltip', {
          attrs: {
            "VHtml": "Uncheck this if you don't want to be notified by email for this invite",
            "icon-color": "darkGrey"
          }
        })], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.notify,
      callback: function callback($$v) {
        _vm.notify = $$v;
      },
      expression: "notify"
    }
  })], 1), _c('div', {
    staticClass: "text-center mt-10"
  }, [_c('v-btn', {
    staticClass: "py-7 px-15 text-transform-none",
    attrs: {
      "id": "inviteCreateBtn",
      "disabled": !_vm.ifallDone || _vm.inviteLoader,
      "loading": _vm.inviteLoader,
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "x-large": ""
    },
    on: {
      "click": _vm.getAll
    }
  }, [_c('span', {
    staticClass: "font14"
  }, [_vm._v(" Invite ")])])], 1)], 1)])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }