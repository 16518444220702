<template>
  <div class="topbar-item quick-add-member">
    <v-btn
      v-if="!dropDown"
      id="add-member-panel-button"
      :outlined="$vuetify.theme.dark"
      class="main"
      color="primary"
      @click="drawer = true"
    >
      <v-icon left>person_add_alt_1</v-icon>
      Add Member
    </v-btn>
    <action-dropdown
      v-else
      :largeBtn="false"
      button="Add Member"
      icon="person_add_alt_1"
    >
      <template #list>
        <!-- item in the drop down -->
        <v-list-item id="addMemberFromApp" @click="drawer = true">
          <v-icon small>person_add_alt_1</v-icon>
          Add from team
        </v-list-item>
        <!-- item in the drop down -->
        <v-list-item
          id="inviteToTeamBtn"
          @click="
            $router
              .push({
                name: 'app-settings-invite',
              })
              .catch(() => {})
          "
        >
          <v-icon small>mdi-email-outline</v-icon>
          Invite to team
        </v-list-item>
      </template>
    </action-dropdown>
    <SidePanel v-model="drawer" :loading="busy" width="520">
      <h1 class="text-center mx-auto mb-8 display-1">Add member</h1>
      <div class="text-center bold-text title">
        {{ app.name }}
      </div>
      <member-select
        :key="app.id"
        v-model="selectedMember"
        :app="app"
        add-member
        invite-important
        @isInvite="isInvite = $event"
        @selected="selectedOption = $event"
      />
      <v-checkbox
        v-model="notify"
        label="Notify members by email"
        :value="false"
      ></v-checkbox>
      <!-- use v-alert for info card of alerts, it cool and simple -->
      <!-- when button  should be disable loading procces -->
      <div class="text-center">
        <v-btn
          id="invite-member-to-app"
          :disabled="busy || !app.id"
          :loading="busy"
          :outlined="$vuetify.theme.dark"
          class="mt-13 px-15 py-7 text-transform-none"
          color="primary"
          x-large
          @click="onAddMember"
        >
          <span class="font14"> Submit </span>
        </v-btn>
      </div>
      <!--  -->
    </SidePanel>
  </div>
</template>

<script>
import MemberSelect from "@/view/components/Common/MemberSelectSection";
import { validationMixin } from "vuelidate";
import {
  email,
  helpers,
  maxLength,
  minLength,
  required,
} from "vuelidate/lib/validators";
import { ADD_INVITE } from "@/store/apps/app.module";
import { mapMutations, mapState } from "vuex";

const name = helpers.regex("name", /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/);
const validemail = helpers.regex(
  "validemail",
  /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/
);

export default {
  name: "add-member-panel",
  mixins: [validationMixin],
  props: {
    dropDown: {
      type: Boolean,
      default: false,
    },
    appDetail: {
      type: [Object, Boolean],
      default() {
        return false;
      },
    },
  },
  components: { MemberSelect },
  data() {
    return {
      app: "",
      busy: false,
      notify: false,
      alert: true,
      drawer: false,
      selectedMember: "",
      description: "",
      isInvite: true,
      selectedOption: "Current",
      show: false,
      show1: false,
      invalidApp: false,
      openMenu: false,
      members: [],
      search: "",
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
      roles: (state) => {
        const roles = [];
        Object.keys(state.constants.roles).map((key) => {
          roles.push({
            name: key,
            permissions: state.constants.roles[key],
          });
        });
        return roles;
      },
    }),
  },
  watch: {
    // this is for navigation drawer to remove the scroll when it open
    drawer: function () {
      // v
      //   ? (document.querySelector("body, html").style.overflow = "hidden")
      //   : (document.querySelector("body, html").style.overflow = "auto");
      this.clearInitialApp([]);
      this.app = "";
      if (this.appDetail) {
        this.app = { ...this.appDetail };
      }
      this.selectedOption = "Current";
    },
    app() {
      this.clearInitialApp([]);
    },
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30),
        name,
      },
      email: {
        required,
        email,
        validemail,
      },
    },
  },
  mounted() {},
  created() {
    if (this.appDetail) {
      this.app = { ...this.appDetail };
    }
  },
  methods: {
    ...mapMutations({
      clearInitialApp: "setMemberInitialList",
      appendMemberStatsCount: "appendMemberStatsCount",
    }),
    resetForm() {
      this.form = {
        name: "",
        email: "",
      };
      this.invalidApp = false;
      this.$v.$reset();
    },
    onAddMember() {
      if (!this.app && !this.app.id) {
        this.notifyErrorMessage("Please select app");
        return;
      }
      if (this.busy) return;
      this.busy = true;
      const dataToPost =
        this.selectedOption === "Specific"
          ? {
              app_id: this.app.id,
              platforms: this.app ? this.app.platforms : [],
              details: {
                notify: this.notify,
                add_all_team_members: false,
                ids: this.selectedMember
                  ? this.selectedMember.map((x) => x.id)
                  : null,
              },
            }
          : {
              app_id: this.app.id,

              platforms: this.app ? this.app.platforms : [],
              details: {
                notify: this.notify,
                add_all_team_members: true,
              },
            };
      this.$store
        .dispatch(ADD_INVITE, dataToPost)
        .then((response) => {
          this.busy = false;
          const count = response.message.split("")[0];
          this.appendMemberStatsCount(parseInt(count));
          this.notifyUserMessage({
            message: response.message || "Invited Successfully",
          });
          this.app = "";
          this.selectedMemeber = [];
          this.clearInitialApp([]);
          this.selectedOption = "Current";
          this.$emit("refresh");
          this.drawer = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(
            err.message || `Something went wrong, please try again`
          );
          this.busy = false;
        });
    },
    onAppSelected(app) {
      this.app = app;
    },
    onRoleSelected({ permissions }) {
      this.permissions = permissions;
    },
    hidePanel() {
      // new KTOffcanvas(KTLayoutQuickAddMember.getElement()).hide();
    },
    updateTags() {
      this.$nextTick(() => {
        this.members.push(...this.search.split(","));
        this.$nextTick(() => {
          this.search = "";
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#quick_add_member {
  overflow: hidden;
}

.quick-add-member {
  .offcanvas {
    right: 0;

    &.offcanvas-right {
      right: -900px;

      &.offcanvas-on {
        right: 0;
      }
    }
  }
}

.invalid-app {
  color: #f64e60;
  font-size: 0.9rem;
}
</style>
