var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "font-size-h3 bold-text text-center mt-4",
    attrs: {
      "id": "successModalText"
    }
  }, [_vm._v(" All done 🎉 ")])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('qrcode', {
    attrs: {
      "value": _vm.getShareLink,
      "options": {
        width: 200
      }
    }
  })], 1), _c('v-col', {
    staticClass: "bodyFont py-0 text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Share this URL with everyone! ")]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mt-4 text-center"
  }, [_c('v-chip', {
    staticClass: "radius-10"
  }, [_c('MaxText', {
    attrs: {
      "id": "copyShareLinkChip",
      "text": _vm.getShareLink,
      "max": 34
    }
  })], 1), !_vm.copied ? _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "small": _vm.copied,
      "color": _vm.copied ? 'success' : ''
    },
    domProps: {
      "textContent": _vm._s(_vm.copied ? 'mdi-check' : 'mdi-content-copy')
    },
    on: {
      "click": function click($event) {
        return _vm.copy(_vm.getShareLink);
      }
    }
  }) : _vm._e(), _vm.copied ? _c('span', {
    staticClass: "success--text ml-2"
  }, [_c('v-icon', {
    attrs: {
      "color": "success",
      "small": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-check')
    }
  }), _vm._v(" " + _vm._s(_vm.copied ? "Copied" : "") + " ")], 1) : _vm._e()], 1), _vm.fromReleasePage ? _c('div', {
    staticClass: "cursor-pointer mt-4 primary--text",
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'app-settings-share'
        });
      }
    }
  }, [_vm._v(" View all links ")]) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }